import React from 'react';
import { Modal, Button } from 'antd';

import styles from './index.module.css';
import { BalancePayComponent } from '../../apollo';
import { message } from '../message';
import { withRouter } from 'react-router';

class BalancePayModal extends React.Component<any> {
  balancePay = async (send: any) => {
    try {
      await send({
        variables: {
          plan_record_id: this.props.id,
        },
      });
      message.success('支付成功');
      this.props.history.push(this.props.router || '/account');
      // this.props.history.push('/account');
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const { price } = this.props;
    return (
      <>
        <Modal
          visible={this.props.visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={this.props.onCancel}
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.modal_title}>确认支付 ¥{price}</div>
            <div className={styles.modal_desc}>
              一旦确认后将从余额中扣除相应数额
            </div>
            <BalancePayComponent>
              {send => {
                return (
                  <Button
                    className={`linear-gradient-gray ${
                      styles.sure
                    } normal_button`}
                    onClick={this.balancePay.bind(this, send)}
                    type="primary"
                  >
                    确认支付
                  </Button>
                );
              }}
            </BalancePayComponent>

            <br />
            <br />
            <Button
              className={`${styles.aggress_sure} normal_button`}
              onClick={this.props.onCancel}
            >
              取消
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(BalancePayModal);

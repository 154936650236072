import React from 'react';
import { Button, Modal, Form, Rate, Icon, Input } from 'antd';

import { FinishDemandComponent } from '../../../apollo';
import { message } from '../../../components/message';
import styles from './index.module.css';
// import moment from 'moment';

class FinishDemandModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              FinishDemand: { ...values, id: this.props.id },
            },
          });
          message.success('修改成功');
          const { refetch } = this.props;
          refetch();
          this.close();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    console.log("getFieldValue('rate')", getFieldValue('rate'))
    return (
      <div>
        <Button
          className={`linear-gradient-gray ${styles.sure}`}
          onClick={() => this.setState({ visible: true })}
          type="primary"
        >
          确认完成
        </Button>
        <Modal
          title="完成需求"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: "36px" }}>
              您的评价
              <br />
              会让我们<span style={{ color: "#DEBB6E" }}>做的更好</span>
            </div>
            <span style={{ color: "#69707A" }}>该评价对设计师是完全匿名的，请您放心填写</span>
            <br />
            <br />
            <Form>
              <Form.Item label="设计师服务态度如何？">
                {getFieldDecorator('rate', {
                  rules: [{ required: true, message: '请输入评分!' }],
                })(
                  <Rate character={<Icon type="heart" />} allowHalf />,
                )}
              </Form.Item>
              <Form.Item label="设计方案符合受众的期待吗？">
                {getFieldDecorator('appropriate_rate', {
                  rules: [{ required: true, message: '请输入评分!' }],
                })(
                  <Rate character={<Icon type="heart" />} allowHalf />,
                )}
              </Form.Item>
              {(getFieldValue('rate') <= 3 || getFieldValue('appropriate_rate') <= 3) && <Form.Item label="反馈留言">
                {getFieldDecorator('feedback', {
                  rules: [
                    { required: true, message: '请输入反馈留言!' },
                  ],
                })(
                  <Input
                    maxLength={100}
                    className="king_normal_input"
                    placeholder="告诉我们您体验好和不好的地方"
                  />,
                )}
                <span className="form_right">
                  {(getFieldValue('feedback') &&
                    getFieldValue('feedback').length) ||
                    0}
                  /100
                </span>
              </Form.Item>
              }
              <Form.Item style={{ textAlign: 'right' }}>
                {/* <Button
                  onClick={() => this.setState({ visible: false })}
                  style={{ marginRight: '30px' }}
                >
                  取消
                </Button> */}
                <FinishDemandComponent>
                  {(send, { loading }) => {
                    return (
                      <Button
                        type="primary"
                        loading={loading}
                        style={{ width: "160px", margin: "0 auto", height: "45px", display: "block" }}
                        onClick={this.handleSubmit.bind(this, send)}
                      >
                        匿名提交
                      </Button>
                    );
                  }}
                </FinishDemandComponent>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

export const FinishDemandModalForm: any = Form.create({
  name: 'create_car',
})(FinishDemandModal);

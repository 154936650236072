import React from 'react';
import { Modal, Button } from 'antd';

import styles from './index.module.css';
import { withRouter } from 'react-router';
import NEF from './NEF@2x.png';

class PayResultModal extends React.Component<any> {
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        className={`modal-cover modal-cover-body`}
        width={600}
        onCancel={onCancel}
        footer={null}
        title={null}
        closable={false}
        maskClosable={false}
      >
        <div className={styles.modal_container}>
          <img className={styles.modal_img} src={NEF} alt="" />
          <div className={styles.modal_title}>请问您是否已完成支付</div>
          <div className={styles.modal_desc}>
            {/* 一旦解绑您将不可再登录该团队系统 */}
          </div>
          <Button
            className={`linear-gradient-gray ${styles.sure} normal_button`}
            onClick={() => this.props.history.push('/account')}
            type="primary"
          >
            我已成功支付
          </Button>

          <br />
          <br />
          <Button
            className={`${styles.aggress_sure} normal_button`}
            onClick={() => this.props.history.push('/account')}
          >
            未完成支付
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withRouter(PayResultModal);

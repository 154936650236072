import React from 'react';
import { Row, Col, Button, Radio } from 'antd';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import plan from './plan@2x.png';
import right from './right.png';
import { ViewerTeamComponent } from '../../apollo';
class PlanList extends React.Component {
  render() {
    return (
      <div className={styles.plan_list_container}>
        <img className={styles.plan_list_img} alt="" src={right} />
        {this.props.children}
      </div>
    );
  }
}

interface props {
  uri: string;
  title: string;
}

class PlanCard extends React.Component<props> {
  render() {
    const { uri, title } = this.props;
    return (
      <div className={styles.plan_container}>
        <img className={styles.plan_img} alt="" src={uri} />
        <div className={styles.plan_title}>{title}</div>
        {this.props.children}
      </div>
    );
  }
}

class Plan extends React.Component<any> {
  state = {
    choose: '业务型设计部',
  };
  render() {
    const { choose } = this.state;
    // const params = queryString.parse(window.location.search);
    return (
      <ViewerTeamComponent fetchPolicy="no-cache">
        {({ data, error, loading, refetch }) => {
          if (error || loading || !data) {
            return '';
          }
          if (!data.viewer.team) {
            return <HeaderLayout>请先加入团队</HeaderLayout>;
          }
          if (!data.viewer.team.plan) {
            return <HeaderLayout>请订阅计划</HeaderLayout>;
          }
          const team_plan = data.viewer.team.plan;
          if (team_plan === '业务型设计部') {
            return <HeaderLayout>当前业务已经无法升级</HeaderLayout>;
          }
          return (
            <HeaderLayout>
              <div>
                <p style={{ fontSize: '36px', fontWeight: 500 }}>
                  您想要升级到什么订阅类型
                </p>
                <p>
                  {/* 欢迎使用 */}
                  {/* Nef.Design！Nef.Design先推出“设计部”和“独立项目”两种订阅计划，分别都可以同时订阅多个。例如：订阅2个设计部意味着可以同时进行2个需求。如需帮助请联系您的{' '} */}
                  欢迎使用云设计部！您可以根据对交付速度和服务范围的不同需求，配置不同的订阅计划。如需帮助请联系您的
                  <span style={{ color: '#DEBA6C' }}>
                    专属设计顾问大白 微信ID: NefDesign_Offical
                  </span>
                </p>
              </div>
              <Radio.Group
                onChange={e =>
                  this.setState({
                    choose: e.target.value,
                  })
                }
                value={choose}
                style={{ width: '100%' }}
              >
                <Row gutter={24} style={{ marginTop: '20px' }}>
                  <Col className="gutter-row" span={9} offset={2}>
                    <PlanCard uri={plan} title="基础型设计部">
                      <div className={styles.plan_price}>2999元/月起</div>
                      {/* <div className={styles.plan_price}>3999元/月起</div> */}
                      <br />
                      <PlanList>订阅期内无限量提交需求及修改</PlanList>
                      <PlanList>首次订阅七天无理由退款</PlanList>
                      {/* <PlanList>
                        配备 <span style={{ color: '#7CB2B8' }}>1</span>{' '}
                        个资深设计师 +{' '}
                        <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
                      </PlanList> */}
                      <PlanList>
                        按
                        <span style={{ color: '#7CB2B8' }}>
                          快速递交时间交付，大部分设计需求可以在
                          <span style={{ color: '#7CB2B8' }}>2个工作日</span>
                          交付
                        </span>
                      </PlanList>
                      <Radio
                        disabled={team_plan === '基础型设计部'}
                        className={styles.radio}
                        value={'基础型设计部'}
                      />
                    </PlanCard>
                  </Col>
                  <Col className="gutter-row" span={9} offset={2}>
                    <PlanCard uri={plan} title="业务型设计部">
                      <div className={styles.plan_price}>6999元/月起</div>
                      {/* <div className={styles.plan_price}>6499元/月起</div> */}
                      <br />
                      <PlanList>订阅期内无限量提交需求及修改</PlanList>
                      <PlanList>首次订阅七天无理由退款</PlanList>
                      {/* <PlanList>
                        配备 <span style={{ color: '#7CB2B8' }}>1</span>{' '}
                        个资深设计师 +{' '}
                        <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
                      </PlanList> */}
                      <PlanList>
                        按
                        <span style={{ color: '#7CB2B8' }}>
                          快速递交时间交付，大部分设计需求可以在
                          <span style={{ color: '#7CB2B8' }}>1个工作日</span>
                          交付
                        </span>
                      </PlanList>
                      <Radio className={styles.radio} value={'业务型设计部'} />
                    </PlanCard>
                  </Col>
                </Row>
              </Radio.Group>
              <div style={{ textAlign: 'right', marginTop: '50px' }}>
                前往
                <a
                  style={{ color: 'rgba(222, 186, 108, 1)' }}
                  href="https://yun.nef.design/guide"
                >
                  《常见问题》
                </a>
                ，详细了解云设计部的服务范围
              </div>
              <Button
                className={`linear-gradient-gray ${styles.next}`}
                onClick={() => {
                  if (this.state.choose === '独立项目') {
                    return this.props.history.push(
                      `/create-independent-demand`,
                    );
                  }
                  this.props.history.push(
                    `/update-subscription-plan?plan=${this.state.choose ||
                      '基础型设计部'}`,
                  );
                }}
                type="primary"
              >
                下一步
              </Button>
            </HeaderLayout>
          );
        }}
      </ViewerTeamComponent>
    );
  }
}

export default Plan;

import React from 'react';
import { Row, Col, Button, Radio } from 'antd';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import plan from './plan@2x.png';
import plan3 from './plan3@2x.png';
import right from './right.png';

class PlanList extends React.Component {
  render() {
    return (
      <div className={styles.plan_list_container}>
        <img className={styles.plan_list_img} alt="" src={right} />
        {this.props.children}
      </div>
    );
  }
}

interface props {
  uri: string;
  title: string;
  active?: boolean;
}

class PlanCard extends React.Component<props> {
  render() {
    const { uri, title, active } = this.props;
    return (
      <div
        className={`${styles.plan_container} ${
          active ? styles.plan_container_shadow : ''
        }`}
      >
        <img className={styles.plan_img} alt="" src={uri} />
        <div className={styles.plan_title}>{title}</div>
        {this.props.children}
      </div>
    );
  }
}

class Plan extends React.Component<any> {
  state = {
    choose: '基础型设计部',
  };
  render() {
    const { choose } = this.state;
    return (
      <HeaderLayout>
        <div>
          <p style={{ fontSize: '36px', fontWeight: 500, color: '#69707A' }}>
            选择您的订阅计划
          </p>
          <p style={{ color: '#69707A' }}>
            {/* 欢迎使用 */}
            {/* Nef.Design！Nef.Design先推出“设计部”和“独立项目”两种订阅计划，分别都可以同时订阅多个。例如：订阅2个设计部意味着可以同时进行2个需求。如需帮助请联系您的{' '} */}
            欢迎使用云设计部！您可以根据对交付速度和服务范围的不同需求，配置不同的订阅计划。如需帮助请联系您的
            <span style={{ color: '#DEBA6C' }}>
              专属设计顾问大白 微信ID: NefDesign_Offical
            </span>
          </p>
        </div>
        <Radio.Group
          onChange={e =>
            this.setState({
              choose: e.target.value,
            })
          }
          value={choose}
          style={{ width: '100%' }}
        >
          <Row gutter={60} style={{ marginTop: '20px' }}>
            <Col
              className="gutter-row"
              span={10}
              // offset={1}
              style={{ marginLeft: '160px' }}
              onClick={() => this.setState({ choose: '基础型设计部' })}
            >
              <PlanCard
                uri={plan}
                title="基础型设计部"
                active={choose === '基础型设计部'}
              >
                <div className={styles.plan_price}>2999元/月起</div>
                {/* <div className={styles.plan_price}>3999元/月起</div> */}
                <br />
                {/* <PlanList>
                  最少可同时进行 <span style={{ color: '#7CB2B8' }}>1</span>{' '}
                  个需求
                </PlanList> */}
                <PlanList>订阅期内无限量提交需求及修改</PlanList>
                <PlanList>首次订阅七天无理由退款</PlanList>
                {/* <PlanList>
                  配备 <span style={{ color: '#7CB2B8' }}>1</span> 个资深设计师
                  + <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
                </PlanList> */}
                <PlanList>
                  按<span style={{ color: '#7CB2B8' }}>快速交付时间</span>
                  交付，大部分设计需求可以在
                  <span style={{ color: '#7CB2B8' }}>2个工作日</span>交付
                </PlanList>
                <Radio className={styles.radio} value={'基础型设计部'} />
              </PlanCard>
            </Col>
            <Col
              className="gutter-row"
              span={10}
              // offset={1}
              onClick={() => this.setState({ choose: '业务型设计部' })}
            >
              <PlanCard
                uri={plan3}
                title="业务型设计部"
                active={choose === '业务型设计部'}
              >
                <div className={styles.plan_price}>6999元/月起</div>
                {/* <div className={styles.plan_price}>6499元/月起</div> */}
                <br />
                <PlanList>订阅期内无限量提交需求及修改</PlanList>
                <PlanList>首次订阅七天无理由退款</PlanList>
                {/* <PlanList>
                  配备 <span style={{ color: '#7CB2B8' }}>1</span> 个资深设计师
                  + <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
                </PlanList> */}
                <PlanList>
                  按<span style={{ color: '#7CB2B8' }}>快速交付时间</span>
                  交付，大部分设计需求可以在
                  <span style={{ color: '#7CB2B8' }}>1个工作日</span>交付
                </PlanList>
                <Radio className={styles.radio} value={'业务型设计部'} />
              </PlanCard>
            </Col>
            {/* <Col
              className="gutter-row"
              span={8}
              // offset={1}
              onClick={() => this.setState({ choose: '独立项目' })}
            >
              <PlanCard
                uri={plan2}
                title="独立项目"
                active={choose === '独立项目'}
              >
                <br />
                <PlanList>设计顾问深入了解需求并制定预算</PlanList>
                <PlanList>组建专属项目组进行设计服务</PlanList>
                <PlanList>服务项目扩展至用户体验类设计</PlanList>
                <PlanList>提供驻场、现场服务</PlanList>
                <Radio className={styles.radio} value={'独立项目'} />
              </PlanCard>
            </Col> */}
          </Row>
        </Radio.Group>
        <div style={{ textAlign: 'right', marginTop: '50px' }}>
          前往
          <a
            style={{ color: 'rgba(222, 186, 108, 1)' }}
            href="https://yun.nef.design/guide"
          >
            《常见问题》
          </a>
          ，详细了解云设计部的服务范围
        </div>
        <Button
          className={`linear-gradient-gray ${styles.next}`}
          onClick={() => {
            this.props.history.push(
              `/choose-subscript-plan-pay?plan=${this.state.choose ||
                '基础型设计部'}`,
            );
          }}
          // onClick={() => {
          //   if (this.state.choose === '独立项目') {
          //     return this.props.history.push(`/create-independent-demand`);
          //   }
          //   this.props.history.push(
          //     `/subscription-plan?plan=${this.state.choose || '基础型设计部'}`,
          //   );
          // }}
          type="primary"
        >
          下一步
        </Button>
      </HeaderLayout>
    );
  }
}

export default Plan;

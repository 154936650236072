import React from 'react';
import { Modal, Button } from 'antd';

import styles from './index.module.css';
import { CancelDemandByOwnerComponent } from '../../apollo';
import { message } from '../message';
import { withRouter } from 'react-router';
import warn from '../../image/warn@2x.png';

class CancelDemandModal extends React.Component<any> {
  balancePay = async (send: any) => {
    try {
      await send({
        variables: {
          id: this.props.id,
        },
      });
      message.success('取消成功');
      this.setState({
        visible: false,
      });
      if (this.props.callback) {
        this.props.callback(this.props.id);
      }
    } catch (e) {
      message.error(e);
    }
  };
  state = {
    visible: false,
  };

  render() {
    return (
      <>
        <div
          className={`hand`}
          onClick={() => {
            this.props.hide();
            this.setState({ visible: true });
          }}
        >
          取消需求
        </div>
        <Modal
          visible={this.state.visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.modal_title}>
              <img alt="" className={styles.modal_title_img} src={warn} />
              您确定要取消该需求吗？
            </div>
            <div className={styles.modal_desc}>需求一旦被取消则不可以恢复</div>
            <CancelDemandByOwnerComponent>
              {send => {
                return (
                  <Button
                    className={`linear-gradient-gray ${styles.sure} normal_button`}
                    onClick={this.balancePay.bind(this, send)}
                    type="primary"
                  >
                    确定取消
                  </Button>
                );
              }}
            </CancelDemandByOwnerComponent>
            <br />
            <br />
            <Button
              className={`${styles.aggress_sure} normal_button`}
              onClick={() => this.setState({ visible: false })}
            >
              稍后再说
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(CancelDemandModal);

export const one_day = 24 * 60 * 60 * 1000;

export const festival = {
  2019: {
    on: [
      "2019-1-1",
      "2019-02-04",
      "2019-02-05",
      "2019-02-06",
      "2019-02-07",
      "2019-02-08",
      "2019-02-09",
      "2019-02-10",
      "2019-04-05",
      "2019-04-06",
      "2019-04-07",
      "2019-05-01",
      "2019-05-02",
      "2019-05-03",
      "2019-05-04",
      "2019-06-07",
      "2019-06-08",
      "2019-06-09",
      "2019-09-13",
      "2019-09-14",
      "2019-09-15",
      "2019-10-01",
      "2019-10-02",
      "2019-10-03",
      "2019-10-04",
      "2019-10-05",
      "2019-10-06",
      "2019-10-07",
    ],
    out: [
      "2019-02-02",
      "2019-02-03",
      "2019-04-28",
      "2019-05-05",
      "2019-09-29",
      "2019-10-12",
    ],
  },
  2020: {
    on: [
      "2020-01-01",
      "2020-01-24",
      "2020-01-25",
      "2020-01-26",
      "2020-01-27",
      "2020-01-28",
      "2020-01-29",
      "2020-01-30",
      "2020-04-04",
      "2020-04-05",
      "2020-04-06",
      "2020-05-01",
      "2020-05-02",
      "2020-05-03",
      "2020-05-04",
      "2020-05-05",
      "2020-06-25",
      "2020-06-26",
      "2020-06-27",
      "2020-10-01",
      "2020-10-02",
      "2020-10-03",
      "2020-10-04",
      "2020-10-05",
      "2020-10-06",
      "2020-10-07",
      "2020-10-08",
    ],
    out: [
      "2020-01-19",
      "2020-02-01",
      "2020-04-26",
      "2020-05-09",
      "2020-06-28",
      "2020-09-27",
      "2020-10-10",
    ],
  },
  2021: {
    on: [
      "2021-01-01",
      "2021-02-11",
      "2021-02-12",
      "2021-02-15",
      "2021-02-16",
      "2021-02-17",
      "2021-04-05",
      "2021-05-03",
      "2021-05-04",
      "2021-05-05",
      "2021-06-14",
      "2021-09-21",
      "2021-10-01",
      "2021-10-04",
      "2021-10-05",
      "2021-10-06",
      "2021-10-07",
    ],
    out: [
      "2021-02-20",
      "2021-04-25",
      "2021-05-08",
      "2021-09-18",
      "2021-09-26",
      "2021-10-09",
    ],
  },
  2022: {
    on: [
      "2022-01-03",
      "2022-01-31",
      "2022-02-01",
      "2022-02-02",
      "2022-02-03",
      "2022-02-04",
      "2022-04-04",
      "2022-04-05",
      "2022-05-02",
      "2022-05-03",
      "2022-05-04",
      "2022-06-03",
      "2022-09-12",
      "2022-10-03",
      "2022-10-04",
      "2022-10-05",
      "2022-10-06",
      "2022-10-07",
    ],
    out: ["2022-01-29", "2022-01-30", "2022-04-02", "2022-10-08", "2022-10-09"],
  },
};

export function getFinishDate(start_date: Date, cost_day: number) {
  // const today = new Date();
  // let time_difference = today.getDay() - 5;
  // fix 目前只支持7天内的业务
  let time_difference =
    start_date.getDay() + cost_day > 5 ? cost_day + 7 - 5 : cost_day;
  // let time_difference = 7 + cost_day - start_date.getDay();
  for (let i = 0; i < cost_day; i++) {
    const date = new Date(start_date.getTime() + one_day * i);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    // @ts-ignore
    const on = festival[year].on;
    // @ts-ignore
    const out = festival[year].out;
    // @ts-ignore
    const is_on = on.find((item) => item === `${year}-${month}-${day}`);
    // @ts-ignore
    const is_out = out.find((item) => item === `${year}-${month}-${day}`);
    if (is_on) {
      time_difference = time_difference + 1;
    } else if (is_out) {
      time_difference = time_difference - 1;
    }
  }
  time_difference = time_difference >= 0 ? time_difference : 0;
  // const end_time = cost_day + time_difference;
  return new Date(
    start_date.getFullYear(),
    start_date.getMonth(),
    start_date.getDate() + time_difference,
    start_date.getHours(),
    start_date.getMinutes(),
    start_date.getSeconds()
  );
}

import React from 'react';
import {
  Button,
  Form,
  Input,
  Radio,
  Checkbox,
} from 'antd';
import queryString from 'query-string';
import SuccessModal from '../../components/success-modal';
import SureDemandModal from '../../components/sure-demand-modal';

import { CreateDemandComponent } from '../../apollo';
import { message } from '../../components/message';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import tip from './tip@2x.png';
import moment from 'moment';
import { getFilesName } from '../../lib';
import { findProjectItem } from '../../db/product.db';
import { oss_uri } from '../../config';
// import { QrCode } from '../choose-type-new/qr_code';
import tip_img from './tip-img@2x.png';
import { QrCodeText } from '../choose-type-new/qr_code_text';

const { TextArea } = Input;

export class LittleCard extends React.Component<any> {
  render() {
    const { uri, title, content, description } = this.props;
    return (
      <div className={styles.card_container}>
        {this.props.children}
        <img className={styles.card_img} alt="" src={oss_uri + uri} />
        <div>
          <div className={styles.card_title}>{title}</div>
          <div className={styles.card_content}>{content}</div>
          <div className={styles.card_description}>{description}</div>
        </div>
      </div>
    );
  }
}

class Tip extends React.Component<any> {
  render() {
    const { style } = this.props;
    return (
      <div style={style} className={styles.tip_container}>
        <img className={styles.tip_img} alt="" src={tip} />
        {this.props.children}
      </div>
    );
  }
}

class CreateDemandSpecial extends React.Component<any> {
  state = {
    visible: false,
    sure_visible: false,
    values: {},
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        this.setState({
          sure_visible: true,
          values,
        });
      }
    });
  };

  sure = async (send: any) => {
    this.setState({
      sure_visible: false,
    });
    try {
      // color_description: color_preference!
      const project_name = queryString.parse(window.location.search).type;
      const { values, uris, ui_uris }: any = this.state;
      const { data } = await send({
        variables: {
          CreateDemand: {
            ...values,
            project_name,
            size_description:
              values.choose_size === '其他尺寸规格'
                ? values.size_description
                : values.choose_size,
            description_files: getFilesName(uris, values.description_files),
            ui_description_files: getFilesName(
              ui_uris,
              values.ui_description_files,
            ),
            file_format: values.file_format.join(','),
            choose_size: undefined,
          },
        },
      });
      // message.success('创建成功');
      const { form } = this.props;
      const { resetFields } = form;
      resetFields();
      const date = moment(data && data.createDemand.finish_date).format(
        'YYYY-MM-DD HH:mm',
      );
      // const date = moment(data && data.createDemand.finish_date).format(
      //   'YYYY-MM-DD HH:mm:ss',
      // );
      const id = data && data.createDemand.id;
      const search = queryString.parse(window.location.search);
      // this.setState({ visible: true });
      // this.props.history.push('/service');
      this.props.history.push(
        `/choose-plan?date=${date}&id=${id}&type=${search.type}`,
      );
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const search = queryString.parse(window.location.search);
    const item = findProjectItem(String(search.type));
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { visible, sure_visible } = this.state;
    return (
      <CreateDemandComponent>
        {(send, { loading, data }) => {
          return (
            <div>
              <HeaderLayout>
                <div style={{ position: 'relative' }}>
                  <div className={styles.title}>创建新的设计需求</div>
                  <div className={styles.desc}>
                    详细、规范的描述您的设计需求，能够大幅提高设计方案的精准度
                  </div>
                  {/* <QrCode /> */}
                  <QrCodeText />
                </div>
                <SuccessModal
                  visible={visible}
                  desc={
                    <>
                      `您可以在需求队列里查看需求进程。`
                      <br /> `现在系统预计
                      {moment(data && data.createDemand.finish_date).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                      完成，如有变动，会由设计顾问与您联系。`
                    </>
                  }
                />
                <SureDemandModal
                  onCancel={() => this.setState({ sure_visible: false })}
                  onSure={this.sure.bind(this, send)}
                  visible={sure_visible}
                />
                {/* <Modal
                  visible={this.state.sure_visible}
                  className={`modal-cover modal-cover-body`}
                  width={600}
                  onCancel={() => this.setState({ sure_visible: false })}
                  footer={null}
                  title={null}
                >
                  <div className={styles.modal_container}>
                    <div className={styles.modal_title}>
                      <img className={styles.warn_img} src={warn} alt="" />
                      请确认提交该需求吗？
                    </div>
                    <div className={styles.modal_desc}>
                      请确保您需要出现在交付物上的所有文案内容都已填写完成；
                      <br />
                      如提交需求后修改文案内容，风格偏好等，交付时间可能会顺延
                    </div>
                    <Button
                      className={`linear-gradient-gray ${styles.sure2} normal_button`}
                      loading={loading}
                      onClick={this.sure.bind(this, send)}
                      type="primary"
                    >
                      确认提交
                    </Button>
                    <br />
                    <br />
                    <Button
                      className={`${styles.aggress_sure} normal_button`}
                      onClick={() => this.setState({ sure_visible: false })}
                    >
                      再完善一下
                    </Button>
                  </div>
                </Modal> */}
                <div className={styles.container}>
                  {/* <div className={styles.title}>新增需求</div> */}
                  <div
                    style={{
                      display: 'flex',
                      padding: '30px',
                      marginTop: '30px',
                    }}
                  >
                    <div className={styles.left}>
                      <Form className="login-form">
                        <Form.Item label="需求名称">
                          {getFieldDecorator('name', {
                            rules: [
                              { required: true, message: '请输入需求名称!' },
                            ],
                          })(
                            <Input
                              maxLength={20}
                              className="king_normal_input"
                              placeholder="例：双11大促食品类Banner设计"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('name') &&
                              getFieldValue('name').length) ||
                              0}
                            /20
                          </span>
                        </Form.Item>
                        <Form.Item label="logo 需要体现的寓意、包含的要素等">
                          {getFieldDecorator('description', {
                            rules: [
                              { required: true, message: '请输入需求描述!' },
                            ],
                          })(
                            <TextArea
                              rows={4}
                              maxLength={200}
                              className="king_normal_text_area"
                              placeholder="比如 logo 需要体现出动感、活力，包含 天空、鹰 等要素"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('description') &&
                              getFieldValue('description').length) ||
                              0}
                            /200
                          </span>
                        </Form.Item>
                        <div>
                          或上传已有的需求文档，以及设计稿中需出现的文案、素材图片等，最多可以上传5个文件
                        </div>
                        <br />
                        <Form.Item label="Logo 对应的中英文品牌名称">
                          {getFieldDecorator('logo_name', {
                            rules: [
                              {
                                required: true,
                                message: '请输入Logo 对应的中英文品牌名称!',
                              },
                            ],
                          })(
                            <Input
                              maxLength={20}
                              className="king_normal_input"
                              placeholder="比如 云设计部 Nef.Design"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('color_description') &&
                              getFieldValue('color_description').length) ||
                              0}
                            /20
                          </span>
                        </Form.Item>

                        <Form.Item label="Logo 类型">
                          <>
                            <span style={{ color: '#AEB1B5' }}>
                              确定需要的 logo 类型
                            </span>
                            <br />
                            {getFieldDecorator('logo_type', {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择Logo 类型!',
                                },
                              ],
                            })(
                              <Radio.Group>
                                <Radio value={'纯图标'}>{'纯图标'}</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  以抽象或具象的图形表达品牌
                                </span>
                                <br />
                                <Radio value={'字形变化'}>{'字形变化'}</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  以可辨识的文字或字母为基础设计logo
                                </span>
                                <br />
                                <Radio value={'logo组合'}>{'logo组合'}</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  图标与标准字组合
                                </span>
                                <br />
                              </Radio.Group>,
                            )}
                          </>
                        </Form.Item>
                        <Form.Item label="源文件格式要求">
                          <div>
                            <span style={{ color: '#AEB1B5' }}>
                              图片格式（可多选）
                            </span>
                            <br />
                            <Form.Item>
                              {getFieldDecorator('file_format', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选图片格式要求!',
                                  },
                                ],
                              })(
                                <Checkbox.Group>
                                  <Checkbox value=".jpg/jpeg">
                                    .jpg/jpeg
                                  </Checkbox>
                                  <br />
                                  <br />
                                  <Checkbox value=".png">.png</Checkbox>
                                  <br />
                                  <br />
                                  <Checkbox value=".pdf">.pdf</Checkbox>
                                </Checkbox.Group>,
                              )}
                            </Form.Item>
                          </div>
                          <div>
                            <span style={{ color: '#AEB1B5' }}>源文件格式</span>
                            <br />
                            {getFieldDecorator('original_file_format', {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择源文件格式要求!',
                                },
                              ],
                            })(
                              <Radio.Group>
                                <Radio value="不做特定要求">不做特定要求</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将根据您的需求自行选择适合的设计工具进行设计
                                </span>
                                <br />
                                <Radio value=".psd">.psd</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将使用 Adobe Photoshop进行设计
                                </span>
                                <br />
                                <Radio value=".ai">.ai</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将使用 Adobe Illustrator进行设计
                                </span>
                                <br />
                                <Radio value=".sketch">.sketch</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将使用 Sketch 进行设计
                                </span>
                                <br />
                              </Radio.Group>,
                            )}{' '}
                          </div>
                        </Form.Item>
                        {/* <Form.Item label="其他要求说明与备注">
                          {getFieldDecorator('remark')(
                            <TextArea
                              rows={4}
                              maxLength={100}
                              className="king_normal_text_area"
                              placeholder="请填写其他特殊要求"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('remark') &&
                              getFieldValue('remark').length) ||
                              0}
                            /100
                          </span>
                        </Form.Item> */}
                      </Form>
                    </div>
                    <div>
                      <Tip style={{ marginTop: '46px' }}>
                        · 为您的需求命名，帮助您在需求队列中分辨出不同的需求。
                        <br />· 建议的格式为：内容+物料类型，如“双十一促销海报”
                      </Tip>
                      <Tip style={{ marginTop: '320px' }}>
                        <img
                          style={{ width: '360px', height: '618px' }}
                          src={tip_img}
                          alt="提示"
                        />
                      </Tip>
                    </div>
                  </div>
                </div>
              </HeaderLayout>
              <div className={styles.bottom}>
                <div className={styles.bottom_container}>
                  <div className={styles.bottom_title}>
                    {item && <LittleCard {...item} />}
                  </div>
                  <div className={styles.bottom_right}>
                    <Button
                      onClick={() => this.props.history.goBack()}
                      className={`${styles.bottom_button} normal_button`}
                    >
                      上一步
                    </Button>
                    <Button
                      className={`${
                        styles.bottom_button
                      } linear-gradient-gray normal_button`}
                      style={{ color: '#FFFFFF' }}
                      onClick={this.handleSubmit}
                      type="primary"
                    >
                      下一步
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </CreateDemandComponent>
    );
  }
}

export const CreateDemandSpecialForm: any = Form.create({
  name: 'create_demand_special',
})(CreateDemandSpecial);

import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Button, Popover, Tabs, Pagination } from 'antd';
import {
  // ViewerDemandsComponent,
  ViewerTeamComponent,
  OwnerDemandsComponent,
} from '../../apollo';
import moment from 'moment';
import { withRouter } from 'react-router';
import empty from './empty@2x.png';
import CancelDemandModal from '../../components/cancel-demand-modal';
// import ChangeDemandModal from './change-demand-modal';
import { StatusTag } from '../demand-chat';
import default_icon from './default-icon.png'
import { SortTable } from './sort-demand-modal'

const { TabPane } = Tabs;

interface CardProps {
  id: string;
  type: string;
  title: string;
  user_name: string;
  order: any;
  // header_img: string;
  time: string;
  finish_date: string;
  headimgurl: string;
  history: any;
  status: any;
  refetch: any;
  cover_uri: any;
  is_new: boolean;
  team_status: string;
  position: number;
}


class CardComponent extends React.Component<CardProps> {
  state = {
    visible: false,
  };

  handleVisibleChange = (visible: any) => {
    this.setState({ visible });
  };

  render() {
    const {
      type,
      title,
      user_name,
      headimgurl,
      id,
      status,
      order,
      finish_date,
      is_new,
      cover_uri,
      team_status,
      position,
    } = this.props;
    console.log(status, team_status)

    return (
      <div style={{ position: 'relative' }}>
        {status === '进行中' && (
          <Popover
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            content={
              <div>
                <CancelDemandModal
                  id={id}
                  hide={() => this.handleVisibleChange(false)}
                  callback={() => this.props.refetch()}
                />
              </div>
            }
            placement="bottomRight"
            title={null}
            // trigger="hover"
            trigger="click"
          >
            <div className={`${styles.right_menu} hand`}>...</div>
          </Popover>
        )}
        {/* 进行中是为了适配原始数据 */}
        {(status === '等待中' || status === '待确认') && (
          <Popover
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            content={
              <div>
                <SortTable
                  hide={() => this.handleVisibleChange(false)}
                  callback={() => this.props.refetch()}
                />
                <CancelDemandModal
                  id={id}
                  hide={() => this.handleVisibleChange(false)}
                  callback={() => this.props.refetch()}
                />
              </div>
            }
            placement="bottomRight"
            title={null}
            // trigger="hover"
            trigger="click"
          >
            <div className={`${styles.right_menu} hand`}>...</div>
          </Popover>
        )}
        <div
          onClick={() => this.props.history.push(`detail?id=${id}`)}
          className={`${styles.card_container} hand`}
        >
          {/* <img alt="缩略图" src={cover_uri ? cover_uri : default_icon} className={styles.default_icon} /> */}
          <div style={{
            background: `url(${cover_uri || default_icon})`,
            backgroundSize: "cover",
            width: "180px",
            marginRight: "20px",
          }}></div>
          <div>
            <div className={styles.card_container_type}>{type} &nbsp;&nbsp;&nbsp;<StatusTag is_new_ui={is_new} status={status} team_status={team_status} /></div>
            <div
              onClick={() => this.props.history.push(`detail?id=${id}`)}
              className={`${styles.card_container_title} hand`}
            >
              {title}
            </div>
            {(status === '进行中') && (
              <div className={styles.card_container_time}>
                预计{moment(finish_date).format('YYYY-MM-DD HH:mm')}前交付
              </div>
            )}
            {(status === '等待中') && (
              <div className={styles.card_container_time}>
                需求已确认，等待排期中，当前排序{position || '无'}
              </div>
            )}
            {(status === '待确认') && (
              <div className={styles.card_container_time}>
                待设计师确认需求明细后，反馈交付时间，当前排序{position || '无'}
              </div>
            )}

            {status === '已完成' && (
              <div className={styles.card_container_time}>
                已于{moment(finish_date).format('YYYY-MM-DD HH:mm')}交付
              </div>
            )}
            {status === '已取消' && (
              <div className={styles.card_container_time}>
                需求已取消
              </div>
            )}
            {order && status === '待付款' && (
              <div style={{ position: 'relative' }}>
                <div className={styles.card_container_time}>待付款</div>
                <div
                  className={`hand ${styles.card_to_pay}`}
                  onClick={e => {
                    // e.preventDefault();
                    e.stopPropagation();
                    // e.nativeEvent.stopImmediatePropagation();
                    this.props.history.push(
                      `/pay-order?order_id=${order && order.id}`,
                    );
                  }}
                >
                  立即付款
                </div>
              </div>
            )}
            <div className={styles.card_container_user_name}>
              <img className="header_img_card" src={headimgurl || ''} alt="" />
              {user_name}
            </div>
          </div>
          {/* <div className={styles.card_container_time}>{time} 提交</div> */}
        </div >
      </div >
    );
  }
}

// @ts-ignore
const Card = withRouter(CardComponent);

interface ListProps {
  title: string;
  demands: any[];
  refetch: any;
  count?: number;
  team_status?: string;
}

class List extends React.Component<ListProps> {
  state = {
    open: true,
  };
  render() {
    let { demands, team_status } = this.props;
    // const { open } = this.state;
    // const first = demands.slice(0, 4);
    // const second = demands.slice(4, demands.length);
    // 后端返回的数据中虽然顺序是按照position排序，但是position不是连续的，这里重置postion
    // let count = 0;
    // demands= demands.map(item => {
    //   if (item.status === '等待中' || item.status === '待确认') {
    //     item.position = ++count
    //   }
    //   return item
    // })
    // console.log(demands)
    return (
      <div>
        {/* <div className={styles.list_title}>
          {title}
        </div> */}
        {demands.map((item, index) => (
          <Card
            key={item.id}
            team_status={team_status}
            id={item.id}
            cover_uri={item.cover_uri}
            refetch={this.props.refetch}
            order={item.order}
            is_new={item.is_new_ui}
            status={item.status}
            type={item.project.title}
            title={item.name}
            finish_date={item.finish_date}
            user_name={item.owner.username}
            headimgurl={item.owner.headimgurl}
            position={item.position}
            time={moment(item.created_date).format('YYYY-MM-DD')}
          />
        ))}
        {/* {open &&
          second.map(item => (
            <Card
              refetch={this.props.refetch}
              key={item.id}
              team_status={team_status}
              id={item.id}
              cover_uri={item.cover_uri}
              is_new={item.is_new_ui}
              status={item.status}
              order={item.order}
              type={item.project.title}
              title={item.name}
              finish_date={item.finish_date}
              user_name={item.owner.username}
              position={item.position}
              headimgurl={item.owner.headimgurl}
              time={moment(item.created_date).format('YYYY-MM-DD')}
            />
          ))} */}
      </div>
    );
  }
}

class PageComponent extends React.Component<any> {
  state = {
    page: 1,
  }
  render() {
    const { team_status, status, count } = this.props;
    return <OwnerDemandsComponent variables={{
      take: 10,
      status,
      skip: (this.state.page - 1) * 10
    }}
      fetchPolicy="no-cache">
      {({ data, loading, error, refetch }) => {
        if (loading || error || !data) {
          return '';
        }
        const list = data.ownerDemands.list;
        if (list.length === 0) {
          return (
            <div className={styles.container}>
              <div className={styles.title}>需求管理</div>
              <div className={styles.desc}>
                可以对需求进行各类操作，比如
                查看详情、取消、调整排期顺序等
              </div>
              <img src={empty} alt="" className={styles.empty_img} />
              <div className={styles.empty_text}>暂无需求</div>
              <Button
                className={`linear-gradient-gray ${styles.next}`}
                onClick={() =>
                  this.props.history.push('/choose-demand-type')
                }
                type="primary"
              >
                <span style={{ fontSize: '20px' }}> + &nbsp;</span>{' '}
                新增需求
              </Button>
            </div>
          );
        }
        return (
          <div className={styles.container}>
            <div className={styles.title}>需求管理</div>
            <div className={styles.desc}>
              可以对需求进行各类操作，比如
              查看详情、取消、调整排期顺序等
            </div>
            <Button
              className={`linear-gradient-gray ${styles.add_button}`}
              onClick={() =>
                this.props.history.push('/choose-demand-type')
              }
              // onClick={() => this.props.history.push('/choose-type')}
              type="primary"
            >
              <span style={{ fontSize: '20px' }}> + &nbsp;</span>{' '}
              新增需求
            </Button>
            <br />
            <List
              title={`进行中（${list.length}）`}
              count={count || 1}
              demands={list}
              refetch={refetch}
              team_status={team_status}
            />
            <Pagination
              defaultPageSize={10}
              className={styles.page}
              defaultCurrent={this.state.page}
              total={data.ownerDemands.count}
              onChange={(page: any) => this.setState({ page })}
            />
          </div>
        );
      }}
    </OwnerDemandsComponent>
  }
}

// @ts-ignore
const Page = withRouter(PageComponent);

class Service extends React.Component<any> {
  render() {
    return (
      <HeaderLayout>
        <ViewerTeamComponent>
          {({ data, error, loading }) => {
            if (loading || error || !data) {
              return '';
            }
            const team = data.viewer.team;
            const is_plan = team && team.plan;
            const count = team && team.plan_count;
            let team_status = "正常";
            if (!is_plan) {
              team_status = "待付款"
            } else if (team && team.plan && new Date(team.plan_end_date) <= new Date()) {
              team_status = "待续费"
            }
            return (
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={`进展中`}
                  key="1"
                >
                  <Page status={"pc进行中"} team_status={team_status} count={count} />
                </TabPane>
                <TabPane
                  tab={`已完成`}
                  key="2"
                >
                  <Page status={"已完成"} team_status={team_status} count={count} />
                </TabPane>
                <TabPane
                  tab={`已取消`}
                  key="3"
                >
                  <Page status={"已取消"} team_status={team_status} count={count} />
                </TabPane>
              </Tabs>
            );
          }}
        </ViewerTeamComponent>
      </HeaderLayout>
    );
  }
}

export default Service;

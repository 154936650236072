import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Breadcrumb, Radio, Checkbox, InputNumber } from 'antd';
import left from './left@2x.png';
import ali from './ali@2x.png';
import wechat from './wechat@2x.png';
import bank from './bank@2x.png';
// import { CreateOrderComponent } from '../../apollo';
import { message } from '../../components/message';
import PayResultModal from '../../components/pay-result-modal';
import WeChatPayModalComponent from '../../components/we-chat-pay-modal';
import { UserAgreemnet } from '../../components/user-agreement';

class Recharge extends React.Component {
  state = {
    value: '支付宝',
    aggress: false,
    result_modal: false,
    we_chat_modal: false,
    uri: '',
    order_id: '',
    price: 0,
    visible: false
  };
  handleSubmit = async (send: any) => {
    const { price, value, aggress } = this.state;
    if (!price) {
      return message.error(new Error('请选输入金额'));
    }
    if (!value) {
      return message.error(new Error('请选择支付方式'));
    }
    if (!aggress) {
      return message.error(new Error('请同意协议后充值'));
    }
    try {
      const { data } = await send({
        variables: {
          CreateOrder: { price: this.state.price, pay_type: value },
        },
      });
      const uri = data.createOrder.uri;
      if (value === '支付宝') {
        this.setState({ result_modal: true, uri });
        window.open(uri);
      } else {
        this.setState({
          we_chat_modal: true,
          uri,
          price: this.state.price,
          order_id: data.createOrder.id,
        });
      }
    } catch (e) {
      message.error(e);
    }
  };
  render() {
    const { aggress } = this.state;
    return (
      <HeaderLayout>
        <PayResultModal
          visible={this.state.result_modal}
          open_uri={this.state.uri}
          onCancel={() => this.setState({ result_modal: false })}
        />
        <WeChatPayModalComponent
          visible={this.state.we_chat_modal}
          uri={this.state.uri}
          id={this.state.order_id}
          price={this.state.price}
          onCancel={() => this.setState({ we_chat_modal: false })}
        />
        <div className={styles.container}>
          <Breadcrumb>
            <Breadcrumb.Item>充值</Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>充值其他金额</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.card_container}>
            <div className={styles.card}>
              <div className={styles.title}>
                <img className={styles.title_img} alt="" src={left} />
                直接充值其他金额
              </div>
              <div style={{ marginLeft: '30px' }}>
                <div className={styles.label}>输入要充值的金额</div>
                <InputNumber
                  className="king_normal_input"
                  style={{ width: '100%' }}
                  placeholder="输入充值金额"
                  value={this.state.price}
                  onChange={e => {
                    this.setState({
                      price: e,
                      // price: e.target.value,
                    });
                  }}
                />
                <div className={styles.label}>选择付款方式：</div>
                <Radio.Group
                  onChange={e =>
                    this.setState({
                      value: e.target.value,
                    })
                  }
                  value={this.state.value}
                  style={{ display: 'flex' }}
                >
                  <div className={styles.pay_container}>
                    <Radio value={'支付宝'}>
                      <img className={styles.pay_img} alt="" src={ali} />
                      支付宝
                    </Radio>
                  </div>
                  <div className={styles.pay_container}>
                    <Radio value={'微信'}>
                      <img className={styles.pay_img} alt="" src={wechat} />
                      微信
                    </Radio>
                  </div>
                </Radio.Group>
                {/* <CreateOrderComponent>
                  {send => {
                    return (
                      <Button
                        className={`linear-gradient-gray ${styles.next}`}
                        onClick={this.handleSubmit.bind(this, send)}
                        type="primary"
                      >
                        确认充值
                      </Button>
                    );
                  }}
                </CreateOrderComponent> */}
                <div style={{ textAlign: 'center' }}>
                  <Checkbox
                    onChange={() => this.setState({ aggress: !aggress })}
                    checked={aggress}
                    className={styles.aggress}
                  >
                    <span className="read_me">我已阅读并同意云设计部</span>
                  </Checkbox>
                  <span
                    onClick={() => this.setState({ visible: true })}
                    style={{
                      color: 'rgba(222, 186, 108, 1)',
                      marginLeft: '-20px',
                    }}
                    className="hand"
                  >
                    《平台使用协议》《设计服务协议》
                  </span>
                  <UserAgreemnet visible={this.state.visible}
                    onUserAgreementCancel={() => { this.setState({ visible: false, aggress: false }) }}
                    onUserAgreementOk={() => { this.setState({ visible: false, aggress: true }) }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.card} style={{ marginLeft: '24px' }}>
              <div className={styles.title}>
                <img className={styles.title_img} alt="" src={bank} />
                对公账户打款
              </div>
              <div className={styles.text}>
                <span style={{ color: '#69707A' }}>
                  我们也提供银行转账充值方式，汇款信息如下：
                </span>
                <br />
                【账户名】天津奈夫科技合伙企业（有限合伙）
                <br />
                【开户行】招商银行股份有限公司北京中关村支行
                <br />
                【账号】110935220110101
                <br />
              </div>
              <div className={styles.tip}>
                汇款成功后，敬请联系您的设计顾问
                <br />
                我们将于1个工作日内与您核对汇款信息并更新账户余额。
              </div>
              <div className={styles.tip2}>
                * 注此充值方式不能享受套餐优惠。
              </div>
            </div>
          </div>
        </div>
      </HeaderLayout>
    );
  }
}

export default Recharge;

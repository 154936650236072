import React from 'react';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import { Button, Radio, Checkbox } from 'antd';
import ali from '../recharge/ali@2x.png';
import wechat from '../recharge/wechat@2x.png';
import { ViewerTeamComponent, CreatePlanRecordComponent } from '../../apollo';
import { message } from '../../components/message';
import PayResultModal from '../../components/pay-result-modal';
import WeChatPayModalComponent from '../../components/we-chat-pay-modal-two';
import BalancePayModal from '../../components/balance-pay-modal';
import top from './top@2x.png';
import { plan } from './subscription-plan.db';
import queryString from 'query-string';
import { UserAgreemnet } from '../../components/user-agreement';

class SubscriptionPlan extends React.Component<any> {
  state = {
    value: '支付宝',
    cycle_name: '支付宝',
    number: 1,
    cycle: 4499,
    // cycle: 3999,
    aggress: false,
    result_modal: false,
    we_chat_modal: false,
    balance_modal: false,
    uri: '',
    order_id: '',
    price: 4499,
    visible: false
    // price: 3999,
  };

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    let title = String(params.plan || '基础型设计部');
    if (!plan[title]) {
      title = '基础型设计部';
    }
    const item = plan[title].packages[0];
    this.setState({
      cycle: item.count * item.price,
      cycle_name: item.name,
    });
  }

  handleSubmit = async (send: any, balance: number) => {
    const { value, aggress, number, cycle } = this.state;
    if (!aggress) {
      return message.error(new Error('请同意协议后充值'));
    }
    if (value === '账户余额' && balance < number * cycle) {
      return message.error(new Error('余额不足请充值'));
    }
    try {
      const params = queryString.parse(window.location.search);
      let plan_type = String(params.plan || '基础型设计部');
      if (!plan[plan_type]) {
        plan_type = '基础型设计部';
      }
      const cycle_name = this.state.cycle_name;
      const { data } = await send({
        variables: {
          CreatePlanRecord: {
            number,
            cycle,
            pay_type: value,
            name: plan_type,
            // name: '基础型设计部',
            cycle_name,
            plan_type,
          },
        },
      });
      const uri = data.createPlanRecord.uri;
      if (value === '支付宝') {
        this.setState({ result_modal: true, uri });
        window.open(uri);
      } else if (value === '微信') {
        this.setState({
          we_chat_modal: true,
          uri,
          price: number * cycle,
          order_id: data.createPlanRecord.id,
        });
      } else {
        this.setState({
          balance_modal: true,
          price: number * cycle,
          order_id: data.createPlanRecord.id,
        });
      }
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const { aggress, number, cycle } = this.state;
    const params = queryString.parse(window.location.search);
    let title = String(params.plan || '基础型设计部');
    if (!plan[title]) {
      title = '基础型设计部';
    }
    const desc = plan[title].desc;
    const packages = plan[title].packages;
    return (
      <HeaderLayout>
        <PayResultModal
          visible={this.state.result_modal}
          open_uri={this.state.uri}
          onCancel={() => this.setState({ result_modal: false })}
        />
        <WeChatPayModalComponent
          visible={this.state.we_chat_modal}
          uri={this.state.uri}
          id={this.state.order_id}
          price={this.state.price}
          onCancel={() => this.setState({ we_chat_modal: false })}
        />
        <BalancePayModal
          visible={this.state.balance_modal}
          price={this.state.price}
          id={this.state.order_id}
          onCancel={() => this.setState({ balance_modal: false })}
          router={`subscription-success?plan=${title}&count=${number}`}
        />
        <div className={styles.container}>
          <img className={styles.top_image} src={top} alt="" />
          <div className={styles.message}>
            已选择 <span style={{ color: '#7CB2B8' }}> {title}</span>
          </div>
          {desc()}
          <div className={styles.label}>选择可以同时进行的需求数目：</div>
          <Radio.Group
            onChange={e =>
              this.setState({
                number: e.target.value,
              })
            }
            value={this.state.number}
            style={{ display: 'flex' }}
          >
            <div className={styles.pay_container}>
              <Radio value={1}>1个</Radio>
            </div>
            <div className={styles.pay_container}>
              <Radio value={2}>2个</Radio>
            </div>
            <div className={styles.pay_container}>
              <Radio value={3}>3个</Radio>
            </div>
          </Radio.Group>
          <div className={styles.label}>选择订阅周期：</div>
          <Radio.Group
            onChange={e => {
              const cycle_name = e.target.value;
              const item = packages.find(
                ({ name }: { name: string }) => name === cycle_name,
              );
              this.setState({
                cycle: item.count * item.price,
                cycle_name,
              });
            }}
            value={this.state.cycle_name}
            style={{ display: 'flex' }}
          >
            {packages.map((item: any) => {
              return (
                <div className={styles.cycle_container} key={item.name}>
                  <Radio
                    value={item.name}
                    // value={item.price * item.count}
                    className={styles.cycle_radio}
                  />
                  <div className={styles.cycle_price}>
                    {item.name} <br />
                    <span className={styles.cycle_price_one}>
                      {item.price} 元/月
                    </span>
                  </div>
                </div>
              );
            })}
            {/* <div className={styles.cycle_container}>
              <Radio value={3999} className={styles.cycle_radio} />
              <div className={styles.cycle_price}>
                月度订阅 <br />
                <span className={styles.cycle_price_one}>3999 元/月</span>
              </div>
            </div>
            <div className={styles.cycle_container}>
              <Radio value={3699 * 3} className={styles.cycle_radio} />
              <div className={styles.cycle_price}>
                季度订阅
                <br />
                <img src={month} className={styles.cycle_price_img} />
                <span className={styles.cycle_price_one}>3699 元/月</span>
              </div>
            </div>
            <div className={styles.cycle_container}>
              <Radio value={3399 * 12} className={styles.cycle_radio} />
              <div className={styles.cycle_price}>
                年度订阅
                <br />
                <span className={styles.cycle_price_one}>3399 元/月</span>
              </div>
            </div> */}
          </Radio.Group>
          <ViewerTeamComponent>
            {({ data, error, loading, refetch }) => {
              if (error || loading || !data) {
                return '';
              }
              const balance =
                (data.viewer.team && data.viewer.team.balance) || 0;
              return (
                <>
                  <div className={styles.label}>选择付款方式：</div>
                  <Radio.Group
                    onChange={e =>
                      this.setState({
                        value: e.target.value,
                      })
                    }
                    value={this.state.value}
                    style={{ display: 'flex' }}
                  >
                    <div className={styles.pay_container}>
                      <Radio value={'支付宝'}>
                        <img className={styles.pay_img} alt="" src={ali} />
                        支付宝
                      </Radio>
                    </div>
                    <div className={styles.pay_container}>
                      <Radio value={'微信'}>
                        <img className={styles.pay_img} alt="" src={wechat} />
                        微信
                      </Radio>
                    </div>
                    <div className={styles.pay_container}>
                      <Radio
                        value={'账户余额'}
                        disabled={balance <= cycle * number}
                      >
                        账户余额 ¥{balance}
                      </Radio>
                    </div>
                  </Radio.Group>
                  <div className={styles.bottom}>
                    <div className={styles.bottom_container}>
                      <div className={styles.price_text}>
                        总计：
                        <span style={{ color: '#7CB2B8' }}>
                          {number * cycle}元
                        </span>
                      </div>
                      <div className={styles.bottom_right}>
                        <div style={{ textAlign: 'center' }}>
                          <Checkbox
                            onChange={() =>
                              this.setState({ aggress: !aggress })
                            }
                            checked={aggress}
                            className={styles.aggress}
                          >
                            <span className="read_me">我已阅读并同意云设计部</span>
                          </Checkbox>
                          <span
                            onClick={() => this.setState({ visible: true })}
                            style={{
                              color: 'rgba(222, 186, 108, 1)',
                              marginLeft: '-20px',
                            }}
                            className="hand"
                          >
                            《平台使用协议》《设计服务协议》
                          </span>
                          <UserAgreemnet visible={this.state.visible}
                            onUserAgreementCancel={() => { this.setState({ visible: false, aggress: false }) }}
                            onUserAgreementOk={() => { this.setState({ visible: false, aggress: true }) }}
                          />
                        </div>
                        <CreatePlanRecordComponent>
                          {send => {
                            return (
                              <>
                                {!aggress ? (
                                  <Button
                                    className={`${styles.next_disable}`}
                                    disabled={!aggress}
                                    type="primary"
                                  >
                                    确认订阅计划
                                  </Button>
                                ) : (
                                  <Button
                                    className={`linear-gradient-gray ${styles.next}`}
                                    onClick={this.handleSubmit.bind(
                                      this,
                                      send,
                                      balance,
                                    )}
                                    type="primary"
                                  >
                                    确认订阅计划
                                  </Button>
                                )}
                              </>
                            );
                          }}
                        </CreatePlanRecordComponent>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </ViewerTeamComponent>
        </div>
      </HeaderLayout>
    );
  }
}

export default SubscriptionPlan;

import React from 'react';
import { Button } from 'antd';
import moment from "moment";
import { withRouter } from 'react-router';

import styles from './index.module.css';
import {
  ChangeTeamComponent,
  ViewerComponent
} from '../../apollo';
import { message } from '../../components/message';
import plan from '../plan/plan@2x.png';
import plan3 from '../plan/plan3@2x.png';
import { Link } from 'react-router-dom';

export class ChooseTeamComponent extends React.Component<any> {
  state = {
    value: 0,
    visible: false,
  };

  render() {
    return (
      <ViewerComponent  fetchPolicy= 'network-only'>
        {({ data, error, loading }) => {
          if (loading || !data || error) {
            return '';
          }
          const teams = data.viewer.teams;
          return (<div className={styles.container} ><div className={styles.change_team_container} >
            <div className={styles.change_team_title} style={{ color: "#69707A" }}>
              请选择要进入的团队
            </div>
            <br />
            <div className={styles.change_team_desc} style={{ color: "#69707A" }}>
              您当前加入了多个团队，请选择您要进入哪一个团队。注意各团队的需求、订单、订阅状态均为独立的
            </div>
            <div className={styles.change_team_desc} style={{ color: "#69707A" }}>
              您也可以<Link to="/create-team" style={{"color":"#DEBB6E"}}>创建团队</Link>
            </div>
            {
              teams && teams.map((team) => {
                let image = plan;
                if (team.plan === "业务型设计部") {
                  image = plan3;
                }
                return <div key={team.id} className={styles.change_team_item_container}>
                  <img alt="照片" src={image} className={styles.change_team_img} />
                  <div style={{ marginLeft: "20px" }}>
                    <span style={{ fontSize: '20px', fontWeight: 900, color: "#69707A" }}>{team.name}</span>
                    <div>
                      {team.plan && new Date(team.plan_end_date) > new Date() ? <div style={{
                        color: "#7CB2B8",
                      }}>
                        {team.plan} 订阅至{moment(team.plan_end_date).format("YYYY年MM月DD日")}
                      </div> : <div style={{
                        color: "#AEB1B5"
                      }}>暂无订阅</div>}
                    </div>
                    <br />
                    <div style={{
                      color: "#AEB1B5"
                    }}>
                      管理员 <img alt="头像" style={{ width: "30px", borderRadius: "50%" }} src={team.owner.headimgurl as any} /> {team.owner.username}
                    </div>
                  </div>
                  <ChangeTeamComponent>
                    {send => {
                      return (
                        <Button
                          className={`linear-gradient-gray normal_button`}
                          style={{ width: "160px", fontSize: "18px", fontWeight: 900, position: "absolute", right: "60px" }}
                          onClick={async () => {
                            await send({
                              variables: {
                                ChangeTeam: { team_id: team.id },
                              },
                            });
                            sessionStorage.setItem("is_choose_team", "1")
                            message.success('切换成功');
                            setTimeout(
                              () => window.location.href = "/",
                              600,
                            );
                          }}
                          type="primary"
                        >
                          进入
                        </Button>
                      );
                    }}
                  </ChangeTeamComponent>
                </div>
              })
            }
          </div></div>)
        }}
      </ViewerComponent>
    )
  }
}

const ChooseTeam = withRouter(ChooseTeamComponent);

export { ChooseTeam } 
import React from 'react';
import { Layout, Menu, Popover, Modal, Button, Tag, Icon, Avatar, Tooltip } from 'antd';
import parse from 'url-parse';
import queryString from 'query-string';

import styles from './header-layout.module.css';
import { withRouter } from 'react-router';
import {
  ViewerComponent,
  OutTeamComponent,
  ChangeTeamComponent,
} from '../apollo';
import { message } from '../components/message';
import { Link } from 'react-router-dom';
import plan from '../pages/plan/plan@2x.png';
import plan3 from '../pages/plan/plan3@2x.png';
import moment from "moment";

const { Header, Content } = Layout;

interface props {
  location: Location;
  history: any;
}

class HeaderLayoutComponent extends React.Component<any> {
  state = {
    collapsed: false,
    visible: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onClick = ({ key }: { key: string }) => {
    const url: any = parse(key);
    const params = queryString.parse(url.query);
    const params2 = queryString.parse(this.props.location.search);
    const params3 = queryString.stringify({
      ...params2,
      ...params,
    });
    this.props.history.push(url.pathname + '?' + params3);
  };

  logout = () => {
    localStorage.removeItem('token');
    this.props.history.push('/login');
  };

  out = () => {
    this.setState({
      visible: true,
    });
  };

  outTeam = async (send: any) => {
    try {
      await send();
      message.success('退出成功');
      // this.props.history.push('/service');
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
      message.error(e);
    } finally {
      this.setState({
        visible: true,
      });
    }
  };

  render() {
    const { visible } = this.state;
    const is_choose_team = sessionStorage.getItem("is_choose_team")
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div style={{ height: '80px', backgroundColor: 'white' }}>
          <Header className={styles.header}>
            <ViewerComponent>
              {({ data, error, loading }) => {
                if (loading || !data || error) {
                  return '';
                }
                const default_key = this.props.location.pathname || '/service';
                const team = data.viewer.team;
                const teams = data.viewer.teams;
                const designerUrl = team && team.designer ? team.designer.headimgurl : ''
                const designerAdviserUrl = team && team.designerAdviser ? team.designerAdviser.headimgurl : ''
                const is_owner = team && team.owner.id === data.viewer.id;
                return (
                  <div className={styles.menu_container}>
                    <Modal
                      visible={visible}
                      className={`modal-cover modal-cover-body`}
                      width={600}
                      onCancel={() =>
                        this.setState({
                          visible: false,
                        })
                      }
                      footer={null}
                      title={null}
                    >
                      <div className={styles.out_container}>
                        <div className={styles.out_title}>
                          您确定要与团队解绑吗？
                        </div>
                        <div className={styles.out_desc}>
                          一旦解绑您将不可再登录该团队系统
                        </div>
                        <ChangeTeamComponent>
                          {send2 => (
                            <OutTeamComponent>
                              {send => {
                                return (
                                  <Button
                                    className={`linear-gradient-gray ${styles.sure} normal_button`}
                                    onClick={this.outTeam.bind(
                                      this,
                                      send,
                                      send2,
                                      teams,
                                    )}
                                    type="primary"
                                  >
                                    确定解绑
                                  </Button>
                                );
                              }}
                            </OutTeamComponent>
                          )}
                        </ChangeTeamComponent>

                        <br />
                        <br />
                        <Button
                          className={`${styles.aggress_sure} normal_button`}
                          onClick={() => this.setState({ visible: false })}
                        >
                          稍后再说
                        </Button>
                      </div>
                    </Modal>

                    <Modal
                      visible={!is_choose_team && teams.length > 1}
                      className={`modal-cover`}
                      width={"100vw"}
                      style={{ position: 'fixed', top: 0, borderRadius: "revert" }}
                      onCancel={() =>
                        this.setState({
                          visible: false,
                        })
                      }
                      footer={null}
                      title={null}
                      closable={false}
                    >
                      <div className={styles.change_team_container} style={{ height: "100vh" }}>
                        <div className={styles.change_team_title}>
                          请选择要进入的团队
                        </div>
                        <br />
                        <div className={styles.change_team_desc}>
                          您当前加入了多个团队，请选择您要进入哪一个团队。注意各团队的需求、订单、订阅状态均为独立的
                        </div>
                        {
                          teams && teams.map((team) => {
                            let image = plan;
                            if (team.plan === "业务型设计部") {
                              image = plan3;
                            }
                            return <div key={team.id} className={styles.change_team_item_container}>
                              <img alt="照片" src={image} className={styles.change_team_img} />
                              <div style={{ marginLeft: "20px" }}>
                                <span style={{ fontSize: '20px', fontWeight: 900 }}>{team.name}</span>
                                <div>
                                  {team.plan ? <div style={{
                                    color: "rgb(142, 180, 187)",
                                  }}>
                                    {team.plan} 订阅至{moment(team.plan_end_date).format("YYYY年MM月DD日")}
                                  </div> : <div style={{
                                    color: "rgba(180, 180, 180, 1)"
                                  }}>暂无订阅</div>}
                                </div>
                                <br />
                                <div style={{
                                  color: "rgba(180, 180, 180, 1)"
                                }}>
                                  管理员 <img alt="头像" style={{ width: "30px", borderRadius: "50%" }} src={team.owner.headimgurl as any} /> {team.owner.username}
                                </div>
                              </div>
                              <ChangeTeamComponent>
                                {send => {
                                  return (
                                    <Button
                                      className={`linear-gradient-gray normal_button`}
                                      style={{ width: "160px", fontSize: "18px", fontWeight: 900, position: "absolute", right: "60px" }}
                                      onClick={async () => {
                                        await send({
                                          variables: {
                                            ChangeTeam: { team_id: team.id },
                                          },
                                        });
                                        sessionStorage.setItem("is_choose_team", "1")
                                        message.success('切换成功');
                                        setTimeout(
                                          () => window.location.reload(),
                                          600,
                                        );
                                      }}
                                      type="primary"
                                    >
                                      进入
                                    </Button>
                                  );
                                }}
                              </ChangeTeamComponent>
                            </div>
                          })
                        }
                      </div>
                    </Modal>



                    <div className={styles.name}>
                      {/* {teams.length > 1 ? ( */}
                        <ChangeTeamComponent>
                          {send => (
                            <Popover
                              placement="bottom"
                              title={null}
                              content={
                                <div>
                                  {teams &&
                                    teams.map(({ id, name }) => (
                                      <div
                                        className="hand"
                                        key={id}
                                        style={{
                                          color:
                                            team && id === team.id ? '#7CB2B8' : '',
                                          width: '200px',
                                          height: '25px',
                                          margin: '12px 0',
                                          fontSize: '18px',
                                          textAlign: 'center',
                                        }}
                                        onClick={async () => {
                                          await send({
                                            variables: {
                                              ChangeTeam: { team_id: id },
                                            },
                                          });
                                          message.success('切换成功');
                                          setTimeout(
                                            () => window.location.reload(),
                                            600,
                                          );
                                        }}
                                      >
                                        {name}
                                      </div>
                                    ))
                                  }
                                  <div
                                    className="hand"
                                    key={'create-team'}
                                    style={{
                                      width: '200px',
                                      height: '25px',
                                      margin: '12px 0',
                                      fontSize: '18px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Link to={'create-team'} style={{ "color": "#595959" }}>+创建新团队</Link>
                                  </div>
                                </div>
                              }
                              trigger="click"
                            >
                              <span style={{ fontSize: '20px' }} className="hand">
                                <Icon
                                  style={{ marginRight: '8px' }}
                                  type="down"
                                />
                                {(team && team.name.length > 6)
                                  ?
                                  <Tooltip title={team && team.name}>
                                    {team.name.slice(0, 5) + '...'}
                                  </Tooltip>
                                  :
                                  <span>
                                    {team && team.name}
                                  </span>
                                }
                              </span>
                            </Popover>
                          )}
                        </ChangeTeamComponent> 
                     {/* )
                        : (
                          <span style={{ fontSize: '20px' }} className="hand">
                            <Icon style={{ marginRight: '8px' }} type="down" />
                            {(team && team.name.length > 6)
                              ?
                              <Tooltip title={team && team.name}>
                                {team.name.slice(0, 5) + '...'}
                              </Tooltip>
                              :
                              <span>
                                {team && team.name}
                              </span>
                            }
                          </span>
                        )}*/}

                      {team && team.plan && (new Date(team.plan_end_date) >= new Date()) && (
                        <Tag style={{ marginLeft: '12px' }} color="#7CB2B8">
                          {team.plan}
                        </Tag>
                      )}
                    </div>
                    {/* 设计师和设计顾问 */}
                    <div style={{ marginLeft: '50px', alignSelf: 'center' }}>
                      <div className={styles.designer}>
                        {
                          designerUrl ? <Avatar className={styles.designer_avatar} src={designerUrl} /> :
                            <div className={styles.designer_avatar_default}>N</div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }}>
                          <span className={styles.designer_title}>当前设计师</span>
                          <span className={styles.designer_name}>{team ? (team.designer) ? team.designer.username : '暂无' : '暂无'}</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: '48px', alignSelf: 'center' }}>
                      <div className={styles.designer}>
                        {
                          designerAdviserUrl ? <Avatar className={styles.designer_avatar} src={designerAdviserUrl} /> :
                            <div className={styles.designer_avatar_default}>N</div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }}>
                          <span className={styles.designer_title}>当前设计顾问</span>
                          <span className={styles.designer_name}>{team ? (team.designerAdviser) ? team.designerAdviser.username : '暂无' : '暂无'}</span>
                        </div>
                      </div>
                    </div>
                    <Menu
                      mode="horizontal"
                      className={styles.menu}
                      defaultSelectedKeys={[default_key]}
                    >
                      <Menu.Item
                        className={
                          default_key === '/choose-demand-type'
                            ? styles.menu_item_active
                            : styles.menu_item
                        }
                        key="/choose-demand-type"
                      >
                        <Link to="/choose-demand-type">
                          <span
                            className={
                              default_key === '/choose-demand-type'
                                ? styles.menu_item_active_left
                                : styles.menu_item_left
                            }
                          />
                          <span
                            className={
                              default_key === '/choose-demand-type'
                                ? styles.menu_item_active_color
                                : styles.menu_item_color
                            }
                          >
                            创建需求
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        className={
                          default_key === '/service'
                            ? styles.menu_item_active
                            : styles.menu_item
                        }
                        key="/service"
                      >
                        <Link to="/service">
                          <span
                            className={
                              default_key === '/service'
                                ? styles.menu_item_active_left
                                : styles.menu_item_left
                            }
                          />
                          <span
                            className={
                              default_key === '/service'
                                ? styles.menu_item_active_color
                                : styles.menu_item_color
                            }
                          >
                            需求管理
                          </span>
                        </Link>
                      </Menu.Item>
                      {/* {is_owner && (
                      <Menu.Item
                        className={
                          default_key === '/team'
                            ? styles.menu_item_active
                            : styles.menu_item
                        }
                        key="/team"
                      >
                        <Link to="/team">
                          <span
                            className={
                              default_key === '/team'
                                ? styles.menu_item_active_left
                                : styles.menu_item_left
                            }
                          />
                          <span
                            className={
                              default_key === '/team'
                                ? styles.menu_item_active_color
                                : styles.menu_item_color
                            }
                          >
                            团队管理
                          </span>
                        </Link>
                      </Menu.Item>
                    )} */}
                      <Menu.Item
                        className={
                          default_key === '/team'
                            ? styles.menu_item_active
                            : styles.menu_item
                        }
                        key="/team"
                      >
                        <Link to="/team">
                          <span
                            className={
                              default_key === '/team'
                                ? styles.menu_item_active_left
                                : styles.menu_item_left
                            }
                          />
                          <span
                            className={
                              default_key === '/team'
                                ? styles.menu_item_active_color
                                : styles.menu_item_color
                            }
                          >
                            团队管理
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        className={
                          default_key === '/account'
                            ? styles.menu_item_active
                            : styles.menu_item
                        }
                        key="/account"
                      >
                        <Link to="/account">
                          <span
                            className={
                              default_key === '/account'
                                ? styles.menu_item_active_left
                                : styles.menu_item_left
                            }
                          />
                          <span
                            className={
                              default_key === '/account'
                                ? styles.menu_item_active_color
                                : styles.menu_item_color
                            }
                          >
                            账户管理
                          </span>
                        </Link>
                      </Menu.Item>
                    </Menu>
                    <Popover
                      content={
                        <div>
                          {!is_owner && (
                            <div onClick={() => this.out()} className={`hand`}>
                              解 绑
                            </div>
                          )}
                          <div onClick={() => this.logout()} className={`hand`}>
                            登 出
                          </div>
                        </div>
                      }
                      placement="bottomRight"
                      title={null}
                      trigger="click"
                    >
                      <div
                        className={`${styles.header_img} header_img_container hand`}
                        style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', marginLeft: '20px' }}
                      >
                        <img
                          className="header_img"
                          src={data.viewer.headimgurl || ''}
                          alt=""
                        />
                        <Icon className={styles.down} type="down" />
                      </div>
                    </Popover>
                  </div>
                );
              }}
            </ViewerComponent>
          </Header >
        </div>
        <Content className={styles.content} style={this.props.contentStyle}>
          {this.props.children}
        </Content>
      </Layout >
    );
  }
}

export const HeaderLayout = withRouter(HeaderLayoutComponent);

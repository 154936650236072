import { message as AntMessage } from 'antd';

export const message = function() {};

message.success = function(value: string) {
  AntMessage.success(value);
};

message.error = function(e: Error) {
  AntMessage.error(e.message.replace('GraphQL error:', ''));
};

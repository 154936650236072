import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Button, Pagination, Row, Col } from 'antd';
import { ViewerTeamComponent, ViewerTeamOrdersComponent } from '../../apollo';
import moment from 'moment';
import { message } from '../../components/message';
import copy from 'copy-to-clipboard';
// import { DeleteModal } from '../../components/delete-modal';
import { Link } from 'react-router-dom';
import empty from '../service/empty@2x.png';
import FailModal from '../../components/fail-modal';

// const one_day = 1000 * 60 * 60 * 24;

// class Item extends React.Component<any> {
//   render() {
//     const {
//       owner: { id, headimgurl, username, mobile },
//       created_date,
//       owner_id,
//       refetch,
//     } = this.props;
//     return (
//       <div className={`${styles.member_container}`}>
//         <div className={`${styles.header_img} header_img_container hand`}>
//           <img className="header_img" src={headimgurl} alt="" />
//         </div>
//         <div className={styles.member_username}>
//           {username}
//           {id === owner_id ? (
//             <Tag style={{ marginLeft: '12px' }} color="#7CB2B8">
//               管理员
//             </Tag>
//           ) : (
//             <DeleteModal user={this.props.owner} refetch={refetch} />
//           )}
//         </div>
//         <div className={styles.member_mobile}>{mobile}</div>
//         <div className={styles.member_created_date}>
//           {moment(created_date).format('YYYY-MM-DD')}加入
//         </div>
//       </div>
//     );
//   }
// }

// function filterSubscriptions(subscriptions: any[]) {
//   let new_subscriptions;
//   subscriptions = subscriptions.filter(subscription => {
//     if (new Date(subscription.end_date) > new Date()) {
//       return true;
//     }
//     // if (
//     //   new Date(subscription.end_date) > new Date() &&
//     //   new Date(subscription.start_date) < new Date()
//     // ) {
//     //   return true;
//     // }
//     return false;
//   });
//   const first_subscriptions = subscriptions
//     .filter(subscription => {
//       if (
//         subscription.name === '基础型设计部' &&
//         subscription.cycle_name !== '后台更新'
//       ) {
//         return true;
//       }
//       return false;
//     })
//     .sort(
//       (item1, item2) =>
//         new Date(item1.end_date).getTime() - new Date(item2.end_date).getTime(),
//     );
//   const second_subscriptions = subscriptions
//     .filter(subscription => {
//       if (
//         subscription.name === '业务型设计部' &&
//         subscription.cycle_name !== '后台更新'
//       ) {
//         return true;
//       }
//       return false;
//     })
//     .sort(
//       (item1, item2) =>
//         new Date(item1.end_date).getTime() - new Date(item2.end_date).getTime(),
//     );
//   const first_subscription = first_subscriptions[0];
//   const second_subscription = second_subscriptions[0];
//   if (first_subscription && second_subscription) {
//     if (
//       new Date(first_subscription.start_date) <
//       new Date(second_subscription.start_date)
//     ) {
//       new_subscriptions = [first_subscription, second_subscription];
//     } else {
//       new_subscriptions = [second_subscription, first_subscription];
//     }
//   } else {
//     new_subscriptions = [first_subscription || second_subscription];
//   }
//   return new_subscriptions;
// }

class Account extends React.Component<any> {
  state = {
    visible: false,
    warn_visible: false,
    page: 1,
    modalVisible: false,
  };

  onCopy = (text: string) => {
    if (copy(text)) {
      message.success('复制成功');
    } else {
      message.error(new Error('复制成功'));
    }
  };

  add = (count: number) => {
    if (count >= 10) {
      return this.setState({
        warn_visible: true,
      });
    }
    this.setState({
      visible: true,
    });
  };

  render() {
    // const { visible, warn_visible } = this.state;
    return (
      <HeaderLayout>
        <div className={styles.title}>账户管理</div>
        <div className={styles.desc}>可以对订阅计划，以及订单进行各类操作</div>
        <ViewerTeamComponent>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            const team = data.viewer.team;
            return (
              <div className={styles.container}>
                <div>
                  {team && new Date(team.plan_end_date) > new Date() ? (
                    <div className={styles.plan}>
                      <p className={styles.plan_title}>订阅计划</p>
                      <p className={styles.plan_empty}>
                        {team.plan}
                        <span style={{ fontSize: '14px' }}>
                          （可同时进行{team.plan_count}个需求）
                        </span>
                        <span
                          style={{
                            fontSize: '14px',
                            color: '#7A818C',
                            paddingLeft: '30px',
                            fontFamily: 'PingFangSC',
                            fontWeight: 400,
                          }}
                        >
                          订阅有效期至{' '}
                          {moment(team.plan_end_date).format('YYYY年MM月DD日')}
                        </span>
                      </p>

                      <div className={`${styles.add_container}`}>
                        <Button
                          className={`${styles.add_plan} normal_button`}
                          onClick={() => this.props.history.push('/plan')}
                        >
                          续订
                        </Button>
                        <br />
                        <br />
                        {team.plan === '基础型设计部' && (
                          <Button
                            className={`${styles.add_plan} normal_button`}
                            onClick={() =>
                              this.props.history.push(
                                `/update-plan?plan=${team.plan}&plan_count=${team.plan_count}`,
                              )
                            }
                          >
                            升级
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.plan}>
                      <p className={styles.plan_title}>订阅计划</p>
                      <p className={styles.plan_empty}>暂无</p>
                      <Button
                        className={`linear-gradient-gray ${styles.go_subscription}`}
                        onClick={() => this.props.history.push('/plan')}
                        type="primary"
                      >
                        立即订阅
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        </ViewerTeamComponent>

        <ViewerTeamOrdersComponent
          variables={{
            take: 10,
            skip: (this.state.page - 1) * 10,
            
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, loading }) => {
            if (!data || !data.viewer || !data.viewer.team) {
              return '';
            }
            return (
              <div className={styles.order_container}>
                {data.viewer.team.order_count === 0 ? (
                  <div style={{ paddingTop: '20px' }}>
                    <img src={empty} alt="" className={styles.empty_img} />
                    <div className={styles.empty_text}>暂无订单</div>
                  </div>
                ) : (
                  <div>
                    {data.viewer.team.orders.map(order => {
                      return (
                        <div key={order.id}>
                          <div
                            style={{
                              marginBottom: '8px',
                              marginLeft: '-12px',
                              position: 'relative',
                            }}
                          >
                            <span style={{ paddingRight: '20px' }}>
                              下单时间:{' '}
                              <span style={{ color: '#AEB1B5' }}>
                                {moment(order.created_date).format(
                                  'YYYY-MM-DD HH:mm',
                                )}
                              </span>
                            </span>
                            <span style={{ paddingRight: '20px' }}>
                              订单号:{' '}
                              <span style={{ color: '#AEB1B5' }}>
                                {order.out_trade_no}
                              </span>
                            </span>
                            <span>
                              订单状态:{' '}
                              <span
                                style={{
                                  color:
                                    order.status === '待付款' ||
                                      order.status === '审核未通过'
                                      ? '#DB5B6B'
                                      : '',
                                }}
                              >
                                {order.status}
                                {order.reason &&
                                  order.status === '审核未通过' &&
                                  `-${order.reason}`}
                              </span>
                            </span>
                            <div
                              style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                              }}
                            >
                              实际支付: ¥{order.price}
                            </div>
                          </div>
                          <Row
                            type="flex"
                            justify="start"
                            gutter={24}
                            style={{
                              backgroundColor: '#EFEFEF',
                              padding: '6px 0',
                            }}
                          >
                            <Col span={4} style={{ position: 'relative' }}>
                              服务类型
                            </Col>
                            <Col span={4} style={{ position: 'relative' }}>
                              明细
                            </Col>
                            <Col span={4} style={{ position: 'relative' }}>
                              金额
                            </Col>
                            <Col span={4} style={{ position: 'relative' }}>
                              创建者
                            </Col>
                            <Col span={4} style={{ position: 'relative' }}>
                              付款方式
                            </Col>
                            <Col span={4} style={{ position: 'relative' }}>
                              操作
                            </Col>
                          </Row>
                          <Row
                            type="flex"
                            justify="start"
                            gutter={24}
                            style={{
                              padding: '12px 0',
                              marginBottom: '30px',
                            }}
                          >
                            <Col span={12} style={{ position: 'relative' }}>
                              <Row type="flex" justify="start" gutter={24}>
                                <Col span={8} style={{ position: 'relative' }}>
                                  {order.type}
                                </Col>
                                <Col span={8} style={{ position: 'relative' }}>
                                  {order.detail}
                                </Col>
                                <Col span={8} style={{ position: 'relative' }}>
                                  ¥ {order.old_price}
                                </Col>
                              </Row>
                              {order.items.map(order_item => (
                                <Row
                                  key={order_item.id}
                                  type="flex"
                                  justify="start"
                                  gutter={24}
                                  style={{ marginTop: '20px' }}
                                >
                                  <Col
                                    span={8}
                                    style={{ position: 'relative' }}
                                  >
                                    {order_item.type}
                                  </Col>
                                  <Col
                                    span={8}
                                    style={{ position: 'relative' }}
                                  >
                                    {order_item.detail}
                                  </Col>
                                  <Col
                                    span={8}
                                    style={{ position: 'relative' }}
                                  >
                                    {order_item.order_type === 0 ? '-' : '+'} ¥{' '}
                                    {order_item.price}
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                            <Col
                              span={4}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {order.owner && order.owner.username}
                            </Col>
                            <Col
                              span={4}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {order.pay_type}
                            </Col>
                            <Col
                              span={4}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {(order.status === '支付成功' && data.viewer.team) ?
                                !order.open_invoice ?
                                  (data.viewer.team.owner.id === data.viewer.id) ?
                                    (<Link style={{ color: '#DEBA6C' }} to={`invoicing?order_id=${order.id}`}>
                                      申请发票
                                    </Link>)
                                    :
                                    (
                                    <span onClick={() => { this.setState({ modalVisible: true }) }} style={{ color: '#DEBA6C', cursor: 'pointer' }}>申请发票</span>
                                    )
                                  :  (
                                    <span style={{ color: '#69707A' }}>发票已申请</span>
                                    )
                                : ''}
                              {order.status === '待付款' && (
                                <Link to={`pay-order?order_id=${order.id}`}>
                                  立即付款
                                </Link>
                              )}
                              {order.status === '审核未通过' && (
                                <Link to={`pay-order?order_id=${order.id}`}>
                                  重新付款
                                </Link>
                              )}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Pagination
                      className={styles.page}
                      defaultCurrent={this.state.page}
                      total={data.viewer.team.order_count}
                      onChange={(page: any) => this.setState({ page })}
                    />
                    <FailModal
                      visible={this.state.modalVisible}
                      desc={
                        <>
                          为保证客户财务安全，仅限账户管理员可以提交发票申请
                        </>
                      }
                      okText="好的"
                      onOkClick={() => { this.setState({ modalVisible: false }) }}
                    />
                  </div>
                )}
              </div>
            );
          }}

        </ViewerTeamOrdersComponent>
      </HeaderLayout>
    );
  }
}

export default Account;

import React from 'react';
import { Icon, Modal, Form, Upload } from 'antd';

import { CreateAnnexComponent } from '../../apollo';
import { message } from '../../components/message';
// import styles from './index.module.css';
import { up_load_life_photo_options } from '../../lib/upload.client';
// import bluebird from 'bluebird';
// import { v4 as uuid } from 'uuid';
// import { getFileType } from '../photo';

const { Dragger } = Upload;

class UploadFileModal extends React.Component<any> {
  state = {
    visible: false,
    uri: [],
    file_old_name: ""
  };

  handleSubmit = async (send: any, e: any) => {
    e.preventDefault();
    if (this.state.uri.length === 0) {
      return message.error(new Error('请上传附件'));
    }
    try {
      message.success('上传成功');
      const { callback } = this.props;
      callback(this.state.uri, this.state.file_old_name);
      this.close();
    } catch (e) {
      message.error(e);
    }
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.props;
    return (
      <CreateAnnexComponent>
        {(send, { loading }) => {
          return (
            <Modal
              title="上传文件"
              centered
              visible={visible}
              onCancel={this.props.onCancel}
              onOk={this.handleSubmit.bind(this, send)}
              confirmLoading={loading}
              okText="上传"
              cancelText="取消"
            >
              {visible && <Dragger
                // disabled={!!uri}
                multiple={false}
                {...up_load_life_photo_options((uri, file) => {
                  this.setState({
                    file_old_name: file.name,
                    uri,
                  })
                })}
              // accept=".pdf,.jpg,.word,.rar,.zip"
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或者拖动到此处上传</p>
                <p className="ant-upload-hint">
                  请上传一个文件
                </p>
              </Dragger>}
            </Modal>
          );
        }}
      </CreateAnnexComponent>)
  }
}

export const UploadFileModalForm: any = Form.create({ name: 'upload-file' })(
  UploadFileModal,
);

import React from 'react';
import { Icon, Popover, Popconfirm } from 'antd';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import Annotation from "react-image-annotation";
import {
  PointSelector,
} from 'react-image-annotation/lib/selectors'
import { FileCard } from '../demand-chat/index';
import {
  AnnexGroupListComponent,
  CreateAnnotationComponent,
  AnnexAnnotationsComponent,
  DeleteAnnotationComponent,
} from '../../apollo';
import queryString from 'query-string';
import moment from "moment";
import { message } from '../../components/message';
import Point from './Point';
import Editor from './Editor';
import Overlay from './Overlay';
import Content from './Content';
import { getFileTypeByPath } from '../../lib';
import { Empty } from './Empty';
// import t from "./t.jpeg"

export const file_types = ["jpg", "png", "jpeg"]

export function getFileType(filePath: string) {
  var startIndex = filePath.lastIndexOf(".");
  var endIndex = filePath.lastIndexOf("?");
  if (startIndex !== -1)
    return filePath.substring(startIndex + 1, endIndex !== -1 ? endIndex : filePath.length).toLowerCase();
  else return "";
}


function getFirstPng(items: any[]) {
  const result = items.find((item) => file_types.includes(getFileType(item.path)))
  return result
}

class Photo extends React.Component<any> {
  componentDidMount() {
    // const instance = new fabric.Canvas('c')
  }

  state = {
    annotations: [],
    annotation: {},
    uri: "",
    path: "",
    annex_id: ""
  }

  onChange = (annotation: any) => {
    this.setState({ annotation })
  }

  onSubmit = async (annotation: any, send: any, refetch: any, annexs: any) => {
    const { geometry, data } = annotation

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        // @ts-ignore
        geometry,
        data: {
          ...data,
          id: Math.random(),
          // date: new Date(),
        }
      })
    })
    try {
      const first = getFirstPng(annexs)
      await send({
        variables: {
          CreateAnnotation: {
            id: annotation.id,
            annex_id: this.state.annex_id || first.id,
            text: data.text,
            geometry: JSON.stringify(geometry),
            data: JSON.stringify(data),
          },
        },
      });
      refetch();
    } catch (e) {
      message.error(e);
    }

  }

  render() {
    const search: any = queryString.parse(window.location.search);
    // const session = JSON.parse(String(localStorage.getItem("session")))
    return (
      <AnnexGroupListComponent variables={{ id: search.id, group_id: search.group_id }}>
        {({ data, error, loading, refetch }) => {
          if (error || loading || !data) {
            return '';
          }
          const first = getFirstPng(data.annexGroupList[0].items)
          return (
            <AnnexAnnotationsComponent variables={{ annex_id: this.state.annex_id || first.id }}>
              {({ data: annotationData, error, loading, refetch }) => {
                if (error || loading || !annotationData) {
                  return '';
                }
                // const result = this.state.annotations;
                const result = annotationData.annexAnnotations;
                const annotations = result.map((item, index) => {
                  return {
                    index: result.length - index,
                    ...item,
                    geometry: JSON.parse(item.geometry),
                    data: {
                      ...JSON.parse(item.data),
                      id: item.id
                    },
                  }
                })
                const is_image = ["jpg", "jpeg", "png"].includes(getFileTypeByPath(this.state.path || first.path))
                return (<HeaderLayout>
                  <div className="go_back" onClick={() => this.props.history.goBack()}>
                    <Icon type="left-circle" />&nbsp;返回上层
                  </div>
                  <div className={styles.list_photo}>
                    {data.annexGroupList[0].items.map((item) => <FileCard file_old_name={item.file_old_name} key={item.id} uri={item.uri} onClick={() => this.setState({
                      annex_id: item.id,
                      path: item.path,
                      uri: item.uri
                    })} />)}
                  </div>
                  <div className={styles.leftPhoto}>
                    <CreateAnnotationComponent>
                      {(send) => {
                        if (error || loading || !data) {
                          return '';
                        }
                        return is_image ? <Annotation
                          src={this.state.uri || first.uri}
                          alt='无法显示'

                          annotations={annotations}

                          type={PointSelector.TYPE}
                          value={this.state.annotation}
                          onChange={this.onChange}
                          renderSelector={Point}
                          renderContent={({ key, annotation }: any) => (
                            <Content
                              key={key}
                              annotation={annotation}
                            />
                          )}
                          // renderHighlight={Point}
                          renderHighlight={({ key, annotation, active }: any) => {
                            return (
                              <Point
                                key={key}
                                annotation={annotation}
                                active={active}
                              />
                            )
                          }}
                          renderEditor={Editor}
                          renderOverlay={({ type, annotation }: any) => {
                            return <Overlay>
                              点击进行批注
                            </Overlay>
                          }}
                          onSubmit={(annotation: any) => this.onSubmit(annotation, send, refetch, data.annexGroupList[0].items)}
                        /> : <Empty uri={this.state.uri || first.uri} />
                      }}
                    </CreateAnnotationComponent>
                    <div className={styles.list_container}>
                      {annotations.map((annotation: any, index) => {
                        // const geometry = JSON.parse(annotation.geometry)
                        // const data = JSON.parse(annotation.data)
                        return <div key={annotation.id} className={styles.list_card}>
                          <Popover
                            content={
                              <div>
                                <span className="hand" onClick={() => {
                                  this.setState({
                                    annotation: {
                                      ...annotation,
                                      selection: {
                                        mode: "EDITING",
                                        showEditor: true
                                      }
                                    }
                                  })
                                }}>编辑</span>
                                <br />
                                <DeleteAnnotationComponent>
                                  {send => {
                                    return (
                                      <Popconfirm title="确定删除？" okText="确定" cancelText="取消" onConfirm={async () => {
                                        try {
                                          await send({
                                            variables: { id: annotation.id },
                                          });
                                          message.success('删除成功');
                                          refetch();
                                        } catch (e) {
                                          message.error(e);
                                        }
                                      }}>
                                        <span className="hand">删除</span>
                                      </Popconfirm>
                                    );
                                  }}
                                </DeleteAnnotationComponent>
                              </div>
                            }
                            placement="bottomRight"
                            title={null}
                            // trigger="hover"
                            trigger="click"
                          >
                            <div className={`${styles.right_menu} hand`}>...</div>
                          </Popover>
                          <div className={styles.user_name}>
                            <img
                              className="header_img_card"
                              src={annotation.owner.headimgurl || ''}
                              alt=""
                            />
                            <div>{annotation.owner.username}
                              <br />{moment(annotation.created_date).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                          </div>
                          <div className={styles.text}>
                            <span style={{ color: "rgb(116, 169, 175)" }}>{annotations.length - index}.</span> {annotation.text}
                          </div>
                        </div>
                      })}
                    </div>
                  </div>

                </HeaderLayout >)
              }}
            </AnnexAnnotationsComponent>)
        }}
      </AnnexGroupListComponent>
    );
  }
}

export default Photo;

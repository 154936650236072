import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Radio, Row, Col, Checkbox } from 'antd';
import ali from '../recharge/ali@2x.png';
import wechat from '../recharge/wechat@2x.png';
import dian from './dian.png';
// import { PackagesComponent, CreateOrderComponent } from '../../apollo';
import { PackagesComponent } from '../../apollo';
import { message } from '../../components/message';
import PayResultModal from '../../components/pay-result-modal';
import WeChatPayModalComponent from '../../components/we-chat-pay-modal';
import { UserAgreemnet } from '../../components/user-agreement';

interface CardProps {
  title: string;
  price: number;
  get_price: number;
  list: string[];
}

class Card extends React.Component<CardProps> {
  render() {
    const { title, price, get_price, list } = this.props;
    return (
      <div className={styles.card}>
        <div className={styles.card_top}>{title}</div>
        <p className={styles.price}>
          充值{' '}
          <span style={{ color: '#7CB2B8', fontWeight: 600 }}> ¥ {price}</span>
        </p>
        <p className={styles.get_price}>
          得{' '}
          <span style={{ color: '#7CB2B8' }}>
            {' '}
            ¥{' '}
            <span style={{ fontSize: '50px', fontWeight: 600 }}>
              {get_price}
            </span>
          </span>
        </p>
        <div className={styles.line} />
        <div className={styles.list_container}>
          {list.map(item => {
            return (
              <div className={styles.list_item} key={item}>
                <img className={styles.dian} alt="" src={dian} />
                {item}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class Package extends React.Component<any> {
  state = {
    value: '支付宝',
    choose: '',
    aggress: false,
    result_modal: false,
    we_chat_modal: false,
    uri: '',
    order_id: '',
    price: 500,
    visible: false
  };
  handleSubmit = async (send: any, list: any[]) => {
    const { choose, value, aggress } = this.state;
    if (!choose) {
      return message.error(new Error('请选择套餐'));
    }
    if (!value) {
      return message.error(new Error('请选择支付方式'));
    }
    if (!aggress) {
      return message.error(new Error('请同意协议后充值'));
    }
    try {
      const { data } = await send({
        variables: {
          CreateOrder: { package_id: choose, pay_type: value },
        },
      });
      const uri = data.createOrder.uri;
      if (value === '支付宝') {
        this.setState({ result_modal: true, uri });
        window.open(uri);
      } else {
        const old_package = list.find(({ id }) => id === choose);
        this.setState({
          we_chat_modal: true,
          uri,
          price: old_package.price,
          order_id: data.createOrder.id,
        });
      }
    } catch (e) {
      message.error(e);
    }
  };
  render() {
    const { choose, aggress } = this.state;
    return (
      <HeaderLayout>
        <div className={styles.container}>
          <PayResultModal
            visible={this.state.result_modal}
            open_uri={this.state.uri}
            onCancel={() => this.setState({ result_modal: false })}
          />
          <WeChatPayModalComponent
            visible={this.state.we_chat_modal}
            uri={this.state.uri}
            id={this.state.order_id}
            price={this.state.price}
            onCancel={() => this.setState({ we_chat_modal: false })}
          />
          <div style={{ position: 'relative' }}>
            <div className={styles.title}>选择适合您的充值套餐：</div>
            <div className={styles.content}>
              我们提供多种充值套餐，可以满足您各类业务需求场景，充值前敬请您咨询设计顾问
            </div>
            <div
              onClick={() => this.props.history.push('/recharge')}
              className={`${styles.other_price} hand`}
            >
              充值其他金额
            </div>
          </div>
          <PackagesComponent>
            {({ error, data, loading }) => {
              if (error || loading || !data) {
                return '';
              }
              return (
                <>
                  <Radio.Group
                    onChange={e =>
                      this.setState({
                        choose: e.target.value,
                      })
                    }
                    value={choose}
                    style={{ width: '100%', marginTop: '30px' }}
                  >
                    <Row type="flex" justify="start" gutter={2}>
                      {data.packages.list.map((item, index) => (
                        <Col
                          span={6}
                          style={{ position: 'relative' }}
                          key={item.id}
                        >
                          <Card
                            title={item.name}
                            price={item.price}
                            get_price={item.balance}
                            list={item.introduction.split(',')}
                          />
                          <Radio className={styles.radio} value={item.id} />
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                  <div className={styles.label}>选择付款方式：</div>
                  <Radio.Group
                    onChange={e =>
                      this.setState({
                        value: e.target.value,
                      })
                    }
                    value={this.state.value}
                    style={{ display: 'flex' }}
                  >
                    <div className={styles.pay_container}>
                      <Radio value={'支付宝'}>
                        <img className={styles.pay_img} alt="" src={ali} />
                        支付宝
                      </Radio>
                    </div>
                    <div className={styles.pay_container}>
                      <Radio value={'微信'}>
                        <img className={styles.pay_img} alt="" src={wechat} />
                        微信
                      </Radio>
                    </div>
                  </Radio.Group>
                  {/* <CreateOrderComponent>
                    {send => {
                      return (
                        <Button
                          className={`linear-gradient-gray ${styles.next}`}
                          onClick={this.handleSubmit.bind(
                            this,
                            send,
                            data.packages.list,
                          )}
                          type="primary"
                        >
                          确认充值
                        </Button>
                      );
                    }}
                  </CreateOrderComponent> */}
                </>
              );
            }}
          </PackagesComponent>
          <div style={{ textAlign: 'center' }}>
            <Checkbox
              onChange={() => this.setState({ aggress: !aggress })}
              checked={aggress}
              className={styles.aggress}
            >
              <span className="read_me">我已阅读并且同意</span>
            </Checkbox>
            <span
              onClick={() => this.setState({ visible: true })}
              style={{
                color: 'rgba(222, 186, 108, 1)',
                marginLeft: '-20px',
              }}
              className="hand"
            >
              《平台使用协议》《设计服务协议》
            </span>
            <UserAgreemnet visible={this.state.visible}
              onUserAgreementCancel={() => { this.setState({ visible: false, aggress: false }) }}
              onUserAgreementOk={() => { this.setState({ visible: false, aggress: true }) }}
            />
          </div>
        </div>
      </HeaderLayout>
    );
  }
}

export default Package;

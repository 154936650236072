import React from 'react';
import { Button } from 'antd';
import queryString from 'query-string';

import Card from '../../components/card';
import styles from './index.module.css';
import {
  GetTeamByCodeComponent,
  ViewerComponent,
  AddTeamComponent,
} from '../../apollo';
import warn from './warn@2x.png';
import { message } from '../../components/message';

export default class AddTeam extends React.Component<any> {
  // componentDidMount() {
  //   const code: any = queryString.parse(window.location.search).code;
  //   if (code) {
  //     localStorage.setItem(
  //       'team_code',
  //       JSON.stringify({
  //         created_date: new Date(),
  //         code,
  //       }),
  //     );
  //   }
  // }

  state = {};

  render() {
    const code: any = queryString.parse(window.location.search).code;
    return (
      <Card>
        <ViewerComponent>
          {({ data, error, loading }) => {
            if (loading || !data) {
              return '';
            }
            const viewer = data.viewer;
            return (
              <GetTeamByCodeComponent variables={{ code }}>
                {({ error, loading, data }) => {
                  if (loading) {
                    return '';
                  }
                  if (error || !data) {
                    return (
                      <div className={styles.warn_container}>
                        <img className={styles.warn_img} src={warn} alt="" />
                        <p className={styles.warn_title}>邀请链接已失效</p>
                        <p className={styles.warn_desc}>
                          请联系团队管理员重新生成邀请链接
                        </p>
                      </div>
                    );
                  }
                  return (
                    <div className={styles.container}>
                      <div>
                        <p>
                          <span className={styles.hello}>欢迎使用</span>
                          <span className={`${styles.hello} ${styles.golden}`}>
                            Nef.Design
                          </span>
                        </p>
                        <div className={styles.paragraph}>您即将加入</div>
                        <div className={styles.big_paragraph}>
                          {data.getTeamByCode.name}
                        </div>
                      </div>

                      <AddTeamComponent>
                        {(send, { loading }) => {
                          return (
                            <Button
                              className={`linear-gradient-gray ${styles.sure} normal_button`}
                              onClick={async () => {
                                if (error) {
                                  return this.props.history.push(
                                    `/login?team_id=${data.getTeamByCode.id}&code=${code}`,
                                  );
                                }
                                if (viewer.is_mobile) {
                                  try {
                                    await send({
                                      variables: {
                                        AddTeam: { team_code: code },
                                      },
                                    });
                                  } catch (e) {
                                    return message.error(e);
                                  }
                                  return this.props.history.push('/service');
                                }
                                this.props.history.push(`add-team-form?code=${code}`);
                              }}
                              type="primary"
                              loading={loading}
                            >
                              确认加入
                            </Button>
                          );
                        }}
                      </AddTeamComponent>
                    </div>
                  );
                }}
              </GetTeamByCodeComponent>
            );
          }}
        </ViewerComponent>
      </Card>
    );
  }
}

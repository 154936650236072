import React from 'react';
import { Button, Form, Input, Checkbox } from 'antd';

import {
  CreateMobileCodeComponent,
  CreateTeamComponent,
  ViewerComponent,
} from '../../apollo';
import { message } from '../../components/message';
import { UserAgreemnet } from '../../components/user-agreement';
import Card from '../../components/card';
import styles from './index.module.css';

class CreateTeamModal extends React.Component<any> {
  timer: any;

  state = {
    visible: false,
    choose: false,
    code_button_content: '发送验证码',
    code_button_disabled: false,
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    if (!this.state.choose) {
      return message.error(new Error('请同意用户协议后继续'));
    }
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              CreateTeam: values,
            },
          });
          message.success('创建成功');
          this.props.history.push('/choose-demand-type');
          // this.props.history.push('/account');
          // const { form } = this.props;
          // const { resetFields } = form;
          // resetFields();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  sendCode = async (send: any) => {
    try {
      const { mobile } = await this.props.form.validateFields(['mobile']);
      if (this.state.code_button_disabled) {
        return;
      }
      // const country_number = getCountryNumber(mobile);
      // const new_mobile = mobile.replace(country_number, '');
      try {
        await send({
          variables: {
            mobile,
            country_number: '+86',
          },
        });
        let count = 60;
        this.timer = setInterval(() => {
          if (count <= 1) {
            clearInterval(this.timer);
            this.setState({
              code_button_content: '发送验证码',
              code_button_disabled: false,
            });
          } else {
            count--;
            this.setState({
              code_button_disabled: true,
              code_button_content: `剩余${count}s`,
            });
          }
        }, 1000);
        message.success('发送成功');
      } catch (e) {
        message.error(e);
      }
    } catch (e) {
      // message.error(new Error("请填写相关信息"));
    }
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {choose, code_button_content } = this.state;
    return (
      <Card>
        {/* <Modal
          className={`modal-cover modal-cover-body`}
          visible={visible}
          footer={null}
          closable={false}
          centered
          width={800}
          confirmLoading={false}
          onCancel={() => this.setState({ visible: false })}
        >
          <div className={styles.modal_title}>《Nef.Design用户协议》</div>
          <div className={styles.modal_body}>
            <span
              style={{ color: '#69707A', fontSize: '20px', fontWeight: 900 }}
            >
              特别提示:
            </span>
            <br />
            <br />
            您在注册流程中点击同意本协议之前，应当认真、充分、完整地阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。
            <br />
            <br />
            在阅读本协议的过程中，如果您不同意本协议部分或者全部条款的，请您立即终止注册程序。当您按照注册页面提示填写信息且完成全部注册程序后，即表示您已充分阅读、理解并完全接受本协议的全部内容。
            <br />
            <br />
            欢迎您来到Nef.Design
            <br />
            <br />
            请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，应向Nef.Design进行咨询。当按照注册页面提示填写信息且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与Nef.Design达成本协议。本协议内容包括协议正文及所有Nef.Design已经或将来发布的各类规则。所有规则及协议与本协议具有同等法律效力。您承诺接受并遵守上述规则及协议的约定，届时您不应以未阅读本协议的内容主张本协议无效，或要求撤销本协议。
            <br />
            <br />
            Nef.Design有权根据需要不时地制定、修改本协议或各类规则，如本协议有任何变更，Nef.Design将在网站上刊载公告通知，无需另行单独通知您。经修订的条款一经在Nef.Design平台公布后，立即自动生效。如您不同意相关变更，请立即停止使用Nef.Design平台（网址为nef.design，包括但不限于网站等）所有服务。如您继续使用Nef.Design平台提供的服务，则视为您已接受经修订的协议和规则。
            <br />
            <br />
            1、协议范围 1.1
            本协议由您与Nef.Design（即天津奈夫科技合伙团队，下称Nef.Design）共同缔结，具有合同效力，协议签约的另一方称为用户或您。
            <br />
            <br />
            1.2
            除Nef.Design另行明确声明外，Nef.Design服务包含任何Nef.Design提供的基于互联网以及移动端互联网的相关服务，且均受本协议约束。同时任何使“服务”范围扩大或功能增强的新内容亦受本协议
          </div>
          <div className={styles.button_group}>
            <Button
              className={`${styles.aggress_sure}`}
              onClick={() => this.setState({ visible: false })}
              style={{ marginRight: '30px', width: '110px' }}
            >
              取消
            </Button>
            <Button
              className={`linear-gradient-gray ${styles.aggress_sure}`}
              onClick={() => this.setState({ choose: true, visible: false })}
              type="primary"
            >
              已阅读同意
            </Button>
          </div>
        </Modal> */}
        <ViewerComponent>
          {({ data, error, loading }) => {
            if (loading || !data || error) {
              return '';
            }
            return (
              <div className={styles.container}>
                <div>
                  <div className={styles.hello}>
                    Hi,
                    <img
                      className="header_normal_img"
                      src={data.viewer.headimgurl || ''}
                      style={{ marginRight: '8px' }}
                      alt=""
                    />
                    {data.viewer.username}
                  </div>

                  <p>
                    <span className={styles.hello}>欢迎使用</span>
                    <span className={`${styles.hello} ${styles.golden}`}>
                      Nef.Design
                    </span>
                  </p>
                  <p className={styles.paragraph}>
                    如您和您的团队首次使用Nef.Design平台,
                    请填写您的团队名/产品名/品牌名并完成手机号验证
                  </p>
                  <p className={styles.paragraph}>
                    如已有团队，请联系创建人向您发送邀请链接，邀请您加入
                  </p>
                </div>
                <Form className="login-form">
                  <Form.Item label="您的团队名称">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: '请输入团队名称!' }],
                    })(
                      <Input
                        className="king_normal_input"
                        placeholder="例如团队名/产品名/品牌名"
                      />,
                    )}
                  </Form.Item>
                  {!data.viewer.is_mobile && (
                    <div className={`${styles.send_code_container}`}>
                      <Form.Item label="您的手机号">
                        {getFieldDecorator('mobile', {
                          rules: [{ required: true, message: '请输入手机号!' }],
                        })(
                          <Input
                            className="king_normal_input"
                            placeholder="请输入您的手机号"
                          />,
                        )}
                      </Form.Item>
                      <CreateMobileCodeComponent>
                        {send => {
                          return (
                            <div
                              className={`${styles.send_code} hand`}
                              onClick={this.sendCode.bind(this, send)}
                            >
                              {code_button_content}
                            </div>
                          );
                        }}
                      </CreateMobileCodeComponent>
                    </div>
                  )}
                  {!data.viewer.is_mobile && (
                    <Form.Item label="您的验证码">
                      {getFieldDecorator('code', {
                        rules: [{ required: true, message: '请输入验证码!' }],
                      })(
                        <Input
                          className="king_normal_input"
                          placeholder="请输入收到的验证码"
                        />,
                      )}
                    </Form.Item>
                  )}
                  <div style={{ textAlign: 'center' }}>
                    <Checkbox
                      onChange={() => this.setState({ choose: !choose })}
                      checked={choose}
                      className={styles.aggress}
                    >
                      <span className="read_me">我已阅读并同意云设计部</span>
                    </Checkbox>
                    <span
                      onClick={() => this.setState({ visible: true })}
                      style={{
                        color: 'rgba(222, 186, 108, 1)',
                        marginLeft: '-20px',
                      }}
                      className="hand"
                    >
                      《平台使用协议》《设计服务协议》
                    </span>
                    <UserAgreemnet visible={this.state.visible}
                      onUserAgreementCancel={() => { this.setState({ visible: false }) }}
                      onUserAgreementOk={() => { this.setState({ visible: false, choose: true }) }}
                    />
                  </div>
                  <Form.Item>
                    <CreateTeamComponent>
                      {(send, { loading }) => {
                        return (
                          <Button
                            className={`linear-gradient-gray ${styles.sure}`}
                            loading={loading}
                            onClick={this.handleSubmit.bind(this, send)}
                            type="primary"
                          >
                            继续
                          </Button>
                        );
                      }}
                    </CreateTeamComponent>
                  </Form.Item>
                </Form>
              </div>
            );
          }}
        </ViewerComponent>
      </Card>
    );
  }
}

export const CreateTeamModalForm: any = Form.create({ name: 'create_team' })(
  CreateTeamModal,
);

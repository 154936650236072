import React from 'react';
import { Modal, Button } from 'antd';

import styles from './index.module.css';
import { DeleteMemberComponent } from '../../apollo';
import { message } from '../message';

export class DeleteModal extends React.Component<any> {
  state = {
    visible: false,
  };

  deleteMember = async (send: any) => {
    try {
      await send({
        variables: {
          user_id: this.props.user.id,
        },
      });
      this.props.refetch();
      this.setState({
        visible: false,
      });
      message.success('移除成功');
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const { visible } = this.state;
    const { headimgurl, username } = this.props.user;
    return (
      <>
        <span
          className={`hand ${styles.delete_button}`}
          onClick={() => this.setState({ visible: true })}
        >
          移除
        </span>
        <Modal
          visible={visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.modal_title}>移除成员确认</div>
            <div className={styles.modal_desc}>
              请确认将移除以下成员，一旦确认移除，该成员将
              不再有权限进入团队账户
            </div>
            <div className={styles.header_img_container}>
              <div className={`${styles.header_img}`}>
                <img className="header_img" src={headimgurl} alt="" />
              </div>
              <span className={styles.username}>{username}</span>
            </div>
            <DeleteMemberComponent>
              {send => {
                return (
                  <Button
                    className={`linear-gradient-gray ${styles.sure} normal_button`}
                    onClick={this.deleteMember.bind(this, send)}
                    type="primary"
                  >
                    确认移除
                  </Button>
                );
              }}
            </DeleteMemberComponent>

            <br />
            <br />
            <Button
              className={`${styles.aggress_sure} normal_button`}
              onClick={() => this.setState({ visible: false })}
            >
              取消
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

import React from 'react';

import styles from './index.module.css';

class Card extends React.Component {
  render() {
    return (
      <div className={styles.bg}>
        <div className={styles.container}>
          <div className={styles.top} />
          <div className={styles.children}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Card;

// const old_projects = [
//   {
//     uri: 'Banner.png',
//     title: 'Banner',
//     type: '基础型设计部',
//     item_type: '线上物料',
//     content: '设计banner 图片，用于网站轮播、电商广告等 ',
//     description: '标准交付时间：项目开始后，2个工作日交付方案',
//     cost_day: 2,
//   },
//   {
//     uri: '微信移动端单屏海报.png',
//     title: '微信移动端单屏海报',
//     type: '基础型设计部',
//     item_type: '线上物料',
//     content: '设计移动端单屏海报，可用于微信朋友圈等',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '微信移动端长图文.png',
//     title: '微信移动端长图文',
//     item_type: '线上物料',
//     type: '基础型设计部',
//     content: '设计用于手机微信生态内展示的长图文',
//     description:
//       '标准交付时间：项目开始后，1个工作日交付主视觉方案；主视觉风格确认后，每1个工作日1屏设计。',
//     cost_day: 2,
//   },
//   {
//     uri: '电子版优惠券/贺卡.png',
//     title: '电子版优惠券/贺卡',
//     type: '基础型设计部',
//     item_type: '线上物料',
//     content: '设计移动端电子版优惠券，贺卡等卡片类 ',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: 'PPT.png',
//     title: 'PPT',
//     type: '基础型设计部',
//     item_type: '线上物料',
//     content: '设计或者优化幻灯片',
//     description:
//       '标准交付时间：项目开始后，1个工作日交付主视觉方案；主视觉风格确认后，每1个工作日交付 10 个版式。',
//     cost_day: 2,
//   },
//   {
//     uri: '海报.png',
//     title: '海报',
//     type: '基础型设计部',
//     item_type: '线下物料',
//     content: '海报 ',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '易拉宝.png',
//     title: '易拉宝',
//     type: '基础型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的线下易拉宝',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '优惠券.png',
//     title: '优惠券',
//     type: '基础型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的优惠券',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '门票/贺卡/明信片.png',
//     title: '门票/贺卡/明信片',
//     type: '基础型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的门票/贺卡/明信片等卡片类',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '传单（单面）.png',
//     title: '传单（单面）',
//     type: '基础型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的单面传单',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '传单（双面）.png',
//     title: '传单（双面）',
//     type: '基础型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的双面传单',
//     description: '标准交付时间：项目开始后，3个工作日交付完整方案',
//     cost_day: 3,
//   },
//   {
//     uri: '多页手册/折页.png',
//     title: '多页手册/折页',
//     type: '基础型设计部',
//     content: '设计多页手册，如多页 PDF 文档、多页手册等',
//     item_type: '线下物料',
//     description:
//       '标准交付时间：项目开始后，2个工作日交付主视觉方案；主视觉风格确认后，每1个工作日交付 10页。',
//     cost_day: 3,
//   },
//   {
//     uri: '视觉类设计（独立项目）.png',
//     title: '视觉类设计（独立项目）',
//     type: '基础型设计部',
//     item_type: '独立项目',
//     content:
//       '例如VIS视觉识别系统、UI设计规范、控件设计、web UI、原生应用UI、移动端网页UI。',
//     description: '',
//     cost_day: 0,
//   },
//   {
//     uri: '用户体验类设计（独立项目）.png',
//     title: '用户体验类设计（独立项目）',
//     type: '基础型设计部',
//     item_type: '独立项目',
//     content:
//       '例如用研策划与执行、可用性测试策划与执行、需求洞察与原型设计、交互设计、服务设计等。',
//     description: '',
//     cost_day: 0,
//   },
//   {
//     uri: 'Banner.png',
//     title: 'Banner',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计banner 图片，用于网站轮播、电商广告等 ',
//     description: '标准交付时间：项目开始后，1个工作日交付方案',
//     cost_day: 1,
//   },
//   {
//     uri: '微信移动端单屏海报.png',
//     title: '微信移动端单屏海报',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计移动端单屏海报，可用于微信朋友圈等',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: '微信移动端长图文.png',
//     title: '微信移动端长图文',
//     item_type: '线上物料',
//     type: '业务型设计部',
//     content: '设计用于手机微信生态内展示的长图文',
//     description: '快速交付时间：项目开始后，每1个工作日1屏设计。',
//     cost_day: 1,
//   },
//   {
//     uri: '电子版优惠券/贺卡.png',
//     title: '电子版优惠券/贺卡',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计移动端电子版优惠券，贺卡等卡片类 ',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: 'PPT.png',
//     title: 'PPT',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计或者优化幻灯片',
//     description: '快速交付时间：项目开始后，每1个工作日交付 10 个版式。',
//     cost_day: 1,
//   },
//   {
//     uri: ' 移动端H5页面.png',
//     title: ' 移动端H5页面',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计用于移动端展示的有简单交互的页面',
//     description: '快速交付时间：项目开始后，每1个工作日交付1屏方案。',
//     cost_day: 1,
//   },
//   {
//     uri: '图标.png',
//     title: '图标',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计专属图标 ',
//     description: '快速交付时间：项目开始后，每1个工作日交付5 个图标。',
//     cost_day: 1,
//   },
//   {
//     uri: 'logo.png',
//     title: '图标',
//     type: '业务型设计部',
//     item_type: '线上物料',
//     content: '设计专属logo',
//     description: '快速交付时间：项目开始后，2个工作日交付方案。',
//     cost_day: 1,
//   },

//   {
//     uri: '海报.png',
//     title: '海报',
//     type: '业务型设计部',
//     item_type: '线下物料',
//     content: '海报 ',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: '易拉宝.png',
//     title: '易拉宝',
//     type: '业务型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的线下易拉宝',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: '优惠券.png',
//     title: '优惠券',
//     type: '业务型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的优惠券',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: '门票/贺卡/明信片.png',
//     title: '门票/贺卡/明信片',
//     type: '业务型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的门票/贺卡/明信片等卡片类',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: '传单（单面）.png',
//     title: '传单（单面）',
//     type: '业务型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的单面传单',
//     description: '标准交付时间：项目开始后，1个工作日交付完整方案',
//     cost_day: 1,
//   },
//   {
//     uri: '传单（双面）.png',
//     title: '传单（双面）',
//     type: '业务型设计部',
//     item_type: '线下物料',
//     content: '设计需印制的双面传单',
//     description: '标准交付时间：项目开始后，2个工作日交付完整方案',
//     cost_day: 2,
//   },
//   {
//     uri: '多页手册/折页.png',
//     title: '多页手册/折页',
//     type: '业务型设计部',
//     content: '设计多页手册，如多页 PDF 文档、多页手册等',
//     item_type: '线下物料',
//     description:
//       '快速交付时间：项目开始后，1个工作日交付主视觉方案；主视觉风格确认后，每1个工作日交付 10页。',
//     cost_day: 2,
//   },
//   {
//     uri: '名片.png',
//     title: '名片',
//     type: '业务型设计部',
//     content: '设计企业名片',
//     item_type: '线下物料',
//     description: '快速交付时间：项目开始后，1个工作日交付方案',
//     cost_day: 1,
//   },
//   {
//     uri: 'VIS延展（T恤，茶杯，笔记本等）.png',
//     title: 'VIS延展（T恤，茶杯，笔记本等）',
//     type: '业务型设计部',
//     content: '根据企业现有VIS, 进行延展设计',
//     item_type: '线下物料',
//     description: '快速交付时间：项目开始后，1个工作日交付方案',
//     cost_day: 1,
//   },
//   {
//     uri: '视觉类设计（独立项目）.png',
//     title: '视觉类设计（独立项目）',
//     type: '业务型设计部',
//     item_type: '独立项目',
//     content:
//       '例如VIS视觉识别系统、UI设计规范、控件设计、web UI、原生应用UI、移动端网页UI。',
//     description: '',
//     cost_day: 0,
//   },
//   {
//     uri: '用户体验类设计（独立项目）.png',
//     title: '用户体验类设计（独立项目）',
//     type: '业务型设计部',
//     item_type: '独立项目',
//     content:
//       '例如用研策划与执行、可用性测试策划与执行、需求洞察与原型设计、交互设计、服务设计等。',
//     description: '',
//     cost_day: 0,
//   },
// ];

export const projects: any = {
  屏幕展示: [
    {
      uri: 'Banner.png',
      title: 'Banner类',
      content: '横幅广告/推文头图/焦点图等',
      description: '1工作日交付',
      cost_day: 1,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
      sizes: [
        { value: '宽1920px，⾼600px', desc: '电商常见Banner尺寸' },
        { value: '宽1200px，⾼600px', desc: '2:1 规格的Banner尺寸' },
        { value: '宽900px，⾼383px', desc: '微信公众号文章封面大图尺寸' },
        { value: '宽200px，⾼200px', desc: '微信公众号文章封面小图尺寸' },
        { value: '宽900px，⾼500px', desc: '微信公众号文章内容头图尺寸' },
        { value: '宽1280px，⾼1184px', desc: '微信朋友圈封面尺寸' },
        { value: '宽720px，⾼720px', desc: '微信朋友圈九宫格（每张）尺寸' },
        { value: '宽540px，⾼960px', desc: '抖音封面图尺寸' },
        {
          value: '宽540px，⾼960px（默认高度400px）',
          desc: '抖音个人中心顶部背景图尺寸',
        },
        { value: '宽980px，⾼300px', desc: '微博封面图尺寸' },
        { value: '宽560px，⾼260px', desc: '微博焦点图尺寸' },
        { value: '宽1440px，⾼900px', desc: '视频封面常见尺寸' },
        { value: '其他尺寸规格' },
      ],
    },
    {
      uri: '移动端单屏海报.png',
      title: '移动端单屏海报',
      content: '设计移动端单屏海报，可用于微信朋友圈等',
      description: '1工作日交付',
      cost_day: 1,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
      sizes: [
        { value: '宽375px，⾼667px', desc: 'iPhone 8 手机单屏海报常见尺寸' },
        { value: '宽375px，⾼812px', desc: 'iPhone XS 手机单屏海报常见尺寸' },
        { value: '宽360px，⾼640px', desc: '安卓手机单屏海报常见尺寸' },
        { value: '其他尺寸规格' },
      ],
    },
    {
      uri: '移动端长图文.png',
      title: '移动端长图文',
      content: '设计用于移动端展示的长图文',
      description: '10版式内的长图文，2-3 工作日交付',
      cost_day: 3,
      business_cost_day: 2,
      single_cost_day: 2,
      price: 1300,
      in_price: 780,
      sizes: [
        {
          value: '750px宽，高根据内容而定',
          desc: '常见手机尺寸',
        },
        {
          value: '1440px宽，高根据内容而定',
          desc: '常见电脑尺寸'
        },
        // {
        //   value: '宽900px，高不限',
        //   desc: '移动端长图文常见尺寸，高度与内容以及设计风格有关',
        // },
        // {
        //   value: '其他尺寸规格',
        // },
      ],
    },
    {
      uri: '卡-券-票-明信片类.png',
      title: '卡/券/票/明信片类',
      content: '电子版优惠券，贺卡，票，名片，明信片等',
      description: '1工作日交付',
      cost_day: 1,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
      sizes: [
        {
          value: '宽360px，⾼216px',
          desc: '电子会员卡常见尺寸',
        },
        {
          value: '宽360px，⾼108px',
          desc: '电子优惠券常见尺寸',
        },
        {
          value: '其他尺寸规格',
        },
      ],
    },
    {
      uri: 'PPT 美化.png',
      title: 'PPT 美化',
      content: '在已有的PPT基础上进行设计优化',
      description: '10 页内的PPT，2-4工作日交付',
      cost_day: 4,
      business_cost_day: 2,
      single_cost_day: 2,
      price: 1300,
      in_price: 780,
      sizes: [
        { value: '宽1024px，⾼768px', desc: '4:3规格，适合常见的投影仪屏幕' },
        {
          value: '宽1920px，⾼1080px',
          desc: '16:9规格，适合常见的个人笔记本电脑、台式机、LED屏幕等',
        },
        { value: '其他尺寸规格' },
      ],
    },
    {
      uri: '线上多页文档.png',
      title: '线上多页文档',
      content: '设计用于手机、电脑阅读的多页文档',
      description: '10 页内的手册，2-4 工作日交付',
      cost_day: 4,
      business_cost_day: 2,
      single_cost_day: 2,
      price: 1650,
      in_price: 990,
      sizes: [
        {
          value: '宽1920px，⾼1080px',
          desc: '适合PC端浏览',
        },
        {
          value: '宽750px，⾼1334px',
          desc: '适合手机端浏览',
        },
        {
          value: '其他尺寸规格',
        },
      ],
    },
  ],
  印刷或线下物料: [
    {
      uri: '印刷版海报.png',
      title: '印刷版海报',
      content: '设计需印制的线下海报',
      description: '1工作日交付',
      cost_day: 1,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
      sizes: [
        { value: '宽140mm，⾼210mm', desc: '海报印刷常见尺寸（A5/32开）' },
        { value: '宽210mm，⾼285mm', desc: '海报印刷常见尺寸（A4/16开）' },
        { value: '宽285mm，⾼420mm', desc: '海报印刷常见尺寸（A3/8开）' },
        { value: '宽420mm，⾼570mm', desc: '海报印刷常见尺寸（A2/4开）' },
        { value: '其他尺寸规格' },
      ],
    },
    {
      uri: '易拉宝-X 展架-背景板.png',
      title: '易拉宝/X 展架/背景板',
      content: '设计需印制的线下易拉宝易拉宝/X 展架/背景板',
      description: '1-2工作日交付',
      cost_day: 2,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
      sizes: [
        {
          value: '宽60cm，⾼160cm',
          desc: '易拉宝、X展架、门型展架常见尺寸。适合室内，办公场所等地点摆放',
        },
        {
          value: '宽80cm，⾼180cm',
          desc: '易拉宝、X展架、门型展架常见尺寸。适合室外、商场等地点摆放',
        },
        {
          value: '宽80cm，⾼200cm',
          desc: '易拉宝、X展架、门型展架常见尺寸。适合室外、商场等地点摆放',
        },
        {
          value: '其他尺寸规格',
        },
      ],
    },
    {
      uri: '传单（单面）.png',
      title: '传单（单面）',
      content: '设计需印制的单面传单',
      description: '1 工作日交付',
      cost_day: 1,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
      sizes: [
        { value: '宽105mm，⾼148mm', desc: '传单印刷常见尺寸（A6/64开）' },
        { value: '宽140mm，⾼210mm', desc: '传单印刷常见尺寸（A5/32开）' },
        { value: '宽210mm，⾼285mm', desc: '传单印刷常见尺寸（A4/16开）' },
        { value: '其他尺寸规格', desc: '' },
      ],
    },
    {
      uri: '传单（双面）.png',
      title: '传单（双面）',
      content: '设计需印制的双面传单',
      description: '1-2个工作日交付',
      cost_day: 2,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 910,
      in_price: 400,
      sizes: [
        { value: '宽100mm，⾼140mm', desc: '传单印刷常见尺寸（A6/64开）' },
        { value: '宽140mm，⾼210mm', desc: '传单印刷常见尺寸（A5/32开）' },
        { value: '宽210mm，⾼285mm', desc: '传单印刷常见尺寸（A4/16开）' },
        { value: '其他尺寸规格', desc: '' },
      ],
    },
    {
      uri: '手册-画册-宣传册.png',
      title: '手册/画册/宣传册',
      content: '设计多页手册，如多页 PDF 文档、多页手册等',
      description: '10 页内的手册，2-4 工作日交付',
      cost_day: 4,
      business_cost_day: 2,
      single_cost_day: 2,
      price: 1650,
      in_price: 990,
      sizes: [
        {
          value: '宽100mm，⾼140mm',
          desc: '手册（每页）印刷常见尺寸（A6/64开）',
        },
        {
          value: '宽140mm，⾼210mm',
          desc: '手册（每页）印刷常见尺寸（A5/32开）',
        },
        {
          value: '宽210mm，⾼285mm',
          desc: '手册（每页）印刷常见尺寸（A4/16开）',
        },
        { value: '其他尺寸', desc: '' },
      ],
    },
    // {
    //   uri: '印刷版卡/券/票/明信片类.png',
    //   title: '印刷版卡/券/票/明信片类',
    //   content: '设计需印制的门票/贺卡/明信片等卡片类',
    //   description: '1工作日交付',
    //   cost_day: 4,
    //   business_cost_day: 2,
    //   single_cost_day: 2,
    //   price: 1650,
    //   sizes: [
    //     '宽90mm，⾼54mm',
    //     '宽180mm，⾼54mm',
    //     '宽140mm，⾼68mm',
    //     '宽86mm，⾼54mm',
    //     '宽100mm，⾼70mm',
    //     '宽120mm，⾼80mm',
    //     '宽143mm，⾼93mm',
    //     '其他尺寸',
    //   ],
    // },
    {
      uri: '二折页-三折页.png',
      title: '二折页/三折页',
      content: '设计多页手册，如多页 PDF 文档、多页手册等',
      description: '2-3工作日交付',
      cost_day: 3,
      business_cost_day: 2,
      single_cost_day: 2,
      price: 1400,
      in_price: 780,
      sizes: [
        {
          value: '宽285mm，⾼210mm',
          desc: '折页（平铺）印刷常见尺寸（A4/16开）',
        },
        {
          value: '宽210mm，⾼140mm',
          desc: '折页（平铺）印刷常见尺寸（A5/32开）',
        },
        {
          value: '其他尺寸',
          desc: '',
        },
      ],
    },
  ],
  品牌设计: [
    {
      uri: 'Logo图标-icon-头像.png',
      title: 'Logo图标 / icon / 头像',
      content: '设计专属logo',
      description: '1-2工作日交付 3 方案',
      cost_day: 2,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 800,
      in_price: 480,
    },
    {
      uri: 'VIS 延展.png',
      title: 'VIS 延展',
      content: '根据已有 VIS 手册延展其他设计物料',
      description: '1-2工作日交付',
      cost_day: 2,
      business_cost_day: 1,
      single_cost_day: 1,
      price: 455,
      in_price: 200,
    },
  ],
  UI: [
    {
      uri: 'UI.png',
      title: 'UI',
      content: 'UI / UX 用户体验类',
      description: '3-5工作日交付',
      cost_day: 5,
      business_cost_day: 3,
      single_cost_day: 3,
      price: 2980,
      in_price: 2980,
    }
  ],
  包装设计: [
    {
      uri: 'package.png',
      title: '包装设计',
      content: '横幅广告/推文头图/焦点图等',
      description: '1-2个工作日交付方案',
      cost_day: 5,
      business_cost_day: 3,
      single_cost_day: 3,
      price: 2980,
      in_price: 2980,
      sizes:[ { value: '其他尺寸规格' }]
    }
  ],
  H5: [
    {
      uri: 'H5.png',
      title: 'H5',
      content: '官网 / 落地页 / H5 活动页',
      description: '3-5工作日交付',
      cost_day: 5,
      business_cost_day: 3,
      single_cost_day: 3,
      price: 2980,
      in_price: 2980,
    }
  ],
};

export const findProjectItem = (name: string): any => {
  console.log(name)
  const new_keys = Object.keys(projects);
  let result = false;
  new_keys.forEach(key => {
    const is_ok = projects[key].find((item: any) => item.title === name);
    if (is_ok) {
      result = is_ok;
    }
  });
  return result;
};

import React from 'react';
import { Button } from 'antd';
import queryString from 'query-string';

import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import { findProjectItem } from '../../db/product.db';
import { LittleCard } from '../create-demand-new';
// import one from './one@2x.png';
import two from './two@2x.png';
import three from './three@2x.png';
import moment from 'moment';
import { message } from '../../components/message';
import {
  ViewerTeamComponent,
  // ChangeDemandTypeComponent 
} from '../../apollo';
import { getFinishDate } from '../../db/festival.db';

interface CardProps {
  uri: string;
  title: string;
  content: string;
  description: string;
}

export class Card extends React.Component<CardProps> {
  render() {
    const { uri, title, content, description } = this.props;
    return (
      <div className={styles.card_container}>
        {this.props.children}
        <img className={styles.card_img} alt="" src={uri} />
        <div>
          <div className={styles.card_title}>{title}</div>
          <div className={styles.card_content}>{content}</div>
          <div className={styles.card_description}>{description}</div>
        </div>
      </div>
    );
  }
}

class ChoosePlan extends React.Component<any> {
  async payOrder(send: any) {
    try {
      const search = queryString.parse(window.location.search);
      const { data } = await send({
        variables: {
          CreateProductOrder: {
            product_name: search.type,
            demand_id: search.id,
          },
        },
      });
      // message.success('创建成功');
      this.props.history.push(
        `/pay-order?order_id=${data.createProductOrder.id}`,
      );
    } catch (e) {
      message.error(e);
    }
  }
  render() {
    const search = queryString.parse(window.location.search);
    const item = findProjectItem(String(search.type));
    return (
      <ViewerTeamComponent>
        {({ data, error, loading, refetch }) => {
          if (error || loading || !data) {
            return '';
          }
          const team = data.viewer.team;
          const is_plan =
            team && team.plan && new Date(team.plan_end_date) > new Date();
          const hour = new Date().getHours();
          let other_cost_day = 0;
          if (hour >= 18) {
            other_cost_day = 1;
          }
          return (
            <div style={{ position: 'relative', paddingBottom: '120px' }}>
              <HeaderLayout>
                <div className={styles.title}>制定交付规划</div>
                <div className={styles.desc}>
                  根据您的预算和时间需求配置交付规划
                </div>
                {is_plan && team && (
                  <div className={styles.card_container}>
                    <img alt="" className={styles.card_image} src={two} />
                    <div className={styles.card_middle}>
                      <br />
                      <div className={styles.card_title}>
                        已添加至{' '}
                        <span style={{ color: '#7CB2B8' }}>{team.plan}</span>{' '}
                        的需求队列
                      </div>
                      <div className={styles.card_desc}>
                        按当前队列顺序，预计于{' '}
                        <span style={{ color: '#7CB2B8' }}>
                          {moment(new Date(String(search.date))).format(
                            'YYYY-MM-DD',
                          )}
                          前
                        </span>{' '}
                        交付
                      </div>
                    </div>
                    <div
                      className={styles.card_right}
                      style={{ border: 'none' }}
                    >
                      <div style={{ width: '230px' }} />
                      <Button
                        className={`linear-gradient-gray ${styles.go_order
                          } normal_button`}
                        type="primary"
                        onClick={() => this.props.history.push('/service')}
                      >
                        查看需求队列
                      </Button>
                    </div>
                  </div>
                )}
                <br />
                {/* <br /> */}
                {/* <div className={styles.card_container}>
                  <img alt="" className={styles.card_image} src={one} />
                  <div className={styles.card_middle}>
                    <div className={styles.card_title}>
                      按 <span style={{ color: '#7CB2B8' }}>单次独立项目</span>
                    </div>
                    <div className={styles.card_desc}>
                      <span style={{ color: '#7CB2B8' }}>
                        按人工日独立计价，快速交付，可多项目并发进行
                      </span>
                    </div>
                    <div className={styles.card_content}>
                      灵活、快速交付方式
                      <br />
                      支持企业的各类物料设计需求
                    </div>
                  </div>
                  <div className={styles.card_right}>
                    <span style={{ paddingTop: '26px' }}>
                      <span
                        style={{
                          color: '#7CB2B8',
                          fontWeight: 600,
                          fontSize: '28px',
                        }}
                      >
                        {is_plan ? item.in_price : item.price}元
                      </span>
                      <br />
                      预计{' '}
                      <span
                        style={{
                          color: '#7CB2B8',
                        }}
                      >
                        {moment(
                          getFinishDate(
                            new Date(),
                            item.single_cost_day + other_cost_day,
                          ),
                        ).format('YYYY-MM-DD')}
                        前{' '}
                      </span>{' '}
                      交付
                    </span>
                    <ChangeDemandTypeComponent>
                      {(send2) => {
                        return (
                          <Button
                            className={`linear-gradient-gray ${
                              styles.go_order
                            } normal_button`}
                            type="primary"
                            onClick={async () => {
                              try {
                                const { data }: any = await send2({
                                  variables: {
                                    // ts-ignore
                                    id: String(search.id),
                                  },
                                });
                                this.props.history.push(
                                  `/pay-order?order_id=${
                                    data.changeDemandType.order.id
                                  }`,
                                );
                              } catch (e) {
                                message.error(e);
                              }
                            }}
                          >
                            立即下单
                          </Button>
                        );
                      }}
                    </ChangeDemandTypeComponent>
                  </div>
                </div> */}
                {team &&
                  (!team.plan || new Date(team.plan_end_date) < new Date()) && (
                    <div className={styles.card_container}>
                      <img alt="" className={styles.card_image} src={two} />
                      <div className={styles.card_middle}>
                        <div className={styles.card_title}>
                          使用{' '}
                          <span style={{ color: '#7CB2B8' }}>基础型设计部</span>{' '}
                          进行设计
                        </div>
                        <div className={styles.card_desc}>
                          每月最低不到 3000 元，无限量设计、无限量修改
                        </div>
                        <div className={styles.card_content}>
                          <span style={{ color: '#DEBB6E' }}>
                            · 大部分需求 2 工作日交付 <br />·
                            支持企业的各类基础物料设计需求
                          </span>
                        </div>
                      </div>
                      <div className={styles.card_right}>
                        <span style={{ paddingTop: '36px' }}>
                          <span
                            style={{
                              color: '#7CB2B8',
                              fontWeight: 600,
                              fontSize: '20px',
                            }}
                          >
                            订阅后无需额外费用
                          </span>
                          <br />
                          预计{' '}
                          <span
                            style={{
                              color: '#7CB2B8',
                            }}
                          >
                            {moment(
                              getFinishDate(
                                new Date(),
                                item.cost_day + other_cost_day,
                              ),
                            ).format('YYYY-MM-DD')}
                            前{' '}
                          </span>{' '}
                          交付
                        </span>
                        <Button
                          className={`${styles.go_order} normal_button`}
                          onClick={() =>
                            this.props.history.push(
                              `/choose-subscript-plan-pay?plan=基础型设计部`,
                            )
                          }
                        >
                          立即订阅
                        </Button>
                      </div>
                    </div>
                  )}
                {team &&
                  (team.plan === '基础型设计部' ||
                    new Date(team.plan_end_date) < new Date()) && (
                    <div className={styles.card_container}>
                      <img alt="" className={styles.card_image} src={three} />
                      <div className={styles.card_middle}>
                        <div className={styles.card_title}>
                          使用{' '}
                          <span style={{ color: '#7CB2B8' }}>业务型设计部</span>{' '}
                          进行设计
                        </div>
                        <div className={styles.card_desc}>
                          每月最低仅需 6999元，全方位满足业务需求
                        </div>
                        <div className={styles.card_content}>
                          <span style={{ color: '#DEBB6E' }}>
                            · 大部分需求 1 工作日交付 <br />·
                            企业运营中的各类业务需求均能支持
                          </span>
                        </div>
                      </div>
                      <div className={styles.card_right}>
                        <span style={{ paddingTop: '36px' }}>
                          <span
                            style={{
                              color: '#7CB2B8',
                              fontWeight: 600,
                              fontSize: '20px',
                            }}
                          >
                            订阅后无需额外费用
                          </span>
                          <br />
                          预计{' '}
                          <span
                            style={{
                              color: '#7CB2B8',
                            }}
                          >
                            {moment(
                              getFinishDate(
                                new Date(),
                                item.business_cost_day + other_cost_day,
                              ),
                            ).format('YYYY-MM-DD')}
                            前{' '}
                          </span>{' '}
                          交付
                        </span>
                        <Button
                          className={`${styles.go_order} normal_button`}
                          onClick={() =>
                            this.props.history.push(
                              `/choose-subscript-plan-pay?plan=业务型设计部`,
                            )
                          }
                        >
                          立即订阅
                        </Button>
                      </div>
                    </div>
                  )}
              </HeaderLayout>
              <div className={styles.bottom}>
                <div className={styles.bottom_container}>
                  <div className={styles.bottom_title}>
                    {item && <LittleCard {...item} />}
                  </div>
                  {/* <div className={styles.bottom_right}>
                    <Button
                      onClick={() => this.props.history.goBack()}
                      className={`${styles.bottom_button} normal_button`}
                    >
                      上一步
                    </Button>
                    <Button
                      className={`${styles.bottom_button} linear-gradient-gray normal_button`}
                      style={{ color: '#FFFFFF' }}
                      onClick={() => alert('sure')}
                      type="primary"
                    >
                      下一步
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        }}
      </ViewerTeamComponent>
    );
  }
}

export default ChoosePlan;

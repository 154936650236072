import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  border: solid 2px white;
  border-radius: 50%;
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  position: absolute;
  text-algin: center;
  color: #FFFFFF;
  font-size: 16px;
  background: #7CB2B8;
  box-shadow: 0px 0px 5px 0px rgba(105, 112, 122, 0.41);
  transform: translate3d(-50%, -50%, 0);
`
// const Container = styled.div`
//   border: solid 3px white;
//   border-radius: 50%;
//   box-sizing: border-box;
//   box-shadow:
//     0 0 0 1px rgba(0,0,0,0.3),
//     0 0 0 2px rgba(0,0,0,0.2),
//     0 5px 4px rgba(0,0,0,0.4);
//   height: 16px;
//   position: absolute;
//   transform: translate3d(-50%, -50%, 0);
//   width: 16px;
// ` 

function Point(props: any) {
  const { geometry, index } = props.annotation
  if (!geometry) return null
  return (
    <Container
      style={{
        top: `${geometry.y}%`,
        left: `${geometry.x}%`
      }}
    ><div style={{ textAlign: "center", justifyContent: "center", width: "32px", height: "32px" }}>{index}</div></Container>
  )
}


export default Point
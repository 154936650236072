import React from 'react';
import right from '../plan/right.png';
import styles from './index.module.css';
import plan1 from './plan1@2x.png';
import plan2 from './plan2@2x.png';

class PlanList extends React.Component {
  render() {
    return (
      <div className={styles.plan_list_container}>
        <img className={styles.plan_list_img} alt="" src={right} />
        {this.props.children}
      </div>
    );
  }
}

export const plan: any = {
  基础型设计部: {
    plan_img: plan1,
    packages: [
      {
        name: '月度订阅',
        price: 4499,
        // price: 3999,
        count: 1,
      },
      {
        name: '季度订阅',
        price: 3499,
        // price: 3699,
        count: 3,
      },
      {
        name: '年度订阅',
        price: 2999,
        // price: 3399,
        count: 12,
      },
    ],
    desc: (count?: number) => (
      // <div className={styles.list}>
      //   <PlanList>
      //     最少可同时进行 <span style={{ color: '#7CB2B8' }}>1</span> 个需求
      //   </PlanList>
      //   <PlanList>订阅期内无限量提交需求及修改</PlanList>
      //   <PlanList>首次订阅七天无理由退款</PlanList>
      //   <PlanList>
      //     配备 <span style={{ color: '#7CB2B8' }}>1</span> 个资深设计师 +{' '}
      //     <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
      //   </PlanList>
      // </div>
      <div className={styles.list}>
        {count && (
          <PlanList>
            可同时进行 <span style={{ color: '#7CB2B8' }}>{count}</span> 个需求
          </PlanList>
        )}
        <PlanList>订阅期内无限量提交需求及修改</PlanList>
        <PlanList>首次订阅七天无理由退款</PlanList>
        {/* <PlanList>
          配备 <span style={{ color: '#7CB2B8' }}>1</span> 个资深设计师 +{' '}
          <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
        </PlanList> */}
        <PlanList>
          按
          <span style={{ color: '#7CB2B8' }}>
            快速递交时间交付，大部分设计需求可以在
            <span style={{ color: '#7CB2B8' }}>2个工作日</span>交付
          </span>
        </PlanList>
      </div>
    ),
  },
  业务型设计部: {
    plan_img: plan2,
    packages: [
      {
        name: '月度订阅',
        price: 7999,
        // price: 6499,
        count: 1,
      },
      {
        name: '季度订阅',
        price: 7499,
        // price: 6099,
        count: 3,
      },
      {
        name: '年度订阅',
        price: 6999,
        // price: 5899,
        count: 12,
      },
    ],
    desc: (count?: number) => (
      <div className={styles.list}>
        {count && (
          <PlanList>
            可同时进行 <span style={{ color: '#7CB2B8' }}>{count}</span> 个需求
          </PlanList>
        )}
        <PlanList>订阅期内无限量提交需求及修改</PlanList>
        <PlanList>首次订阅七天无理由退款</PlanList>
        {/* <PlanList>
          配备 <span style={{ color: '#7CB2B8' }}>1</span> 个资深设计师 +{' '}
          <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
        </PlanList> */}
        <PlanList>
          按
          <span style={{ color: '#7CB2B8' }}>
            快速递交时间交付，大部分设计需求可以在
            <span style={{ color: '#7CB2B8' }}>1个工作日</span>交付
          </span>
        </PlanList>
      </div>
    ),
  },
};

import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ViewerComponent } from '../apollo';
import queryString from 'query-string';

// const time = 1000 * 60 * 60 * 24; // 最大登录有效时间为 24 小时
export class PrivateRoute extends Component<any, {}> {
  auth = {
    status: false,
    updated_date: Date.now(),
  };
  render() {
    const { component: TrueComponent, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          // console.log("this.props", this.props)
          // console.log("this.props2", window.location.href)
          const path = this.props.path;
          if (path === '/') {
            const search = queryString.parse(window.location.search);
            if (search.access_token) {
              localStorage.setItem('token', String(search.access_token));
            }
            const reback_url = localStorage.getItem('reback-url');
            if (reback_url) {
              localStorage.removeItem('reback-url');
              window.location.href = reback_url;
              return '跳转中.....';
            }
          } else if (path === '/add-team') {
            const code: any = queryString.parse(window.location.search).code;
            // console.log(queryString.parse(window.location.search))
            if (code) {
              localStorage.setItem(
                'team_code',
                JSON.stringify({
                  created_date: new Date(),
                  code,
                }),
              );
            }
          }

          // if (this.auth.status && Date.now() - this.auth.updated_date < time) {
          //   return (
          //     <Route
          //       {...this.props}
          //       render={props => <TrueComponent {...props} />}
          //     />
          //   );
          // }
          return (
            <ViewerComponent fetchPolicy="no-cache">
              {({ data, error, loading }) => {
                if (loading) {
                  return '';
                }
                // fix 如果是未登录错误 返回登录
                // 如果是其他的错误返回 500 页面
                // 如果是网络错误返回到网络错误页面
                if (error || !data) {
                  // const code: any = queryString.parse(window.location.search).code;
                  localStorage.setItem('reback-url', window.location.href);
                  return (
                    <Redirect
                      exact
                      to={{
                        pathname: '/login',
                        state: { from: this.props.location },
                      }}
                    />
                  );
                }
                this.auth = {
                  status: true,
                  updated_date: Date.now(),
                };
                localStorage.setItem('session', JSON.stringify(data.viewer));
                const code = localStorage.getItem('team_code');
                let result: any = {};
                if (code) {
                  result = JSON.parse(code);
                }
                if (
                  !data.viewer.team &&
                  code &&
                  Date.now() - new Date(result.created_date).getTime() <
                  1000 * 60 * 2 &&
                  path !== '/add-team-form'
                  && path !== '/add-team'
                  // path === '/'
                ) {
                  localStorage.removeItem('team_code');
                  // return (
                  //   <Redirect
                  //     exact
                  //     to={{
                  //       // pathname: `/add-team-form`,
                  //       pathname: `/add-team-form?team_code=${result.code}`,
                  //       state: { from: this.props.location },
                  //     }}
                  //   />
                  // );
                  window.location.href = `/add-team-form?team_code=${result.code}`;
                  return '跳转中.....';
                }
                if (
                  !data.viewer.team &&
                  path !== '/create-team' &&
                  path !== '/add-team-form'
                  && path !== '/add-team'
                ) {
                  return (
                    <Redirect
                      exact
                      to={{
                        pathname: '/choose-or-create-team',
                        state: { from: this.props.location },
                      }}
                    />
                  );
                }
                const is_choose_team = sessionStorage.getItem("is_choose_team")
                if (!is_choose_team && data.viewer.teams.length > 1 && path !== '/choose-team' && path !== '/create-team' && path !== '/add-team') {
                  return (
                    <Redirect
                      exact
                      to={{
                        pathname: '/choose-team',
                        state: { from: this.props.location },
                      }}
                    />
                  );
                }
                return (
                  <Route
                    {...this.props}
                    render={props => <TrueComponent {...props} />}
                  />
                );
              }}
            </ViewerComponent>
          );
        }}
      />
    );
  }
}

import React from 'react';

import styles from './index.module.css';

interface HeaderProps {
  title: string;
  desc: string
  extar?: any
}

class CardWithHeader extends React.Component<HeaderProps>{
  render() {
    const { title, desc, extar } = this.props;
    return (
      <div className={styles.bg}>
        <div style={{ position: 'relative' }}>
          <div className={styles.title}>{title}</div>
          <div className={styles.desc}>
            {desc}
          </div>
          <div className={styles.desc}>
          {extar}
          </div>
        </div>
        <div className={styles.container}>
          <div >{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default CardWithHeader;

import React from 'react';
import { Modal, Button } from 'antd';

import styles from './index.module.css';
import { withRouter } from 'react-router';
import success from './success@2x.png';

class FailModal extends React.Component<any> {
  render() {
    const onOkClick=this.props.onOkClick
    return (
      <Modal
        visible={this.props.visible}
        className={`modal-cover modal-cover-body`}
        width={600}
        footer={null}
        title={null}
        closable={false}
        maskClosable={false}
      >
        <div className={styles.modal_container}>
          <img className={styles.success_img} src={success} alt="" />
          <div className={styles.modal_title}></div>
          <div className={styles.modal_desc}>
            {this.props.desc || '我们将在2个工作日内与您联系。'}
          </div>
          <Button
            className={`linear-gradient-gray ${styles.sure} normal_button`}
            onClick={() => onOkClick?onOkClick():this.props.history.push('/service')}
            type="primary"
          >
            {this.props.okText?this.props.okText: "确定"}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withRouter(FailModal);

import React from 'react';
import {
  Button,
  Form,
  Input,
  Radio,
  Upload,
  Icon,
  Checkbox,
} from 'antd';
import queryString from 'query-string';
import SuccessModal from '../../components/success-modal';
import SureDemandModal from '../../components/sure-demand-modal';

import { CreateDemandComponent } from '../../apollo';
import { message } from '../../components/message';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import tip from './tip@2x.png';
import moment from 'moment';
import { up_load_life_photo_options } from '../../lib/upload';
import { getFilesName } from '../../lib';
// import warn from '../../image/warn@2x.png';
import { findProjectItem } from '../../db/product.db';
// import { QrCode } from '../choose-type-new/qr_code';
import { oss_uri } from '../../config';
import { QrCodeText } from '../choose-type-new/qr_code_text';

const { TextArea } = Input;

export class LittleCard extends React.Component<any> {
  render() {
    const { uri, title, content, description } = this.props;
    return (
      <div className={styles.card_container}>
        {this.props.children}
        <img className={styles.card_img} alt="" src={oss_uri + uri} />
        <div>
          <div className={styles.card_title}>{title}</div>
          <div className={styles.card_content}>{content}</div>
          <div className={styles.card_description}>{description}</div>
        </div>
      </div>
    );
  }
}

class Tip extends React.Component<any> {
  render() {
    const { style } = this.props;
    return (
      <div style={style} className={styles.tip_container}>
        <img className={styles.tip_img} alt="" src={tip} />
        {this.props.children}
      </div>
    );
  }
}

class CreateDemandUI extends React.Component<any> {
  state = {
    visible: false,
    sure_visible: false,
    values: {},
    uris: [],
    ui_uris: [],
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        this.setState({
          sure_visible: true,
          values,
        });
      }
    });
  };

  sure = async (send: any) => {
    this.setState({
      sure_visible: false,
    });
    try {
      // color_description: color_preference!
      const project_name = queryString.parse(window.location.search).type;
      const { values, uris, ui_uris }: any = this.state;
      const send_data = {
        ...values,
        project_name,
        size_description:
          values.choose_size === '其他尺寸'
            ? values.choose_size_other_left + "*" + values.choose_size_other_right
            : values.choose_size,
        description_files: getFilesName(uris, values.description_files),
        ui_description_files: getFilesName(
          ui_uris,
          values.ui_description_files,
        ),
        file_format: values.file_format.join(','),
        choose_size: undefined,
        ui_description_more: undefined,
        choose_size_other_left: undefined,
        choose_size_other_right: undefined,
      }
      if (values.ui_description_more) {
        send_data.ui_description = values.ui_description_more.join(",")
      }
      const { data } = await send({
        variables: {
          CreateDemand: send_data,
        },
      });
      // message.success('创建成功');
      const { form } = this.props;
      const { resetFields } = form;
      resetFields();
      const date = moment(data && data.createDemand.finish_date).format(
        'YYYY-MM-DD HH:mm',
      );
      // const date = moment(data && data.createDemand.finish_date).format(
      //   'YYYY-MM-DD HH:mm:ss',
      // );
      const id = data && data.createDemand.id;
      const search = queryString.parse(window.location.search);
      // this.setState({ visible: true });
      // this.props.history.push('/service');
      this.props.history.push(
        `/choose-plan?date=${date}&id=${id}&type=${search.type}`,
      );
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const search = queryString.parse(window.location.search);
    const item = findProjectItem(String(search.type));
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { visible, uris, ui_uris, sure_visible } = this.state;
    const uploadButton = (
      <div>
        <Button className="btn_upload">
          <Icon type="plus" />
          {/* <div className="ant-upload-text">上传</div> */}
          上传文件
        </Button>
        <span className="file_desc">
          支持pdf及word，jpg格式，.zip 和 .rar格式，.ppt 和 .pptx
        </span>
      </div>
    );
    return (
      <CreateDemandComponent>
        {(send, { loading, data }) => {
          return (
            <div>
              <HeaderLayout>
                <div style={{ position: 'relative' }}>
                  <div className={styles.title}>创建新的设计需求</div>
                  <div className={styles.desc}>
                    详细、规范的描述您的设计需求，能够大幅提高设计方案的精准度
                  </div>
                  {/* <QrCode /> */}
                  <QrCodeText />
                </div>
                <SuccessModal
                  visible={visible}
                  desc={
                    <>
                      `您可以在需求队列里查看需求进程。`
                      <br /> `现在系统预计
                      {moment(data && data.createDemand.finish_date).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                      完成，如有变动，会由设计顾问与您联系。`
                    </>
                  }
                />
                <SureDemandModal
                  onCancel={() => this.setState({ sure_visible: false })}
                  onSure={this.sure.bind(this, send)}
                  visible={sure_visible}
                />
                {/* <Modal
                  visible={this.state.sure_visible}
                  className={`modal-cover modal-cover-body`}
                  width={600}
                  onCancel={() => this.setState({ sure_visible: false })}
                  footer={null}
                  title={null}
                >
                  <div className={styles.modal_container}>
                    <div className={styles.modal_title}>
                      <img className={styles.warn_img} src={warn} alt="" />
                      请确认提交该需求吗？
                    </div>
                    <div className={styles.modal_desc}>
                      请确保您需要出现在交付物上的所有文案内容都已填写完成；
                      <br />
                      如提交需求后修改文案内容，风格偏好等，交付时间可能会顺延
                    </div>
                    <Button
                      className={`linear-gradient-gray ${styles.sure2} normal_button`}
                      loading={loading}
                      onClick={this.sure.bind(this, send)}
                      type="primary"
                    >
                      确认提交
                    </Button>
                    <br />
                    <br />
                    <Button
                      className={`${styles.aggress_sure} normal_button`}
                      onClick={() => this.setState({ sure_visible: false })}
                    >
                      再完善一下
                    </Button>
                  </div>
                </Modal> */}
                <div className={styles.container}>
                  {/* <div className={styles.title}>新增需求</div> */}
                  <div
                    style={{
                      display: 'flex',
                      padding: '30px',
                      marginTop: '30px',
                    }}
                  >
                    <div className={styles.left}>
                      <Form className="login-form">
                        <Form.Item label="需求名称">
                          {getFieldDecorator('name', {
                            rules: [
                              { required: true, message: '请输入需求名称!' },
                            ],
                          })(
                            <Input
                              maxLength={20}
                              className="king_normal_input"
                              placeholder="例：留学生租房网站"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('name') &&
                              getFieldValue('name').length) ||
                              0}
                            /20
                          </span>
                        </Form.Item>
                        <Form.Item label="需求描述">
                          {getFieldDecorator('description', {
                            rules: [
                              { required: true, message: '请输入需求描述!' },
                            ],
                          })(
                            <TextArea
                              rows={4}
                              maxLength={5000}
                              className="king_normal_text_area"
                              placeholder="输入在该物料中需要出现的所有文案内容"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('description') &&
                              getFieldValue('description').length) ||
                              0}
                            /5000
                          </span>
                        </Form.Item>
                        <div>
                          上传已有的需求文档/原型图/线框图，以及设计稿中需要出现的文案，素材图片等最多可以上传5个文件
                        </div>
                        <br />
                        <Form.Item>
                          {getFieldDecorator('description_files')(
                            <Upload
                              {...up_load_life_photo_options((uri, file) =>
                                this.setState({
                                  uris: [...uris, { [uri]: file.name }],
                                }),
                              )}
                              accept=".pdf,.jpg,.docx,.doc,.rar,.zip,.ppt,.pptx"
                              defaultFileList={
                                getFieldValue('description_files') &&
                                getFieldValue('description_files').fileList
                              }
                            >
                              {((getFieldValue('description_files') &&
                                getFieldValue('description_files').fileList
                                  .length < 5) ||
                                !getFieldValue('description_files')) &&
                                uploadButton}
                            </Upload>,
                          )}
                        </Form.Item>
                        <Form.Item label="用户群体">
                          <span style={{ color: '#AEB1B5' }}>
                            选择用户群体
                          </span>
                          <br />
                          {getFieldDecorator('client', {
                            rules: [
                              { required: true, message: '请选择用户群体!' },
                            ],
                          })(
                            <Radio.Group>
                              <Radio value="面向C端">
                                面向C端
                              </Radio>
                              {getFieldValue('client') === "面向C端" && <Form.Item style={{ marginBottom: "-30px", width: "40vw" }} label="详细描述">
                                {getFieldDecorator('client_desc', {
                                  rules: [
                                    { required: true, message: '请输入详细描述!' },
                                  ],
                                })(
                                  <Input
                                    maxLength={20}
                                    className="king_normal_input"
                                    placeholder="例：初次出国留学的留学生"
                                  />,
                                )}
                                <span className="form_right">
                                  {(getFieldValue('client_desc') &&
                                    getFieldValue('client_desc').length) ||
                                    0}
                                  /20
                                </span>
                              </Form.Item>
                              }
                              <br />
                              <Radio value="面向B端">
                                面向B端
                              </Radio>
                              <br />
                              {getFieldValue('client') === "面向B端" && <Form.Item style={{ marginBottom: "-30px", width: "40vw" }} label="详细描述">
                                {getFieldDecorator('client_desc', {
                                  rules: [
                                    { required: true, message: '请输入详细描述!' },
                                  ],
                                })(
                                  <Input
                                    maxLength={20}
                                    className="king_normal_input"
                                    placeholder="例：初次出国留学的留学生"
                                  />,
                                )}
                                <span className="form_right">
                                  {(getFieldValue('client_desc') &&
                                    getFieldValue('client_desc').length) ||
                                    0}
                                  /20
                                </span>
                              </Form.Item>
                              }
                            </Radio.Group>,
                          )}
                        </Form.Item>
                        <Form.Item label="设计风格">
                          <span style={{ color: '#AEB1B5' }}>
                            选择设计风格
                          </span>
                          <br />
                          {getFieldDecorator('ui_description', {
                            rules: [
                              {
                                required: true,
                                message: '请输入设计风格!',
                              },
                            ],
                          })(
                            <Radio.Group>
                              <Radio value="上传已有设计风格">
                                上传已有设计风格
                              </Radio>
                              {getFieldValue('ui_description') === "上传已有设计风格" && <Form.Item>
                                {getFieldDecorator('ui_description_files')(
                                  <Upload
                                    {...up_load_life_photo_options((uri, file) =>
                                      this.setState({
                                        ui_uris: [...ui_uris, { [uri]: file.name }],
                                      }),
                                    )}
                                    accept=".pdf,.jpg,.docx,.doc,.rar,.zip"
                                    defaultFileList={
                                      getFieldValue('ui_description_files') &&
                                      getFieldValue('ui_description_files').fileList
                                    }
                                  >
                                    {((getFieldValue('ui_description_files') &&
                                      getFieldValue('ui_description_files').fileList
                                        .length < 5) ||
                                      !getFieldValue('ui_description_files')) &&
                                      uploadButton}
                                  </Upload>,
                                )}
                              </Form.Item>
                              }
                              {getFieldValue('ui_description') === "上传已有设计风格" && <Form.Item style={{ marginBottom: "-30px", width: "40vw" }} label="备注信息">
                                {getFieldDecorator('ui_description_remark', {
                                  rules: [
                                    // { required: true, message: '请输入备注!' },
                                  ],
                                })(
                                  <Input
                                    maxLength={20}
                                    className="king_normal_input"
                                    placeholder="输入您已有设计风格的详细描述/备注信息"
                                  />,
                                )}
                                <span className="form_right">
                                  {(getFieldValue('client_desc') &&
                                    getFieldValue('client_desc').length) ||
                                    0}
                                  /20
                                </span>
                              </Form.Item>
                              }
                              <br />
                              <Radio value="参考行业标准（支持多选）">
                                参考行业标准（支持多选）
                              </Radio>
                              {getFieldValue('ui_description') === "参考行业标准（支持多选）" && <Form.Item style={{ marginBottom: "-30px", marginLeft: "50px", width: "40vw" }} label="">
                                {getFieldDecorator('ui_description_more', {
                                  rules: [
                                    { required: true, message: '请选择行业标准!' },
                                  ],
                                })(<Checkbox.Group>
                                  <Checkbox value="Ant Design">
                                    Ant Design
                                    <span
                                      style={{
                                        color: '#AEB1B5',
                                        paddingLeft: '20px',
                                      }}
                                    >
                                      参考样例
                                    </span>
                                  </Checkbox>
                                  <br />
                                  <Checkbox value="Element Design">
                                    Element Design
                                    <span
                                      style={{
                                        color: '#AEB1B5',
                                        paddingLeft: '20px',
                                      }}
                                    >
                                      参考样例
                                    </span>
                                  </Checkbox>
                                  <br />
                                  <Checkbox value="IOS Design">
                                    IOS Design
                                    <span
                                      style={{
                                        color: '#AEB1B5',
                                        paddingLeft: '20px',
                                      }}
                                    >
                                      参考样例
                                    </span>
                                  </Checkbox>
                                  <br />
                                  <Checkbox value="Material Design">
                                    Material Design
                                    <span
                                      style={{
                                        color: '#AEB1B5',
                                        paddingLeft: '20px',
                                      }}
                                    >
                                      参考样例
                                    </span>
                                  </Checkbox>
                                </Checkbox.Group>)}
                              </Form.Item>
                              }
                              <br />
                              <Radio value="无设计规范，待设计师自行发挥">
                                无设计规范，待设计师自行发挥
                              </Radio>
                            </Radio.Group>,
                          )}
                        </Form.Item>
                        <Form.Item label="设计尺寸">
                          <span style={{ color: '#AEB1B5' }}>
                            选择设计尺寸（支持多选）
                          </span>
                          <br />
                          {getFieldDecorator('choose_size', {
                            rules: [
                              {
                                required: true,
                                message: '请选择设计尺寸!',
                              },
                            ],
                          })(
                            <Radio.Group>
                              <Radio value="App端">
                                App端
                                <span
                                  style={{
                                    color: '#AEB1B5',
                                    paddingLeft: '20px',
                                  }}
                                >
                                  默认尺寸：375*667px
                                </span>
                              </Radio>
                              <br />
                              <Radio value="小程序">
                                小程序
                                <span
                                  style={{
                                    color: '#AEB1B5',
                                    paddingLeft: '20px',
                                  }}
                                >
                                  默认尺寸：375*667px
                                </span>
                              </Radio>
                              <br />
                              <Radio value="手机网页端">
                                手机网页端
                                <span
                                  style={{
                                    color: '#AEB1B5',
                                    paddingLeft: '20px',
                                  }}
                                >
                                  默认尺寸：375*667px
                                </span>
                              </Radio>
                              <br />
                              <Radio value="PC网页端">
                                PC网页端
                                <span
                                  style={{
                                    color: '#AEB1B5',
                                    paddingLeft: '20px',
                                  }}
                                >
                                  默认尺寸：1920*1024px
                                </span>
                              </Radio>
                              <br />
                              <Radio value="其他尺寸">
                                其他尺寸
                                {
                                  getFieldValue('choose_size') === "其他尺寸" && <>
                                    <Form.Item style={{
                                      display: "inline-block", marginLeft: "10px",
                                      "marginTop": "-10px",
                                      "width": "80px"
                                    }}>
                                      {getFieldDecorator('choose_size_other_left', {
                                        rules: [
                                          { required: true, message: '请输入尺寸!' },
                                        ],
                                      })(
                                        <Input
                                          maxLength={20}
                                          className="king_normal_input"
                                          placeholder="请输入"
                                        />,
                                      )}
                                    </Form.Item>
                                    <span>*</span>
                                    <Form.Item style={{
                                      display: "inline-block", marginLeft: "10px",
                                      "marginTop": "-10px",
                                      "width": "80px"
                                    }}>
                                      {getFieldDecorator('choose_size_other_right', {
                                        rules: [
                                          { required: true, message: '请输入尺寸!' },
                                        ],
                                      })(
                                        <Input
                                          maxLength={20}
                                          className="king_normal_input"
                                          placeholder="请输入"
                                        />,
                                      )}
                                    </Form.Item>
                                  </>
                                }
                              </Radio>
                              <br />
                            </Radio.Group>,
                          )}
                        </Form.Item>
                        {getFieldValue('choose_size') === '其他尺寸规格' && (
                          <Form.Item>
                            {getFieldDecorator('size_description', {
                              rules: [
                                { required: true, message: '请输入特殊要求!' },
                              ],
                            })(
                              <Input
                                maxLength={20}
                                className="king_normal_input"
                                placeholder="请输入要求"
                              />,
                            )}
                            <span className="form_right">
                              {(getFieldValue('size_description') &&
                                getFieldValue('size_description').length) ||
                                0}
                              /20
                            </span>
                          </Form.Item>
                        )}
                        <Form.Item label="交付源文件">
                          <div>
                            <Form.Item>
                              {getFieldDecorator('file_format', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选图片格式要求!',
                                  },
                                ],
                              })(
                                <Checkbox.Group>
                                  <Checkbox value="Figma">
                                    Figma
                                    <span
                                      style={{
                                        color: '#AEB1B5',
                                        paddingLeft: '20px',
                                      }}
                                    >
                                      推荐此格式
                                    </span>
                                  </Checkbox>
                                </Checkbox.Group>,
                              )}
                            </Form.Item>
                          </div>
                          <div>
                            <span style={{ color: '#AEB1B5' }}>前端开发交付格式</span>
                            <br />
                            {getFieldDecorator('original_file_format', {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择前端开发交付格式要求!',
                                },
                              ],
                            })(
                              <Radio.Group>
                                <Radio value="蓝湖">蓝湖</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  推荐此格式
                                </span>
                                <br />
                              </Radio.Group>,
                            )}{' '}
                          </div>
                        </Form.Item>
                        <Form.Item label="其他需求">
                          {getFieldDecorator('remark')(
                            <TextArea
                              rows={4}
                              maxLength={100}
                              className="king_normal_text_area"
                              placeholder="还有什么想叮嘱我们的吗"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('remark') &&
                              getFieldValue('remark').length) ||
                              0}
                            /100
                          </span>
                        </Form.Item>
                      </Form>
                    </div>
                    <div>
                      <Tip style={{ marginTop: '46px' }}>
                        · 为您的需求命名，帮助您在需求队列中分辨出不同的需求。
                        <br />· 建议的格式为：产品使用目标+产品形式，如“留学生租房网站” “鲜花速递App”。
                      </Tip>
                      <Tip style={{ marginTop: '70px' }}>
                        · 您的UI中需要出现的所有文案都需要交接给我们。
                      <br />· 建议您向我们详细描述您的UI设计需求，如果有需求文档可以一并上传。
                      <br />· 如有需要用到的图片、源文件等素材也可以一并上传。
                      <br />· 将您的原型图/线框图上传，建议详细标注页面重点内容，层级关系等，便于更精准的UI还原
                      </Tip>
                      <Tip style={{ marginTop: '150px' }}>
                        <span>
                          · 请详细描述您的产品的目标使用人群，描述用户群体可以帮助设计师更好的判断设计风格
                        </span>
                      </Tip>
                      <Tip style={{ marginTop: '150px' }}>
                        <span>
                          · 若您已有设计风格/规范，请您上传您的设计风格/规范提供我们的设计师参考
                        <br />· 若您没有明确的设计风格/规范，您可以选择参考我们的视觉风格图谱，以确定您偏好的视觉风格
                        </span>
                      </Tip>
                      <Tip style={{ marginTop: '150px' }}>
                        <span>
                          · 选择您需要的产品形式（如App，PC网页端等），我们已为您默认选择了此产品形式的最佳交付尺寸，此处支持多选
                        </span>
                      </Tip>
                      <Tip style={{ marginTop: '220px' }}>
                        <span>
                          · 如您对交付源文件有其他格式要求，<span style={{ color: '#DEBB6E' }}>
                            可咨询设计顾问
                          </span>
                        </span>
                      </Tip>
                      <Tip style={{ marginTop: '60px' }}>
                        <span>
                          <span>
                            · 用于工程开发的UI设计完整标注、切图等，会上传至蓝湖平台，交付给您蓝湖链接
                        <br />· 如您对前端开发交付格式有其他要求，<span style={{ color: '#DEBB6E' }}>
                              可咨询设计顾问
                          </span>
                          </span>
                        </span>
                      </Tip>
                    </div>
                  </div>
                </div>
              </HeaderLayout>
              <div className={styles.bottom}>
                <div className={styles.bottom_container}>
                  <div className={styles.bottom_title}>
                    {item && <LittleCard {...item} />}
                  </div>
                  <div className={styles.bottom_right}>
                    <Button
                      onClick={() => this.props.history.goBack()}
                      className={`${styles.bottom_button} normal_button`}
                    >
                      上一步
                    </Button>
                    <Button
                      className={`${styles.bottom_button} linear-gradient-gray normal_button`}
                      style={{ color: '#FFFFFF' }}
                      onClick={this.handleSubmit}
                      type="primary"
                    >
                      下一步
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        }
      </CreateDemandComponent >
    );
  }
}

export const CreateDemandUIForm: any = Form.create({ name: 'create_demand_new' })(
  CreateDemandUI,
);

import io from "socket.io-client";
import { server_uri } from "../config";

const socket = io(server_uri);

let is_login = false;

const rooms = new Set();

socket.on('connect', async () => {
  console.log('Connected');

  await login()
  rooms.forEach((item: any) => {
    joinRoom(item, true);
  })
});
  //       socket.on('events', function(data) {
  //         console.log('event', data);
  //       });
  //       socket.on('exception', function(data) {
  //         console.log('event', data);
  //       });
  //       socket.on('disconnect', function() {
  //         console.log('Disconnected');
  //       });

export async function login() {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    socket.emit('login', { token }, (data: boolean) => {
      is_login = data;
      resolve(data)
    });
  })
}

export async function joinRoom(room_id: string, force = false) {
  if (!force && rooms.has(room_id)) {
    return {
      socket,
      is_login,
      join_result: true
    }
  }
  if (!is_login) {
    await login()
  }
  let join_result = false;
  if (is_login) {
    join_result = await new Promise((resolve) => {
      socket.emit('join-room', { room_id }, (data: boolean) => {
        resolve(data)
      });
    })
  }
  if (join_result) {
    rooms.add(room_id);
  }
  return {
    socket,
    is_login,
    join_result
  }
}

export async function sendMessage(room_id: string, message: string, type: number, file_old_name?: string) {
  return new Promise((resolve) => socket.emit('message', { room_id, message, type, file_old_name }, resolve));
}

export async function initMessage(room_id: string): Promise<any> {
  return new Promise((resolve) => socket.emit('init-message', { room_id }, resolve));
}

export async function loadMoreMessage(room_id: string, cursor: string): Promise<any> {
  return new Promise((resolve) => socket.emit('more-message', { room_id, cursor }, resolve));
}

// socket.on("login-result", (data) => {
//   console.log("拿到 login 结果", data)
//   is_login = data
// })

import React from 'react';
import { Modal, message, Button } from 'antd';
import './we-chat-pay-modal.css';
import 'antd/dist/antd.css';
import QrCode from 'qrcode.react';

import we_chat_pay from './we_chat_pay@2x.png';
import scan from './scan.png';
import { GetOrderByIdDocument } from '../../apollo';
import client from '../../apollo/client';
import styles from './index.module.css';
import success from './success@2x.png';
import { withRouter } from 'react-router';

class WeChatPayModalComponent extends React.Component<any> {
  pollingTime: any;
  state = {
    visible: false,
  };
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.visible && nextProps.id) {
      this.polling();
    }
    // if (nextProps.visible && nextProps.id && nextProps.id !== this.props.id) {
    //   this.polling();
    // }
  }
  polling = async () => {
    clearTimeout(this.pollingTime);
    this.pollingTime = setTimeout(async () => {
      const { data } = await client.query({
        query: GetOrderByIdDocument,
        variables: {
          id: this.props.id,
        },
        fetchPolicy: 'network-only',
      });
      if (data.getOrderById.status === '支付成功') {
        clearTimeout(this.pollingTime);
        message.success('支付成功');
        if (this.props.onCancel) {
          this.props.onCancel();
        }
        this.setState({
          visible: true,
        });
      } else {
        if (this.props.visible) {
          this.polling();
        }
      }
    }, 1000);
  };
  render() {
    const { visible } = this.state;
    return (
      <>
        <Modal
          visible={visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={null}
          title={null}
          closable={false}
          maskClosable={false}
        >
          <div className={styles.modal_container}>
            <img className={styles.success_img} src={success} alt="" />
            <div className={styles.modal_title}>恭喜您支付成功</div>
            <br />
            <Button
              className={`${styles.aggress_sure} normal_button`}
              onClick={() => this.props.history.push('/choose-demand-type')}
            >
              提交需求
            </Button>
            {/* <Button
              className={`linear-gradient-gray ${styles.sure} normal_button`}
              onClick={() => this.props.history.push('/order')}
              type="primary"
            >
              查看充值记录
            </Button>

            <br />
            <br />
            <Button
              className={`${styles.aggress_sure} normal_button`}
              onClick={() => this.props.history.push('/plan')}
            >
              选购服务
            </Button> */}
          </div>
        </Modal>
        <Modal
          className="modal-cover modal-cover-body"
          visible={this.props.visible}
          footer={null}
          closable={false}
          centered
          confirmLoading={false}
          maskClosable={false}
        >
          <div className="we-chat-pay-modal-type-content">
            <div className="we-chat-pay-modal-header-container">
              <img
                className="we-chat-pay-modal-header-img"
                src={we_chat_pay}
                alt=""
              />
            </div>
            <div className="we-chat-pay-middle">
              <div className="we-chat-top">
                <span className="we-chat-pay-text">充值</span>
                <span className="we-chat-pay-amount">¥{this.props.price}</span>
              </div>
              <QrCode value={this.props.uri} size={120} />
              <p className="we-chat-pay-modal-text-second">
                <img className="scan" src={scan} alt="scan" />
                <span>
                  请使用微信扫描
                  <br />
                  二维码以完成支付
                </span>
              </p>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(WeChatPayModalComponent);

import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { AxiosProvider } from 'react-axios';

import './App.css';
import Routers from './router';
import client from './apollo/client';
import { api } from './request';
// import { Alert } from 'antd';

// function isWeiXin() {
//   var ua = window.navigator.userAgent.toLowerCase();
//   // @ts-ignore
//   if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i).toString() === 'micromessenger') {
//     return true;
//   } else {
//     return false;
//   }
// }

const App: React.FC = () => {
  console.log("用户端 版本号1.0.2")
  // const is_wei_xin = isWeiXin();
  // if (is_wei_xin) {
  //   return <div style={{ margin: "0 auto 0", width: "800px", paddingTop: "120px" }}><Alert
  //     message="请您在chrome浏览器中访问 platform.nef.design"
  //     description="因微信内置浏览器的兼容性问题，暂不支持在微信浏览器中访问，请您使用Chrome或其他浏览器"
  //     type="info"
  //     showIcon
  //   />
  //   </div>
  // }
  return (
    <AxiosProvider instance={api}>
      <ApolloProvider client={client}>
        <Routers />
      </ApolloProvider>
    </AxiosProvider>
  );
};

export default App;

import React from 'react';
import empty_icon from './empty@2x.png';
import styles from './empty.module.css';

interface EmptyProps {
    uri: string;
}

export class Empty extends React.Component<EmptyProps> {
    render() {
        const { uri } = this.props;
        return (
            <div className={styles.container}>
                <img className={styles.container_img} src={empty_icon} alt="empty" />
                <br />
                <br />
                <br />
                <p>该文件类型暂不支持预览
                    <br />您可以直接<a download="download.jpg" target="_blank" rel="noopener noreferrer" href={uri} style={{ color: "#DFBD73" }}>
                        下载文件
                    </a></p>
            </div>
        );
    }
}

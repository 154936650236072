import React from 'react';
import { withRouter } from 'react-router-dom';
import { Get } from 'react-axios';

import './login.css';
// import wellcome from './wellcome.png'
import sweep from './sweep.png'
import styles from './login.module.css';
import queryString from 'query-string';

const images = [
  'https://nef-design-public.oss-cn-beijing.aliyuncs.com/bg.1.jpg',
  'https://nef-design-public.oss-cn-beijing.aliyuncs.com/bg.2.jpg',
  'https://nef-design-public.oss-cn-beijing.aliyuncs.com/bg.3.jpg',
  'https://nef-design-public.oss-cn-beijing.aliyuncs.com/bg.4.jpg',
  'https://nef-design-public.oss-cn-beijing.aliyuncs.com/bg.5.jpg'
]

function randomImage(images: Array<string>) {
  const index = Math.round(Math.random() * (images.length - 1));
  return images[index]
}

declare global {
  interface Window {
    WxLogin?: Function
  }
}

class Login extends React.Component<any, any> {
  componentDidMount() {
    sessionStorage.removeItem("is_choose_team")
    const code: any = queryString.parse(window.location.search).code;
    if (code) {
      localStorage.setItem(
        'team_code',
        JSON.stringify({
          created_date: new Date(),
          code,
        }),
      );
    }
  }

  render() {
    const team_id: any = queryString.parse(window.location.search).team_id;
    const code: any = queryString.parse(window.location.search).code;
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.hello_container}>
              <div className={styles.hello}>欢迎使用</div>
              <div className={`${styles.hello} ${styles.logo}`}><span style={{fontFamily:'Futura',fontStyle:'italic'}}>Nef.Design</span> 云设计部</div>
              {/* <img style={{ width: '369px', height: '110px' }} src={wellcome} alt=''></img> */}
              <div className={`${styles.hello_intro}`}>登录云设计部，即可创建账户、管理设计需求</div>
            </div>
            <div>
              <div className={styles.qr_tip}>请微信扫码登陆</div>
              <div className={styles.qr_container_new}>
                <div id="login_container" />
                <div className={styles.qr_bottom_tip}><img src={sweep} style={{ width: '16.76px', height: '14.86px', marginRight: '8.62px', marginBottom: '3px' }} alt=''></img>打开手机微信扫一扫</div>
                <div className={styles.qr}>
                  <Get url={`/we-chat/pc/local/login?team_id=${team_id}&code=${code}`}>
                    {(error: any, response: any, isLoading: any) => {
                      if (error || isLoading || !response) {
                        return '';
                      }
                      const { redirect_uri, scope, appid } = response.data;
                      console.log('redirect_uri', redirect_uri);
                      // // @ts-ignore
                      // console.log('window.WxLogin', WxLogin)
                      // // @ts-ignore
                      // console.log('window.WxLogin', window.WxLogin)
                      // @ts-ignore
                      var obj = new window.WxLogin({
                        self_redirect: false,
                        // self_redirect: true,
                        id: 'login_container',
                        appid,
                        scope,
                        redirect_uri,
                        // state: '',
                        style: 'white',
                        href:
                          'https://nef-design-public.oss-cn-beijing.aliyuncs.com/we-chat.css',
                      });
                      console.log('obj', obj)
                      return <></>;
                      // return <QrCode value={redirect_uri} size={200} />;
                    }}
                  </Get>
                </div>
              </div>
            </div>
            <div className={styles.hello_bottom}>云设计部需求管理系统 4.0</div>
          </div>
          <div className={styles.right}>
            <img src={randomImage(images)} style={{ height: '100%' }} alt=''></img>
          </div>
        </div>
        {/* <div className={styles.qr_container}>
          <div className={styles.qr_tip}>请微信扫码登陆</div>
          <div id="login_container" />
          <div className={styles.qr_bottom_tip}>打开手机微信扫一扫</div>
          <div className={styles.qr}>
            <Get url={`/we-chat/pc/local/login?team_id=${team_id}&code=${code}`}>
              {(error: any, response: any, isLoading: any) => {
                if (error || isLoading || !response) {
                  return '';
                }
                const { redirect_uri, scope, appid } = response.data;
                console.log('redirect_uri', redirect_uri);
                // // @ts-ignore
                // console.log('window.WxLogin', WxLogin)
                // // @ts-ignore
                // console.log('window.WxLogin', window.WxLogin)
                // @ts-ignore
                var obj = new window.WxLogin({
                  self_redirect: false,
                  // self_redirect: true,
                  id: 'login_container',
                  appid,
                  scope,
                  redirect_uri,
                  // state: '',
                  style: 'white',
                  href:
                    'https://nef-design-public.oss-cn-beijing.aliyuncs.com/we-chat.css',
                });
                console.log('obj', obj)
                return <></>;
                // return <QrCode value={redirect_uri} size={200} />;
              }}
            </Get>
          </div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(Login);

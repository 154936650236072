import React from 'react';
import { Button, Form, Input, Radio, Icon, Upload } from 'antd';

import { CreateIndependentComponent } from '../../apollo';
import { message } from '../../components/message';
import SuccessModal from '../../components/success-modal';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import tip from './tip@2x.png';
import { up_load_life_photo_options } from '../../lib/upload';
import { getFilesName } from '../../lib';

const { TextArea } = Input;

class Tip extends React.Component<any> {
  render() {
    const { style } = this.props;
    return (
      <div style={style} className={styles.tip_container}>
        <img className={styles.tip_img} alt="" src={tip} />
        {this.props.children}
      </div>
    );
  }
}

class CreateIndependentDemand extends React.Component<any> {
  state = {
    visible: false,
    uris: [],
  };
  handleSubmit = (send: any, e: any) => {
    e.preventDefault();

    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          const { uris }: any = this.state;
          await send({
            variables: {
              CreateIndependent: {
                ...values,
                description_files: getFilesName(uris, values.description_files),
              },
            },
          });
          // message.success('创建成功');
          const { form } = this.props;
          const { resetFields } = form;
          resetFields();
          this.setState({ visible: true });
          // this.props.history.push('/service');
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { visible, uris } = this.state;
    const uploadButton = (
      <div>
        <Button className="btn_upload">
          <Icon type="plus" />
          {/* <div className="ant-upload-text">上传</div> */}
          上传文件
        </Button>
        <span className="file_desc">
          支持pdf及word，jpg格式，.zip 和 .rar格式，.ppt 和 .pptx
        </span>
      </div>
    );
    return (
      <HeaderLayout>
        <SuccessModal visible={visible} />
        <div className={styles.container}>
          <div className={styles.title}>独立项目-请填写需求详情</div>
          <div style={{ display: 'flex', padding: '30px' }}>
            <div className={styles.left}>
              <Form className="login-form">
                <Form.Item label="需求名称">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: '请输入需求名称!',
                      },
                    ],
                  })(
                    <Input
                      maxLength={20}
                      className="king_normal_input"
                      placeholder="例：双11大促食品类Banner设计"
                    />,
                  )}
                  <span className="form_right">
                    {(getFieldValue('name') && getFieldValue('name').length) ||
                      0}
                    /20
                  </span>
                </Form.Item>
                <Form.Item label="需求描述">
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: '请输入需求描述!' }],
                  })(
                    <TextArea
                      rows={4}
                      maxLength={200}
                      className="king_normal_text_area"
                      placeholder="可填写所需文案及其他特殊要求"
                    />,
                  )}
                  <span className="form_right">
                    {(getFieldValue('description') &&
                      getFieldValue('description').length) ||
                      0}
                    /200
                  </span>
                </Form.Item>
                <div>或上传已有的需求文档，最多可以上传5个文件</div>
                <br />
                <Form.Item>
                  {getFieldDecorator('description_files')(
                    <Upload
                      {...up_load_life_photo_options((uri, file) =>
                        this.setState({
                          uris: [...uris, { [uri]: file.name }],
                        }),
                      )}
                      accept=".pdf,.jpg,.docx,.doc,.rar,.zip,.ppt,.pptx"
                      defaultFileList={
                        getFieldValue('description_files') &&
                        getFieldValue('description_files').fileList
                      }
                    >
                      {((getFieldValue('description_files') &&
                        getFieldValue('description_files').fileList.length <
                          5) ||
                        !getFieldValue('description_files')) &&
                        uploadButton}
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="项目周期">
                  {getFieldDecorator('cycle', {
                    rules: [{ required: true, message: '请输入项目周期!' }],
                  })(<Input className="king_normal_input" />)}
                  <span className="form_right">天</span>
                </Form.Item>
                <Form.Item label="预算范围">
                  {getFieldDecorator('budget', {
                    rules: [{ required: true, message: '请选择预算范围!' }],
                  })(
                    <Radio.Group>
                      <Radio value="5000以下">5000以下</Radio>
                      <br />
                      <Radio value="5000-10000">5000-10000</Radio>
                      <br />
                      <Radio value="10000-50000">10000-50000</Radio>
                      <br />
                      <Radio value="50000以上">50000以上</Radio>
                      <br />
                    </Radio.Group>,
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    className={`${styles.go_back} normal_button`}
                    onClick={() => this.props.history.goBack()}
                  >
                    返回
                  </Button>
                  <CreateIndependentComponent>
                    {(send, { loading }) => {
                      return (
                        <Button
                          className={`linear-gradient-gray ${styles.sure}`}
                          loading={loading}
                          onClick={this.handleSubmit.bind(this, send)}
                          type="primary"
                        >
                          提交
                        </Button>
                      );
                    }}
                  </CreateIndependentComponent>
                </Form.Item>
              </Form>
              <div className={styles.bottom_tip}>
                *提交后，我们将在2个工作日内与您联系
              </div>
            </div>
            <div>
              <Tip style={{ marginTop: '46px' }}>
                需求名称可以帮助我们识别不同的需求
              </Tip>
              <Tip style={{ marginTop: '70px' }}>
                需求描述可以用来较为详细的阐述您的设计目标，所需的文案和其他视觉素材
              </Tip>
              <Tip style={{ marginTop: '120px' }}>
                这可以是您预估的一个项目周期，以方便我们之后进行项目规划
              </Tip>
            </div>
          </div>
        </div>
      </HeaderLayout>
    );
  }
}

export const CreateIndependentDemandForm: any = Form.create({
  name: 'create_independent_demand',
})(CreateIndependentDemand);

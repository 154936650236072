import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Button, Tag, Modal, Row, Col } from 'antd';
import { ViewerTeamComponent, CreateTeamUriComponent } from '../../apollo';
import { message } from '../../components/message';
import copy from 'copy-to-clipboard';
import { team_uri } from '../../config';
import queryString from 'query-string';
import warn from './warn@2x.png';
import { DeleteModal } from '../../components/delete-modal';
import { ChangeTeamOwnerModal } from './change-owner-modal';
import { CreateTeamUriModal } from './create-team-uri';

class Item extends React.Component<any> {
  render() {
    const user_session: any = localStorage.getItem('session');
    const user = JSON.parse(user_session);
    const {
      owner: { id, headimgurl, username, mobile },
      owner_id,
      refetch,
    } = this.props;
    const is_owner = owner_id === user.id;
    return (
      // <div className={`${styles.member_container}`}>
      <Row
        type="flex"
        justify="start"
        gutter={24}
        style={{
          marginTop: '20px',
          lineHeight: '40px',
        }}
      >
        <Col span={6} style={{ position: 'relative' }}>
          <div className={`${styles.header_img} header_img_container hand`}>
            <img className="header_img" src={headimgurl} alt="" />
          </div>
        </Col>
        <Col span={6} style={{ position: 'relative' }}>
          <div className={styles.member_username}>
            {username}
            {(user.user_id === id || user.id === id) && (
              <Tag style={{ marginLeft: '12px' }} color="#7CB2B8">
                我
              </Tag>
            )}
          </div>
        </Col>
        <Col span={4} style={{ position: 'relative' }}>
          <div className={styles.member_mobile}>{mobile}</div>
        </Col>
        <Col span={4} style={{ position: 'relative' }}>
          {/* <div className={styles.member_created_date}>
            {moment(created_date).format('YYYY-MM-DD')}加入
          </div> */}
          {id === owner_id ? '管理员' : '成员'}
        </Col>
        {is_owner && (
          <Col span={4} style={{ position: 'relative' }}>
            {id === owner_id ? (
              <ChangeTeamOwnerModal refetch={refetch} />
            ) : (
              <DeleteModal user={this.props.owner} refetch={refetch} />
            )}
          </Col>
        )}
      </Row>
      // </div>
    );
  }
}
// class Item extends React.Component<any> {
//   render() {
//     const {
//       owner: { id, headimgurl, username, mobile },
//       created_date,
//       owner_id,
//       refetch,
//     } = this.props;
//     return (
//       <div className={`${styles.member_container}`}>
//         <div className={`${styles.header_img} header_img_container hand`}>
//           <img className="header_img" src={headimgurl} alt="" />
//         </div>
//         <div className={styles.member_username}>
//           {username}
//           {id === owner_id ? (
//             <Tag style={{ marginLeft: '12px' }} color="#7CB2B8">
//               管理员
//             </Tag>
//           ) : (
//             <DeleteModal user={this.props.owner} refetch={refetch} />
//           )}
//         </div>
//         <div className={styles.member_mobile}>{mobile}</div>
//         <div className={styles.member_created_date}>
//           {moment(created_date).format('YYYY-MM-DD')}加入
//         </div>
//       </div>
//     );
//   }
// }

// function filterSubscriptions(subscriptions: any[]) {
//   let new_subscriptions;
//   subscriptions = subscriptions.filter(subscription => {
//     if (new Date(subscription.end_date) > new Date()) {
//       return true;
//     }
//     // if (
//     //   new Date(subscription.end_date) > new Date() &&
//     //   new Date(subscription.start_date) < new Date()
//     // ) {
//     //   return true;
//     // }
//     return false;
//   });
//   const first_subscriptions = subscriptions
//     .filter(subscription => {
//       if (
//         subscription.name === '基础型设计部' &&
//         subscription.cycle_name !== '后台更新'
//       ) {
//         return true;
//       }
//       return false;
//     })
//     .sort(
//       (item1, item2) =>
//         new Date(item1.end_date).getTime() - new Date(item2.end_date).getTime(),
//     );
//   const second_subscriptions = subscriptions
//     .filter(subscription => {
//       if (
//         subscription.name === '业务型设计部' &&
//         subscription.cycle_name !== '后台更新'
//       ) {
//         return true;
//       }
//       return false;
//     })
//     .sort(
//       (item1, item2) =>
//         new Date(item1.end_date).getTime() - new Date(item2.end_date).getTime(),
//     );
//   const first_subscription = first_subscriptions[0];
//   const second_subscription = second_subscriptions[0];
//   if (first_subscription && second_subscription) {
//     if (
//       new Date(first_subscription.start_date) <
//       new Date(second_subscription.start_date)
//     ) {
//       new_subscriptions = [first_subscription, second_subscription];
//     } else {
//       new_subscriptions = [second_subscription, first_subscription];
//     }
//   } else {
//     new_subscriptions = [first_subscription || second_subscription];
//   }
//   return new_subscriptions;
// }

class Account extends React.Component<any> {
  state = {
    visible: false,
    warn_visible: false,
  };

  onCopy = (text: string) => {
    if (copy(text)) {
      message.success('复制成功');
    } else {
      message.error(new Error('复制成功'));
    }
  };

  add = (count: number) => {
    if (count >= 10) {
      return this.setState({
        warn_visible: true,
      });
    }
    this.setState({
      visible: true,
    });
  };

  render() {
    const { visible, warn_visible } = this.state;
    return (
      <HeaderLayout>
        <Modal
          visible={visible}
          className={`modal-cover modal-cover-body`}
          width={848}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.modal_title}>发送链接邀请</div>
            <div className={styles.modal_desc}>邀请链接将在30分钟后失效</div>
            {visible && (
              <CreateTeamUriComponent>
                {({ data, error, loading }) => {
                  if (error || loading || !data) {
                    return '';
                  }
                  const uri =
                    team_uri +
                    '?' +
                    queryString.stringify({ code: data.createTeamUri.uri });
                  return (
                    <div>
                      <input
                        className={styles.detail_uri}
                        type="text"
                        name="uri"
                        value={uri}
                        readOnly
                      />
                      <Button
                        className={`linear-gradient-gray`}
                        type="primary"
                        onClick={() => this.onCopy(uri)}
                      >
                        复制
                      </Button>
                    </div>
                  );
                }}
              </CreateTeamUriComponent>
            )}
            <p className={styles.copy_content}>
              请将复制以下链接，将其发送给受邀人，受邀人成为成员后，将有以下权限：
              <br />
              1、一个团队的成员将共享团队的订阅计划
              <br />
              2、成员能够查看到团队所有的需求和进度
            </p>
          </div>
        </Modal>
        <Modal
          visible={warn_visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={() =>
            this.setState({
              warn_visible: false,
            })
          }
          footer={null}
          title={null}
        >
          <div className={styles.out_container}>
            <img className={styles.out_img} src={warn} alt="" />
            <div className={styles.out_title}>团队成员已达上限</div>
            <div className={styles.out_desc}>
              在现有的系统中，每个企业最多可以拥有5名成员
            </div>
            <Button
              className={`linear-gradient-gray ${styles.sure} normal_button`}
              onClick={() =>
                this.setState({
                  warn_visible: false,
                })
              }
              type="primary"
            >
              我知道了
            </Button>
          </div>
        </Modal>
        <ViewerTeamComponent>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            const team = data.viewer.team;
            return (
              <>
                <div style={{ position: 'relative' }}>
                  <div className={styles.title}>团队管理</div>
                  <div className={styles.desc}>可以对成员列表进行管理</div>
                  {/* <Button
                    className={`linear-gradient-gray ${styles.next}`}
                    onClick={this.add.bind(
                      this,
                      (team && team.members.length) || 0,
                    )}
                    type="primary"
                  >
                    <span style={{ fontSize: '20px' }}> + &nbsp;</span> 邀请成员
                  </Button> */}
                  <CreateTeamUriModal team={team} />
                </div>
                <div className={styles.container}>
                  <div>
                    {/* {team && team.owner.id === data.viewer.id && ( */}
                    {team && (
                      <div className={styles.member}>
                        <div className={styles.member_top}>
                          <p className={styles.member_title}>
                            成员管理（
                            {(team && team.members.length) || 0}
                            /10）
                          </p>
                          {/* <Button
                          className={`${styles.member_add} normal_button`}
                          onClick={this.add.bind(
                            this,
                            team && team.members.length,
                          )}
                        >
                          邀请成员
                        </Button> */}
                        </div>
                        <Row
                          type="flex"
                          justify="start"
                          gutter={24}
                          style={{
                            marginTop: '30px',
                            backgroundColor: '#EFEFEF',
                            padding: '6px 0',
                          }}
                        >
                          <Col span={6} style={{ position: 'relative' }}>
                            团队成员
                          </Col>
                          <Col span={6} style={{ position: 'relative' }}>
                            加入时间
                          </Col>
                          <Col span={4} style={{ position: 'relative' }}>
                            手机号
                          </Col>
                          <Col span={4} style={{ position: 'relative' }}>
                            角色
                          </Col>
                          <Col span={4} style={{ position: 'relative' }}>
                            操作
                          </Col>
                        </Row>
                        {team &&
                          team.members.map(member => {
                            return (
                              <Item
                                {...member}
                                owner_id={team && team.owner.id}
                                key={member.id}
                                refetch={refetch}
                              />
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </ViewerTeamComponent>
      </HeaderLayout>
    );
  }
}

export default Account;

import React from 'react';
import { Modal, Button, Radio } from 'antd';

import styles from './index.module.css';
import { ChangeTeamOwnerComponent, ViewerTeamComponent } from '../../../apollo';
import { message } from '../../../components/message';
import { withRouter } from 'react-router';

class Item extends React.Component<any> {
  render() {
    const {
      owner: { headimgurl, username, mobile },
    } = this.props;
    return (
      <div className={styles.item_container}>
        <div style={{ display: 'flex' }}>
          <div className={`${styles.header_img} header_img_container hand`}>
            <img className="header_img" src={headimgurl} alt="" />
          </div>
          <div className={styles.member_username}>
            {username}
            <br />
            <span className={styles.member_mobile}>{mobile}</span>
          </div>
          <div className={styles.member_desc}>成员</div>
        </div>
      </div>
    );
  }
}

export class ChangeTeamOwnerModalComponent extends React.Component<any> {
  state = {
    visible: false,
    value: '',
  };

  changeTeamOwner = async (send: any) => {
    if (!this.state.value) {
      return message.error(new Error('请先选择团队成员'));
    }
    try {
      await send({
        variables: {
          user_id: this.state.value,
        },
      });
      this.props.refetch();
      this.setState({
        visible: false,
      });
      message.success('移交成功');
      this.props.history.push('/account');
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const { visible } = this.state;
    // const { headimgurl, username } = this.props.user;
    return (
      <>
        <span
          className={`hand ${styles.delete_button}`}
          onClick={() => this.setState({ visible: true })}
        >
          移交管理员权限
        </span>
        <Modal
          visible={visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.modal_title}>移交管理权限</div>
            <div className={styles.modal_desc}>
              请从下列成员中选择新的管理员 <br />
              一旦移交成功，您将失去管理员权限
            </div>
            <ViewerTeamComponent>
              {({ data, error, loading, refetch }) => {
                if (error || loading || !data) {
                  return '';
                }
                const team = data.viewer.team;
                if (!team) {
                  return '';
                }
                team.members = team.members.filter(
                  ({ owner }) => owner && owner.id !== team.owner.id,
                );
                return (
                  <div className={styles.middle_container}>
                    <Radio.Group
                      onChange={e =>
                        this.setState({
                          value: e.target.value,
                        })
                      }
                      value={this.state.value}
                    >
                      {team.owner.id === data.viewer.id && (
                        <div className={styles.member}>
                          {team.members.map(member => {
                            return (
                              <Radio
                                key={member.id}
                                value={member.owner && member.owner.id}
                                style={{ display: 'block' }}
                              >
                                <Item
                                  {...member}
                                  owner_id={team.owner.id}
                                  refetch={refetch}
                                />
                              </Radio>
                            );
                          })}
                        </div>
                      )}
                    </Radio.Group>
                  </div>
                );
              }}
            </ViewerTeamComponent>
            <ChangeTeamOwnerComponent>
              {send => {
                return (
                  <Button
                    className={`linear-gradient-gray ${styles.sure} normal_button`}
                    onClick={this.changeTeamOwner.bind(this, send)}
                    type="primary"
                  >
                    确认移交
                  </Button>
                );
              }}
            </ChangeTeamOwnerComponent>

            <br />
            <br />
            <Button
              className={`${styles.aggress_sure} normal_button`}
              onClick={() => this.setState({ visible: false })}
            >
              取消
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export const ChangeTeamOwnerModal = withRouter(ChangeTeamOwnerModalComponent);

import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import BodyWithHeader from '../../components/body-with-header';
import create from './create@2x.png';
import join from './join@2x.png';
import styles from './index.module.css';

interface TeamTypeCardProps {
  src: string;
  title: string;
  desc: any;
  to: string;
  buttonText:string;
}

class TeamCard extends React.Component<TeamTypeCardProps> {
  render() {
    return (
      <div className={styles.card_container}>
        <img alt="" className={styles.card_img} src={this.props.src} />
        <div className={styles.card_title}>{this.props.title}</div>
        <div className={styles.card_desc}>{this.props.desc}</div>
        {this.props.to.includes('http') ? (
          <a href={this.props.to} target="view_window">
            <Button className={styles.card_button}>{this.props.buttonText}</Button>
          </a>
        ) : (
          <Link to={this.props.to}>
            <Button className={styles.card_button}>{this.props.buttonText}</Button>
          </Link>
        )}
      </div>
    );
  }
}

class CreateTeamModal extends React.Component<any> {

  render() {
    return (
      <BodyWithHeader
      title="欢迎使用Nef.Design云设计部"
      desc='您可以创建新团队，也可以加入已有团队'
      >
        <Row style={{ "display": "flex", "justifyContent": "center" ,"marginTop":'32px'}} gutter={16}>
          <Col>
            <TeamCard
              src={create}
              title="创建"
              desc={
                <div>
                  创建一个新的团队  <br />
                  新的团队名、成员及新的订阅计划、订单 
                   <br />
                  各团队的需求、订单、成员不互通
                </div>
              }
              buttonText="创建团队"
              to="/create-team"
            />
          </Col>
          <Col>
          <TeamCard
              src={join}
              title="加入"
              desc={
                <div>
                  如您的公司已订阅服务 <br />
                  可以加入到您公司的团队中，进行
                  <br />
                  协作 共同使用云设计部服务 
                </div>
              }
              buttonText="加入已有的团队"
              to="/team-tip"
            />
          </Col>
        </Row >
      </BodyWithHeader>
    );
  }
}

export const CreateOrChooseTeamModal: any = Form.create({ name: 'create_team' })(
  CreateTeamModal,
);

import React from 'react';
import queryString from 'query-string';

import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import ali from './ali@2x.png';
import wechat from './wechat@2x.png';
import bank from './bank@2x.png';
import { Row, Col, Result } from 'antd';
import { GetOrderByIdComponent, SignProductOrderComponent } from '../../apollo';
import PayResultModal from '../../components/pay-result-modal';
import WeChatPayModalComponent from '../../components/we-chat-pay-modal-order';
import BankPayModal from '../../components/bank-pay-modal';

class PayOrder extends React.Component<any> {
  state = {
    result_modal: false,
    we_chat_modal: false,
    bank_modal: false,
    uri: '',
  };

  pay = async (send: any, pay_type: string, order_id: string) => {
    const { data } = await send({
      variables: {
        SignProductOrder: {
          pay_type,
          order_id,
        },
      },
    });
    const uri = data.signProductOrder.uri;
    if (pay_type === '支付宝') {
      this.setState({ result_modal: true, uri });
      window.open(uri);
    } else if (pay_type === '微信') {
      this.setState({
        we_chat_modal: true,
        uri,
      });
    }
  };

  render() {
    const search = queryString.parse(window.location.search);
    if (!search.order_id) {
      return '参数错误';
    }
    return (
      <div style={{ position: 'relative' }}>
        <HeaderLayout>
          <div className={styles.title}>为您的订单付款</div>
          <div className={styles.desc}>
            如有优惠或超规需求，请先联系设计顾问调整订单价格
          </div>
          <GetOrderByIdComponent variables={{ id: String(search.order_id) }}>
            {({ data, error }) => {
              if (!data || !data.getOrderById) {
                return '';
              }
              const order = data.getOrderById;
              if (order.status === '已支付') {
                return (
                  <Result
                    status="success"
                    title="订单已经支付过了"
                    subTitle="如果你需要再次购买，请重新下单"
                  />
                );
              }
              if (order.status === '待审核') {
                return (
                  <Result
                    status="success"
                    title="订单正在审核中"
                    subTitle="如有任何疑问可联系您的设计顾问"
                  />
                );
              }
              return (
                <div className={styles.card_container}>
                  <PayResultModal
                    visible={this.state.result_modal}
                    open_uri={this.state.uri}
                    onCancel={() => this.setState({ result_modal: false })}
                  />
                  <WeChatPayModalComponent
                    visible={this.state.we_chat_modal}
                    uri={this.state.uri}
                    id={order.id}
                    price={order.price}
                    onCancel={() => this.setState({ we_chat_modal: false })}
                  />
                  <BankPayModal
                    id={order.id}
                    price={order.price}
                    visible={this.state.bank_modal}
                    onCancel={() => this.setState({ bank_modal: false })}
                  />
                  <div>
                    <Row
                      type="flex"
                      justify="start"
                      gutter={24}
                      style={{
                        marginTop: '30px',
                        backgroundColor: '#EFEFEF',
                        padding: '6px 0',
                      }}
                    >
                      <Col span={6} style={{ position: 'relative' }}>
                        服务类型
                      </Col>
                      <Col span={12} style={{ position: 'relative' }}>
                        明细
                      </Col>
                      <Col span={6} style={{ position: 'relative' }}>
                        金额
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      justify="start"
                      gutter={24}
                      className={styles.row}
                    >
                      <Col span={6} style={{ position: 'relative' }}>
                        {order.type}
                      </Col>
                      <Col span={12} style={{ position: 'relative' }}>
                        {order.detail}
                      </Col>
                      <Col span={6} style={{ position: 'relative' }}>
                        ¥{order.price}
                      </Col>
                    </Row>
                    {order.items &&
                      order.items.map(item => {
                        return (
                          <Row
                            type="flex"
                            justify="start"
                            gutter={24}
                            className={styles.row}
                            key={item.id}
                          >
                            <Col span={6} style={{ position: 'relative' }}>
                              {item.type}
                            </Col>
                            <Col span={12} style={{ position: 'relative' }}>
                              {item.detail}
                            </Col>
                            <Col span={6} style={{ position: 'relative' }}>
                              {item.order_type ? '+' : '-'}¥{item.price}
                            </Col>
                          </Row>
                        );
                      })}
                  </div>
                  <div className={styles.total}>
                    总计{' '}
                    <span style={{ color: '#7CB2B8' }}>{order.price}元</span>
                    ，请选择一种付款方式
                  </div>
                  <SignProductOrderComponent>
                    {send => {
                      return (
                        <div className={styles.pay_bottom}>
                          <div
                            className={`${styles.pay_container} hand`}
                            onClick={() => this.pay(send, '支付宝', order.id)}
                          >
                            <img
                              src={ali}
                              alt="支付宝"
                              className={styles.pay_image}
                            />
                            支付宝
                          </div>
                          <div
                            className={`${styles.pay_container} hand`}
                            onClick={() => this.pay(send, '微信', order.id)}
                          >
                            <img
                              src={wechat}
                              alt="微信"
                              className={styles.pay_image}
                            />
                            微信
                          </div>
                          <div
                            className={`${styles.pay_container} hand`}
                            onClick={() => this.setState({ bank_modal: true })}
                          >
                            <img
                              src={bank}
                              alt="对公账户"
                              className={styles.pay_image}
                            />
                            对公账户
                          </div>
                        </div>
                      );
                    }}
                  </SignProductOrderComponent>{' '}
                </div>
              );
            }}
          </GetOrderByIdComponent>
        </HeaderLayout>
      </div>
    );
  }
}

export default PayOrder;

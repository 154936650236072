import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Popover, Modal, Icon, Input, Tabs, Tag } from 'antd';
import tip from '../create-demand/tip@2x.png';
import {
  GetDemandByIdComponent,
  TopDemandByOwnerComponent,
  AnnexGroupListComponent
} from '../../apollo';
import queryString from 'query-string';
import moment from 'moment';
import { message } from '../../components/message';
import { getFileName, getFileNameByPath, getTrueName } from '../../lib';
import CancelDemandModal from '../../components/cancel-demand-modal';
import { DemandChat } from './chat';
import { FinishDemandModalForm } from './finish-demand-modal-form';
import change from './cat@2x.png'
import download from './download@2x.png'
import group from './group@2x.png'
import { Link } from 'react-router-dom';
import { getFileType, file_types } from '../photo'


const { TabPane } = Tabs;
interface OwnFileCardProps {
  uri: string;
  name: string;
}
class OwnFileCard extends React.Component<OwnFileCardProps> {
  render() {
    const { uri, name } = this.props;
    return (
      <a
        target="view_window"
        href={uri}
        style={{ color: '#7CB2B8', marginRight: '28px' }}
      >
        <Icon type="paper-clip" /> {getTrueName(name).replace('files/', '')}
      </a>
    );
  }
}

interface FileCardProps {
  uri: string;
  to?: string;
  file_old_name?: string;
  onClick?: any;
}
export class FileCard extends React.Component<FileCardProps> {
  state = {
    visible: false
  }
  render() {
    const { uri, file_old_name } = this.props;
    return (
      <div className={styles.file_card} onClick={this.props.onClick}>
        <Modal
          visible={this.state.visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          footer={null}
          title={null}
          onCancel={() => this.setState({ visible: false })}
          closable={true}
          maskClosable={true}
        >
          <img src={file_types.includes(getFileType(uri)) ? uri : group} alt="" />
        </Modal>
        <img src={file_types.includes(getFileType(uri)) ? uri : group} alt="" />
        <div>
          <div className={styles.file_title}>{file_old_name ? file_old_name : getFileNameByPath(uri)}</div>
          {/* <div className={styles.file_mb}>1.2 MB</div> */}
        </div>
        <div className={styles.file_card_icon}>
          {this.props.to ? <Link to={this.props.to}><img alt="" src={change} /></Link> : <img alt="" src={change} onClick={() => this.setState({ visible: true })} />}
          <a download="download.jpg" target="_blank" rel="noopener noreferrer" href={uri}>
            <img alt="" src={download} />
          </a>
        </div>
      </div>
    );
  }
}

interface RightDetailProps {
  title: string;
  content?: string | null;
}

class RightDetail extends React.Component<RightDetailProps> {
  render() {
    const { title, content } = this.props;
    return (
      <>
        <div className={styles.detail_title}>{title}</div>
        {/* <p className={styles.detail_content}>{content} </p> */}
        <Input.TextArea
          name="note"
          autosize={true}
          disabled
          className={`${styles.note} ${styles.detail_content}`}
        >
          {content}
        </Input.TextArea>
        {/* <textarea name="note" disabled className={`${styles.note} ${styles.detail_content}`}>
          {content}
        </textarea> */}
      </>
    );
  }
}

export function StatusTag({ status, is_new_ui, team_status, type }: { status: string, is_new_ui: boolean | null | undefined, team_status?: string, type?: string }) {
  let tagStyle = {
    background: '#F8FFF1',
    border: '1px solid #8CCC4E',
    borderRadius: '4px',
    fontFamily: 'PingFang SC',
    // fontStyle: 'normal',
    // fontWeight: 400,
    // fontSize: '14px',
    // lineHeight: '14px',
    color: '#8CCC4E',
    // width: '65px',
    // height: '25px'
  }
  let text = status;
  if (status === '等待中') {
    tagStyle.background = '#FFF8EA'
    tagStyle.border = '1px solid #DEBB6E'
    tagStyle.color = '#DEBB6E'
  }
  if (status === '待确认') {
    tagStyle.background = '#FFF2F1'
    tagStyle.border = '1px solid #F67369'
    tagStyle.color = '#F67369'
  }
  if (status === '已完成') {
    // tagStyle.background = '#FFF8EA'
    // tagStyle.border = '1px solid #DEBB6E'
    // tagStyle.color = '#DEBB6E'
  }
  if (status === '已取消') {
    tagStyle.background = '#F7F8FA'
    tagStyle.border = '1px solid #9FA8B4'
    tagStyle.color = '#9FA8B4'
  }
  if ( status === '进行中') {
    // tagStyle.background = '#FFF8EA'
    // tagStyle.border = '1px solid #DEBB6E'
    // tagStyle.color = '#DEBB6E'
  }
  if ((status === "进行中" || status === '等待中' ) && team_status === "待付款" && type !== "独立项目") {
    tagStyle.background = '#FFF2F1'
    tagStyle.border = '1px solid #F67369'
    tagStyle.color = '#F67369'
    text = team_status
  } else if (team_status === "待续费" || team_status === "待付款") {
    tagStyle.background = '#FFF2F1'
    tagStyle.border = '1px solid #F67369'
    tagStyle.color = '#F67369'
    text = team_status
  }

  return <Tag style={
    {
      ...tagStyle
    }
  }>{text}</Tag>
}
// export function StatusTag({ status, is_new_ui, team_status, type }: { status: string, is_new_ui: boolean | null | undefined, team_status?: string, type?: string }) {
//   let color = 'geekblue';
//   let text = status;
//   if (status === '等待中') {
//     color = 'red';
//   }
//   if (status === '已完成') {
//     color = 'green';
//   }
//   if (status === '已取消') {
//     color = 'gray';
//   }
//   // if (is_new) {
//   //   // color = "#F67369"
//   //   color = 'red';
//   // }
//   if (is_new_ui && status !== '已完成' && status !== "已取消") {
//     color = 'red';
//     text = "待反馈"
//   }
//   if (status === "进行中" && !is_new_ui) {
//     color = 'geekblue';
//     text = "设计中"
//   }
//   if ((status === "进行中" || status === '等待中') && team_status === "待付款" && type !== "独立项目") {
//     color = "#1890FF"
//     text = team_status
//   } else if (team_status === "待续费" || team_status === "待付款") {
//     color = "#F67369"
//     text = team_status
//   }
//   return <Tag color={color}>{text}</Tag>
// }

function ItemTitle({ name }: { name: string }) {
  return <div style={{ marginTop: "6px" }}><span className="status-point" /><span className="little_title">{name}：</span></div>
}

class ChatDetail extends React.Component<any> {
  render() {
    const search: any = queryString.parse(window.location.search);
    return (
      <HeaderLayout>
        <div className="go_back" onClick={() => this.props.history.goBack()}>
          <Icon type="left-circle" />&nbsp;返回上层
        </div>
        <GetDemandByIdComponent variables={{ id: search.id }}>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            const demand = data.getDemandById;
            return (
              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.left_first}>
                    <div style={{ position: 'relative' }}>
                      <div>{demand.project.title} <StatusTag status={demand.status} is_new_ui={demand.is_new_ui} type={demand.type} /></div>
                    </div>
                    <div className={styles.left_title}>{demand.name}</div>
                    {demand.status === '等待中' && (
                      <Popover
                        content={
                          <div>
                            <TopDemandByOwnerComponent>
                              {(send) => {
                                return (
                                  <div
                                    className={`hand`}
                                    onClick={async () => {
                                      try {
                                        await send({
                                          variables: { id: demand.id },
                                        });
                                        message.success('修改成功');
                                        refetch();
                                      } catch (e) {
                                        message.error(e);
                                      }
                                    }}
                                  >
                                    置顶需求
                                  </div>
                                );
                              }}
                            </TopDemandByOwnerComponent>
                            <CancelDemandModal
                              id={demand.id}
                              callback={() => refetch()}
                            />
                            {/* <CancelDemandByOwnerComponent>
                              {send => {
                                return (
                                  <div
                                    className={`hand`}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '您确定取消需求吗？',
                                        content: '需求一旦取消则不可以恢复',
                                        okText: '确认取消',
                                        cancelText: '稍后再说',
                                        onOk: async () => {
                                          try {
                                            await send({
                                              variables: { id: demand.id },
                                            });
                                            message.success('修改成功');
                                            refetch();
                                          } catch (e) {
                                            message.error(e);
                                          }
                                        },
                                      });
                                    }}
                                  >
                                    取消需求
                                  </div>
                                );
                              }}
                            </CancelDemandByOwnerComponent> */}
                          </div>
                        }
                        placement="bottomRight"
                        title={null}
                        trigger="click"
                      >
                        <div className={`${styles.left_menu} hand`}>...</div>
                      </Popover>
                    )}
                    {demand.status === '进行中' && (
                      <FinishDemandModalForm id={demand.id} refetch={refetch} />
                    )}
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={`需求详情`}
                        key="1"
                      >
                        <ItemTitle name="需求描述" />
                        <div className={styles.left_first_middle}>
                          <div>
                            <div className={styles.user_name}>
                              <img
                                className="header_img_card"
                                src={demand.owner.headimgurl || ''}
                                alt=""
                              />
                              &nbsp;{demand.owner.username}
                              <br />
                              <span style={{ paddingTop: "8px", display: "inline-block" }}>创建于 &nbsp;&nbsp;{moment(demand.created_date).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')}</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div>
                          {demand.logo_name ? (
                            <RightDetail
                              title="Logo 需要体现的寓意，包含的要素等"
                              content={demand.description}
                            />
                          ) : (
                            <RightDetail
                              title="需求描述"
                              content={demand.description}
                            />
                          )}
                          {demand.description_files &&
                            demand.description_files.map(({ name, uri }) => {
                              return <OwnFileCard key={uri} name={name} uri={uri} />;
                            })}
                          {demand.description_files && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                          {demand.client && (
                            <RightDetail
                              title="用户群体"
                              content={demand.client}
                            />
                          )}
                          {demand.client_desc && (
                            <RightDetail
                              title="用户群体描述"
                              content={demand.client_desc}
                            />
                          )}
                          {demand.logo_name && (
                            <RightDetail
                              title="Logo 对应的中英文品牌名称"
                              content={demand.logo_name}
                            />
                          )}
                          {demand.logo_type && (
                            <RightDetail
                              title="Logo 类型"
                              content={demand.logo_type}
                            />
                          )}
                          <RightDetail
                            title="视觉风格描述"
                            content={demand.ui_description}
                          />
                          {demand.ui_description_files &&
                            demand.ui_description_files.map(({ name, uri }) => {
                              return <OwnFileCard key={uri} name={name} uri={uri} />;
                            })}
                          {demand.ui_description_files && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                          {demand.ui_description_remark && (
                            <RightDetail
                              title="设计风格备注"
                              content={demand.ui_description_remark}
                            />
                          )}
                          {demand.color_description && (<RightDetail
                            title="色彩偏好"
                            content={demand.color_description || '无'}
                          />)}
                          <RightDetail
                            title="尺寸规格"
                            content={demand.size_description || '无特殊要求'}
                          />
                          {demand.size_description_files &&
                            demand.size_description_files.map(({ name, uri }) => {
                              return <OwnFileCard key={uri} name={name} uri={uri} />;
                            })}
                          {demand.size_description_files && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                          <RightDetail
                            title="源文件格式要求"
                            content={demand.original_file_format || '无特殊要求'}
                          />
                          <RightDetail
                            title="图片格式要求"
                            content={demand.file_format || '无特殊要求'}
                          />
                          <RightDetail
                            title="其他要求说明与备注"
                            content={demand.remark || '无'}
                          />
                        </div>
                      </TabPane>
                      <TabPane
                        tab={`设计稿`}
                        key="2"
                      >
                        <AnnexGroupListComponent variables={{ id: search.id }}>
                          {({ data, error, loading, refetch }) => {
                            if (error || loading || !data) {
                              return '';
                            }
                            return (<div className={styles.right_container}>
                              {data.annexGroupList.map(({ date, group_id, items }) => {
                                return <><Link to={`/photo?id=${search.id}&group_id=${group_id}`}><ItemTitle name={`${moment(Number(date)).format("YYYY年MM月DD日 HH:mm")}交付`} /></Link>
                                  {
                                    items.map((item) => <FileCard file_old_name={item.file_old_name} uri={item.uri} to={`/photo?id=${search.id}&group_id=${group_id}`} />)
                                  }
                                </>
                              })}
                            </div>)
                          }}
                        </AnnexGroupListComponent>
                      </TabPane>
                    </Tabs>
                  </div>
                  {demand.finish_remark && (
                    <div className={styles.left_second}>
                      <div>
                        <img className={styles.tip_img} alt="" src={tip} />
                        交付备注
                      </div>
                      <div className={styles.tip_text}>
                        交付物备注：{demand.finish_remark}
                      </div>
                    </div>
                  )}
                  <div style={{ width: "303px" }}>
                    {demand.more && <div key={demand.more.id}>
                      <br />
                      <img className={styles.tip_img} alt="" src={tip} />
                      设计师补充
                      <p style={{ marginTop: "6px" }}>{demand.more.context}</p>
                      {demand.more.oss_uris.map(({ uri, oss_uri }) => {
                        return <a download={uri} target="_blank" rel="noopener noreferrer" href={oss_uri}>
                          {getFileName(uri)}
                        </a>
                      })}
                    </div>
                    }
                  </div>
                </div>
                <div className={styles.right}>
                  <DemandChat room_id={search.id} history={this.props.history} />
                </div>
              </div>
            );
          }}
        </GetDemandByIdComponent>
      </HeaderLayout>
    );
  }
}

export default ChatDetail;

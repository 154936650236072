import * as ReactApollo from "react-apollo";
import * as React from "react";
import gql from "graphql-tag";

export type Maybe<T> = T | null;

export interface OrderFilterOrder {
  created_date?: Maybe<string>;
}

export interface UsersFilterOrder {
  created_date?: Maybe<string>;
}

export interface PlanRecordFilterOrder {
  created_date?: Maybe<string>;
}

export interface DemandFilterOrder {
  created_date?: Maybe<string>;

  finish_date?: Maybe<string>;
}

export interface NoticeFilterOrder {
  created_date?: Maybe<string>;
}

export interface TeamFilterOrder {
  created_date?: Maybe<string>;

  name?: Maybe<string>;

  plan_end_date?: Maybe<string>;

  balance?: Maybe<string>;
}

export interface ProjectFilterOrder {
  created_date?: Maybe<string>;
}

export interface MemberFilterOrder {
  created_date?: Maybe<string>;
}

export interface IndependentFilterOrder {
  created_date?: Maybe<string>;
}

export interface PackageFilterOrder {
  created_date?: Maybe<string>;
}

export interface SubscriptionFilterOrder {
  created_date?: Maybe<string>;
}

export interface OrderItemFilterOrder {
  created_date?: Maybe<string>;
}

export interface AnnexFilterOrder {
  created_date?: Maybe<string>;
}

export interface AnnotationFilterOrder {
  created_date?: Maybe<string>;
}

export interface DemandMoreFilterOrder {
  created_date?: Maybe<string>;
}

export interface LoginByAdmin {
  mobile: string;

  password: string;
}

export interface LoginByUi {
  mobile: string;

  password: string;
}

export interface CreateDesigner {
  username: string;

  password: string;

  mobile: string;
}

export interface CreateProductOrder {
  product_name: string;

  demand_id: string;
}

export interface CreateSubscriptionOrder {
  subscription_name: string;

  cycle: number;

  price: number;
  /** 月 季度 年 */
  cycle_name: string;
}

export interface UpdateSubscriptionByOrder {
  name: string;

  number: number;

  pay_type: string;
}

export interface SignProductOrder {
  order_id: string;

  pay_type: string;
}

export interface UploadOrderImage {
  pay_uri: string;

  order_id: string;
}

export interface ChangeOrderStatus {
  order_id: string;
}

export interface NotPassOrder {
  reason: string;

  order_id: string;
}

export interface CreatePlanRecord {
  name: string;

  number: number;

  cycle: number;

  pay_type: string;

  cycle_name: string;

  plan_type: string;
}

export interface UpdateSubscription {
  name: string;

  number: number;

  pay_type: string;
}

export interface CreateDemand {
  name: string;
  /** 项目名称 */
  project_name: string;
  /** 需求描述 */
  description: string;
  /** 需求描述文件 */
  description_files?: Maybe<string>;
  /** 视觉风格描述 */
  ui_description?: Maybe<string>;
  /** 视觉风格描述文件 */
  ui_description_files?: Maybe<string>;
  /** 用户群体 */
  client?: Maybe<string>;
  /** 用户群体描述 */
  client_desc?: Maybe<string>;
  /** 设计风格备注 */
  ui_description_remark?: Maybe<string>;
  /** 色彩偏好描述 */
  color_description?: Maybe<string>;
  /** 尺寸描述 */
  size_description?: Maybe<string>;
  /** 尺寸描述文件 */
  size_description_files?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 源文件格式要求 */
  original_file_format: string;
  /** 图片格式要求 */
  file_format: string;
  /** logo 类型 */
  logo_type?: Maybe<string>;
  /** logo 名称 */
  logo_name?: Maybe<string>;
  /** 排序 */
  position?: Maybe<number>;
}

export interface ChangeDemandTime {
  id: string;

  finish_date?: Maybe<string>;

  status?: Maybe<string>;
}

export interface AppointDesigner {
  id: string;

  designer_id: string;
}

export interface FinishDemand {
  id: string;

  finish_remark?: Maybe<string>;

  rate?: Maybe<number>;

  appropriate_rate?: Maybe<number>;

  feedback?: Maybe<string>;
}

export interface SortDemandList {
  /** 需求列表 */
  demandList?: Maybe<SortDemand[]>;
}

export interface SortDemand {
  id: string;

  position: number;
}

export interface CreateNotice {
  /** 消息 */
  message: string;
  /** 拥有者 */
  owner_id: string;
}

export interface SignWeChatInput {
  /** url */
  url: string;
  /** 这个字段特殊 使用大写 */
  jsApiList: string[];
}

export interface ChangeTeamEndDate {
  team_id: string;

  plan_end_date: string;
}

export interface CreateTeam {
  name: string;

  mobile?: Maybe<string>;

  code?: Maybe<string>;
}

export interface AddTeam {
  team_code: string;

  mobile?: Maybe<string>;

  code?: Maybe<string>;
}

export interface AppointTeamDesigner {
  id: string;

  designer_id: string;
}

export interface AppointTeamDesignerAdviser {
  id: string;

  designer_adviser_id: string;
}

export interface AddBalance {
  team_id: string;

  balance: number;

  remark: string;
}

export interface ChangeTeam {
  team_id: string;
}

export interface CreateProject {
  name: string;
}

export interface CreateMember {
  name: string;
}

export interface CreateIndependent {
  name: string;
  /** 需求描述 */
  description: string;
  /** 需求描述文件 */
  description_files?: Maybe<string>;
  /** 项目周期 */
  cycle: string;
  /** 预算 */
  budget: string;
}

export interface CreatePackage {
  name: string;
}

export interface CreateSubscription {
  name: string;
}

export interface ChangeSubscriptionCount {
  team_id: string;

  count: number;
}

export interface CreateOrderItem {
  /** 服务类型 */
  type: string;
  /** 明细 */
  detail: string;
  /** 金额 */
  price: number;
  /** 增 减 0 1 */
  order_type: number;
  /** 订单号 */
  out_trade_no: string;
}

export interface CreateAnnex {
  /** 需求 id */
  demand_id: string;
  /** 分组 id */
  group_id: string;
  /** 创建时间 */
  group_date: DateTime;
  /** 文件 */
  path: string;
  /** 文件原名 */
  file_old_name?: Maybe<string>;
}

export interface FinishAnnex {
  /** id */
  id: string;
}

export interface CreateAnnotation {
  /** id */
  id?: Maybe<string>;
  /** 附件 id */
  annex_id: string;
  /** 内容 */
  text: string;

  geometry: string;

  data: string;
}

export interface UpdateAnnotation {
  /** id */
  id: string;
  /** 附件 id */
  annex_id: string;
  /** 内容 */
  text: string;

  geometry: string;

  data: string;
}

export interface CreateDemandMore {
  context: string;

  uris?: Maybe<string>;

  demand_id: string;
}

export interface UpdateDemandMore {
  id: string;

  context: string;

  uris: string;
}

export interface CreateInvoice {
  order_id: string;

  company_name: string;

  identification_number: string;

  amount: string;

  invoice_type: string;

  email: string;

  phone: string;
}

/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
export type DateTime = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface Query {
  viewer: UserEntity;
  /** 用户管理 */
  users: UserList;
  /** 设计师管理 */
  designerList: UserList;
  /** 获取所有设计师 */
  designers: UserEntity[];

  getOrderById: OrderEntity;
  /** 列表 */
  orders: OrderList;

  getPlanRecordById: PlanRecordEntity;
  /** 列表 */
  planRecords: PlanRecordList;

  getDemandById: DemandEntity;

  getDemandByAdmin: DemandEntity;
  /** 列表 */
  demands: DemandList;
  /** 列表 */
  designerDemands: DemandList;
  /** 列表 */
  ownerDemands: DemandList;

  getNoticeById: NoticeEntity;
  /** 列表 */
  ownNotices: NoticeEntity[];
  /** 列表 */
  notices: NoticeList;

  getFileById: FileEntity;

  requestUploadOSSFile?: Maybe<StsTokenType>;

  getTeamById: TeamEntity;
  /** 创建邀请链接通过团队id */
  createTeamUriById: TeamUri;
  /** 创建邀请链接 */
  createTeamUri: TeamUri;
  /** 通过邀请码获取团队 */
  getTeamByCode: BaseTeam;
  /** 列表 */
  teams: TeamList;
  /** 设计师团队列表 */
  designerTeamList: TeamList;

  getProjectById: ProjectEntity;
  /** 列表 */
  projects: ProjectList;

  getMemberById: MemberEntity;
  /** 列表 */
  members: MemberList;

  getIndependentById: IndependentEntity;

  getIndependentByAdmin: IndependentEntity;
  /** 列表 */
  independents: IndependentList;

  getPackageById: PackageEntity;
  /** 列表 */
  packages: PackageList;

  getSubscriptionById: SubscriptionEntity;
  /** 列表 */
  subscriptions: SubscriptionList;

  getOrderItemById: OrderItemEntity;
  /** 列表 */
  orderItems: OrderItemList;

  getAnnexById: AnnexEntity;
  /** 获取需求下设计稿 */
  annexGroupList: AnnexGroupList[];
  /** 列表 */
  annexList: AnnexList;

  getAnnotationById: AnnotationEntity;
  /** 列表 */
  annexAnnotations: AnnotationEntity[];
  /** 列表 */
  annotations: AnnotationList;

  getDemandMoreById: DemandMoreEntity;
  /** 列表 */
  demandMores: DemandMoreList;

  getInvoiceById: InvoiceEntity;
  /** 列表 */
  invoices: InvoiceList;
}

/** 用户 */
export interface UserEntity {
  id: string;

  username?: Maybe<string>;

  role?: Maybe<string>;

  avatar?: Maybe<string>;

  mobile?: Maybe<string>;

  nickname?: Maybe<string>;

  headimgurl?: Maybe<string>;

  proxy_headimgurl?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  demands: DemandEntity[];

  orders: OrderList;

  team?: Maybe<TeamEntity>;

  teams: TeamEntity[];
  /** 服务的额客户数量 */
  services_customs_count: number;
  /** 服务的额客户列表 */
  services_customs_list: TeamEntity[];
  /** 服务的需求数 */
  demands_count: number;
  /** 服务的需求进行中数 */
  demands_progress_count: number;

  is_mobile: boolean;
}

export interface DemandEntity {
  id: string;
  /** 需求名称 */
  name: string;
  /** 花费的时间 */
  cost_day: number;
  /** 状态 进度 进行中 等待中 已完成 已取消 */
  status: string;
  /** 类别 独立项目 订阅计划 */
  type: string;
  /** 项目名称 */
  project_name: string;
  /** 需求描述 */
  description: string;
  /** 视觉风格描述 */
  ui_description?: Maybe<string>;
  /** 色彩偏好描述 */
  color_description?: Maybe<string>;
  /** 尺寸描述 */
  size_description?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 需求开始时间 */
  start_date?: Maybe<DateTime>;
  /** 需求预计完成时间 */
  estimated_finish_date?: Maybe<DateTime>;
  /** 需求完成时间 */
  finish_date: DateTime;
  /** 源文件格式要求 */
  original_file_format?: Maybe<string>;
  /** 图片格式要求 */
  file_format?: Maybe<string>;
  /** logo 类型 */
  logo_type?: Maybe<string>;
  /** logo 名称 */
  logo_name?: Maybe<string>;
  /** logo 名称 */
  cover?: Maybe<string>;
  /** 完成备注 */
  finish_remark?: Maybe<string>;
  /** 是否有新的修改意见 */
  is_new?: Maybe<boolean>;
  /** ui设计师是否新上传设计稿 */
  is_new_ui?: Maybe<boolean>;
  /** 是否是新创建的需求 */
  designer_is_new?: Maybe<boolean>;
  /** 用户群体 */
  client?: Maybe<string>;
  /** 评分 */
  rate?: Maybe<number>;
  /** 评分 */
  appropriate_rate?: Maybe<number>;
  /** 反馈 */
  feedback?: Maybe<string>;
  /** 用户群体描述 */
  client_desc?: Maybe<string>;
  /** 设计风格备注 */
  ui_description_remark?: Maybe<string>;
  /** 排序 */
  position?: Maybe<number>;
  /** team订阅到期后更新该状态，设计师端查询是不查询该数据，1为已过期，其它非 */
  is_expire?: Maybe<number>;

  created_date?: Maybe<DateTime>;

  cover_uri?: Maybe<string>;

  updated_date?: Maybe<DateTime>;

  designer_status?: Maybe<string>;

  description_files?: Maybe<FileItemList[]>;

  ui_description_files?: Maybe<FileItemList[]>;

  size_description_files?: Maybe<FileItemList[]>;

  owner?: Maybe<UserEntity>;

  designer?: Maybe<UserEntity>;

  team: TeamEntity;

  project: ProjectEntity;

  order?: Maybe<OrderEntity>;

  more?: Maybe<DemandMoreEntity>;

  mores: DemandMoreEntity[];
}

export interface FileItemList {
  uri: string;

  name: string;
}

export interface TeamEntity {
  id: string;

  name: string;

  balance: number;
  /** 订阅名称 */
  plan?: Maybe<string>;
  /** 项目周期 */
  cycle?: Maybe<number>;
  /** 项目周期名称 */
  cycle_name?: Maybe<string>;
  /** 设计师 id */
  designer_id?: Maybe<string>;
  /** 设计师顾问id */
  designer_adviser_id?: Maybe<string>;

  plan_end_date?: Maybe<DateTime>;

  now_plan_end_date?: Maybe<DateTime>;

  plan_count?: Maybe<number>;

  card_uri?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  owner: UserEntity;

  designer?: Maybe<UserEntity>;

  designerAdviser?: Maybe<UserEntity>;

  members: MemberEntity[];

  oss_card_uri?: Maybe<string>;

  subscriptions: SubscriptionEntity[];

  now_subscription?: Maybe<SubscriptionEntity>;

  orders: OrderEntity[];

  order_count: number;

  old_team_names: ChangeTeamRecordEntity[];
}

export interface MemberEntity {
  id: string;

  team_id: string;

  created_date?: Maybe<DateTime>;

  owner?: Maybe<UserEntity>;
}

export interface SubscriptionEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 数量 */
  count: number;
  /** 订阅开始时间 */
  start_date?: Maybe<DateTime>;
  /** 订阅到期时间 */
  end_date?: Maybe<DateTime>;
  /** 项目周期 */
  cycle: number;
  /** 项目周期名称 */
  cycle_name: string;
  /** 价格 */
  price?: Maybe<number>;
  /** 状态 未付款 已付款 */
  status: string;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 */
  pay_type?: Maybe<string>;

  created_date?: Maybe<DateTime>;
}

export interface OrderEntity {
  id: string;
  /** 服务类型 单次独立项目 */
  type: string;
  /** 续订 新订 升级 */
  team_type?: Maybe<string>;
  /** 可同时进行的需求数 */
  demand_count?: Maybe<number>;
  /** 明细 */
  detail: string;
  /** 状态 待付款 已付款 待审核 审核通过 审核未通过 */
  status: string;
  /** 付款金额 */
  price: number;
  /** 原价 */
  old_price: number;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 对公账户 */
  pay_type?: Maybe<string>;
  /** 付款时间 */
  pay_date?: Maybe<DateTime>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 原因 */
  reason?: Maybe<string>;
  /** 签名 uri */
  uri?: Maybe<string>;
  /** 付款凭证 */
  pay_uri?: Maybe<string>;
  /** 项目周期 */
  cycle?: Maybe<number>;
  /** 项目周期名称 */
  cycle_name?: Maybe<string>;
  /** 是否开票 */
  open_invoice: boolean;

  created_date?: Maybe<DateTime>;

  owner?: Maybe<UserEntity>;

  pay_user?: Maybe<UserEntity>;

  items: OrderItemEntity[];

  team: TeamEntity;
}

export interface OrderItemEntity {
  id: string;
  /** 服务类型 */
  type: string;
  /** 明细 */
  detail: string;
  /** 金额 */
  price: number;
  /** 增 减 0 1 */
  order_type: number;
  /** 订单号 */
  out_trade_no: string;
}

export interface ChangeTeamRecordEntity {
  id: string;

  old_name: string;

  new_name: string;

  user: UserEntity;

  created_date?: Maybe<DateTime>;
}

export interface ProjectEntity {
  id: string;
  /** 名称 */
  title: string;
  /** 主图 */
  uri: string;
  /** 类型  或者说是属于哪个套餐 */
  type?: Maybe<string>;
  /** 线上 线下 */
  item_type: string;
  /** 内容 */
  content: string;
  /** 介绍 */
  description: string;
  /** 需要的时间 单位 日 */
  cost_day: number;
}

export interface DemandMoreEntity {
  id: string;
  /** 需求内容 */
  context: string;
  /** 文件 */
  uris: string;

  oss_uris: DemandMoreUris[];
}

export interface DemandMoreUris {
  uri: string;

  oss_uri: string;
}

export interface OrderList {
  count: number;

  list: OrderEntity[];
}

export interface UserList {
  count: number;

  list: UserEntity[];
}

export interface PlanRecordEntity {
  id: string;
  /** 订阅名称 */
  name: string;
  /** 需求数目 */
  number: number;
  /** 项目周期 */
  cycle: number;
  /** 项目周期名称 */
  cycle_name: string;
  /** 价格 */
  price: number;
  /** 状态 未付款 已付款 */
  status: string;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 */
  pay_type?: Maybe<string>;
  /** 计划类型 */
  plan_type?: Maybe<string>;
  /** 签名 uri */
  uri?: Maybe<string>;
}

export interface PlanRecordList {
  count: number;

  list: PlanRecordEntity[];
}

export interface DemandList {
  count: number;

  list: DemandEntity[];
}

export interface NoticeEntity {
  id: string;
  /** 消息 */
  message: string;
  /** 是否已读 */
  is_read: boolean;
  /** 拥有者 */
  owner_id: string;
}

export interface NoticeList {
  count: number;

  list: NoticeEntity[];
}

export interface FileEntity {
  id: string;
}

export interface StsTokenType {
  access_key_id: string;

  policy: string;

  signature: string;

  key: string;

  host: string;

  expiration: DateTime;

  dir?: Maybe<string>;

  callback: string;
}

export interface TeamUri {
  uri: string;
}

export interface BaseTeam {
  id: string;

  name: string;

  owner: UserEntity;
}

export interface TeamList {
  count: number;

  list: TeamEntity[];
}

export interface ProjectList {
  count: number;

  list: ProjectEntity[];
}

export interface MemberList {
  count: number;

  list: MemberEntity[];
}

export interface IndependentEntity {
  id: string;
  /** 需求名称 */
  name: string;
  /** 需求描述 */
  description: string;
  /** 项目周期 */
  cycle: string;
  /** 预算 */
  budget: string;

  created_date?: Maybe<DateTime>;

  description_files?: Maybe<FileItemList[]>;

  owner: UserEntity;

  team: TeamEntity;
}

export interface IndependentList {
  count: number;

  list: IndependentEntity[];
}

export interface PackageEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 现价 */
  price: number;
  /** 得到多少余额 */
  balance: number;
  /** 状态 上架 下架 */
  status: string;
  /** 介绍 */
  introduction: string;
  /** 备注 */
  remark?: Maybe<string>;
}

export interface PackageList {
  count: number;

  list: PackageEntity[];
}

export interface SubscriptionList {
  count: number;

  list: SubscriptionEntity[];
}

export interface OrderItemList {
  count: number;

  list: OrderItemEntity[];
}

export interface AnnexEntity {
  id: string;
  /** 设计师 id */
  designer_id: string;
  /** 需求 id */
  demand_id: string;
  /** 创建时间 */
  group_date: DateTime;
  /** 创建时间 */
  group_id: string;
  /** 文件 */
  path: string;
  /** 文件原名 */
  file_old_name?: Maybe<string>;
  /** 是否有反馈 */
  is_new?: Maybe<boolean>;
  /** 地址 */
  uri: string;
}

export interface AnnexGroupList {
  items: AnnexEntity[];

  date: string;

  is_new?: Maybe<boolean>;

  group_id: string;
}

export interface AnnexList {
  count: number;

  list: AnnexEntity[];
}

export interface AnnotationEntity {
  id: string;
  /** 创建人 */
  create_owner_id: string;
  /** 附件 id */
  annex_id: string;
  /** 内容 */
  text: string;
  /** 是否处理 */
  is_finish?: Maybe<boolean>;

  geometry: string;

  data: string;

  owner: UserEntity;

  created_date: DateTime;
}

export interface AnnotationList {
  count: number;

  list: AnnotationEntity[];
}

export interface DemandMoreList {
  count: number;

  list: DemandMoreEntity[];
}

export interface InvoiceEntity {
  id: string;
  /** 金额 */
  amount: string;
  /** 发票类型 */
  invoice_type?: Maybe<string>;
  /** 邮箱 */
  email?: Maybe<string>;
  /** 手机号 */
  phone: string;
}

export interface InvoiceList {
  count: number;

  list: InvoiceEntity[];
}

export interface Mutation {
  /** 管理员登录 */
  loginByAdmin: AccessToken;
  /** UI 登录 */
  loginByUi: AccessToken;
  /** 注册短信验证码 */
  createMobileCode: Result;
  /** 创建设计师 */
  createDesigner: UserEntity;
  /** 创建独立项目订单 */
  createProductOrder: OrderEntity;
  /** 创建订阅项目订单 */
  createSubscriptionOrder: OrderEntity;
  /** 升级订阅项目订单 */
  updateSubscriptionOrder: OrderEntity;
  /** 订单签名 */
  signProductOrder: OrderEntity;
  /** 上传凭证 */
  uploadPayImage: OrderEntity;
  /** 退款 */
  backOrderByAdmin: OrderEntity;
  /** 通过订单 */
  changeOrderStatusByAdmin: OrderEntity;
  /** 不通过订单 */
  notPassOrder: OrderEntity;
  /** 创建 */
  createPlanRecord: PlanRecordEntity;
  /** 升级 */
  updateSubscription: PlanRecordEntity;
  /** 余额支付 */
  balancePay: PlanRecordEntity;
  /** 余额支付更新 */
  balancePayUpdate: PlanRecordEntity;
  /** 创建 */
  createDemand: DemandEntity;
  /** 创建 */
  createDemandByAdmin: DemandEntity;
  /** 创建 */
  createDemandByUi: DemandEntity;
  /** 修改需求时间 */
  changeDemandTime: DemandEntity;
  /** 指定设计师 */
  appointDesigner: DemandEntity;
  /** 完成需求 */
  finishDemand: DemandEntity;
  /** 取消需求 */
  cancelDemand: DemandEntity;
  /** 取消需求 */
  cancelDemandByOwner: DemandEntity;
  /** 置顶需求 */
  topDemandByOwner: DemandEntity;
  /** 转化需求 */
  changeDemandType: DemandEntity;
  /** 排序等待中的需求 */
  sortDemands: Result;
  /** 创建 */
  createNotice: NoticeEntity;
  /** 完成 */
  readNotice: NoticeEntity;
  /** 微信签名 */
  signWeChat: SignWeChatResult;

  changeTeamEndDate: TeamEntity;
  /** 创建 */
  createTeam: TeamEntity;
  /** 删除团队 */
  deleteTeam: TeamEntity;
  /** 加入团队 */
  addTeam: TeamEntity;
  /** 上传名片 */
  uploadTeamCardUri: TeamEntity;
  /** 指定设计师 */
  appointTeamDesigner: TeamEntity;
  /** 指定设计师 */
  appointTeamDesignerAdviser: TeamEntity;
  /** 赠送余额 */
  addBalance: TeamEntity;
  /** 切换团队 */
  changeTeam: TeamEntity;
  /** 转移团队管理人 */
  changeTeamOwner: TeamEntity;
  /** 修改团队名称 */
  changeTeamName: TeamEntity;
  /** 创建 */
  createProject: ProjectEntity;
  /** 创建 */
  createMember: MemberEntity;
  /** 退出团队 */
  outTeam: MemberEntity;
  /** 删除成员 */
  deleteMember: MemberEntity;
  /** 移动成员 */
  moveMember: MemberEntity;
  /** 创建 */
  createIndependent: IndependentEntity;
  /** 创建 */
  createPackage: PackageEntity;
  /** 创建 */
  createSubscription: SubscriptionEntity;
  /** 修改同时并发项目的数量 */
  changeSubscriptionCount: SubscriptionEntity;
  /** 创建 */
  createOrderItem: OrderItemEntity;
  /** 删除 */
  deleteOrderItem: OrderItemEntity;
  /** 创建 */
  createAnnex: AnnexEntity;
  /** 处理 */
  finishAnnex: AnnexEntity;
  /** 创建 */
  createAnnotation: AnnotationEntity;
  /** 更新 */
  updateAnnotation: AnnotationEntity;
  /** 删除 */
  deleteAnnotation: AnnotationEntity;
  /** 创建 */
  createDemandMore: DemandMoreEntity;
  /** 创建 */
  updateDemandMore: DemandMoreEntity;
  /** 创建开票申请 */
  CreateInvoice: InvoiceEntity;
}

export interface AccessToken {
  user_id?: Maybe<string>;

  expires_in?: Maybe<number>;

  access_token?: Maybe<string>;
}

export interface Result {
  success: boolean;
}

export interface SignWeChatResult {
  debug?: Maybe<boolean>;

  appId?: Maybe<string>;

  timestamp?: Maybe<string>;

  nonceStr?: Maybe<string>;

  signature?: Maybe<string>;

  jsApiList?: Maybe<string[]>;

  mobile?: Maybe<string>;

  true_name?: Maybe<string>;
}

export interface PageInfo {
  startCursor?: Maybe<string>;

  endCursor?: Maybe<string>;

  hasPreviousPage: boolean;

  hasNextPage: boolean;

  totalCount: boolean;
}

// ====================================================
// Arguments
// ====================================================

export interface UsersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<UsersFilterOrder>;

  role?: Maybe<string>;
}
export interface DesignerListQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<UsersFilterOrder>;

  role?: Maybe<string>;
}
export interface GetOrderByIdQueryArgs {
  id: string;
}
export interface OrdersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string[]>;

  pay_type?: Maybe<string[]>;

  type?: Maybe<string[]>;
}
export interface GetPlanRecordByIdQueryArgs {
  id: string;
}
export interface PlanRecordsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PlanRecordFilterOrder>;
}
export interface GetDemandByIdQueryArgs {
  id: string;
}
export interface GetDemandByAdminQueryArgs {
  id: string;
}
export interface DemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;

  designer_id?: Maybe<string>;

  owner_id?: Maybe<string>;

  name?: Maybe<string[]>;

  owner_username?: Maybe<string[]>;

  company_name?: Maybe<string[]>;

  type?: Maybe<string[]>;

  designer_status?: Maybe<string[]>;
}
export interface DesignerDemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;

  designer_id?: Maybe<string>;

  owner_id?: Maybe<string>;

  name?: Maybe<string[]>;

  owner_username?: Maybe<string[]>;

  company_name?: Maybe<string[]>;

  type?: Maybe<string[]>;

  designer_status?: Maybe<string[]>;
}
export interface OwnerDemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;

  designer_id?: Maybe<string>;

  owner_id?: Maybe<string>;

  name?: Maybe<string[]>;

  owner_username?: Maybe<string[]>;

  company_name?: Maybe<string[]>;

  type?: Maybe<string[]>;

  designer_status?: Maybe<string[]>;
}
export interface GetNoticeByIdQueryArgs {
  id: string;
}
export interface NoticesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<NoticeFilterOrder>;
}
export interface GetFileByIdQueryArgs {
  id: string;
}
export interface RequestUploadOssFileQueryArgs {
  file_name: string;
}
export interface GetTeamByIdQueryArgs {
  id: string;
}
export interface CreateTeamUriByIdQueryArgs {
  id: string;
}
export interface GetTeamByCodeQueryArgs {
  code: string;
}
export interface TeamsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<TeamFilterOrder>;

  range?: Maybe<string>;

  designer_id?: Maybe<string>;
}
export interface GetProjectByIdQueryArgs {
  id: string;
}
export interface ProjectsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<ProjectFilterOrder>;

  type?: Maybe<string>;
}
export interface GetMemberByIdQueryArgs {
  id: string;
}
export interface MembersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<MemberFilterOrder>;

  team_id?: Maybe<string>;
}
export interface GetIndependentByIdQueryArgs {
  id: string;
}
export interface GetIndependentByAdminQueryArgs {
  id: string;
}
export interface IndependentsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<IndependentFilterOrder>;
}
export interface GetPackageByIdQueryArgs {
  id: string;
}
export interface PackagesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PackageFilterOrder>;
}
export interface GetSubscriptionByIdQueryArgs {
  id: string;
}
export interface SubscriptionsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<SubscriptionFilterOrder>;

  team_id?: Maybe<string>;
}
export interface GetOrderItemByIdQueryArgs {
  id: string;
}
export interface OrderItemsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderItemFilterOrder>;
}
export interface GetAnnexByIdQueryArgs {
  id: string;
}
export interface AnnexGroupListQueryArgs {
  group_id?: Maybe<string>;

  id: string;
}
export interface AnnexListQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<AnnexFilterOrder>;
}
export interface GetAnnotationByIdQueryArgs {
  id: string;
}
export interface AnnexAnnotationsQueryArgs {
  annex_id: string;
}
export interface AnnotationsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<AnnotationFilterOrder>;

  annex_id?: Maybe<string>;
}
export interface GetDemandMoreByIdQueryArgs {
  id: string;
}
export interface DemandMoresQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandMoreFilterOrder>;
}
export interface GetInvoiceByIdQueryArgs {
  id: string;
}
export interface InvoicesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;
}
export interface OrdersUserEntityArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string[]>;

  pay_type?: Maybe<string[]>;

  type?: Maybe<string[]>;
}
export interface OrdersTeamEntityArgs {
  skip?: number;

  take?: number;

  search?: Maybe<string>;

  columns?: Maybe<string[]>;
}
export interface LoginByAdminMutationArgs {
  LoginByAdmin: LoginByAdmin;
}
export interface LoginByUiMutationArgs {
  LoginByUi: LoginByUi;
}
export interface CreateMobileCodeMutationArgs {
  /** 手机号 */
  mobile: string;
  /** 国家区号 */
  country_number: string;
}
export interface CreateDesignerMutationArgs {
  CreateDesigner: CreateDesigner;
}
export interface CreateProductOrderMutationArgs {
  CreateProductOrder: CreateProductOrder;
}
export interface CreateSubscriptionOrderMutationArgs {
  CreateSubscriptionOrder: CreateSubscriptionOrder;
}
export interface UpdateSubscriptionOrderMutationArgs {
  UpdateSubscriptionByOrder: UpdateSubscriptionByOrder;
}
export interface SignProductOrderMutationArgs {
  SignProductOrder: SignProductOrder;
}
export interface UploadPayImageMutationArgs {
  UploadOrderImage: UploadOrderImage;
}
export interface BackOrderByAdminMutationArgs {
  ChangeOrderStatus: ChangeOrderStatus;
}
export interface ChangeOrderStatusByAdminMutationArgs {
  ChangeOrderStatus: ChangeOrderStatus;
}
export interface NotPassOrderMutationArgs {
  NotPassOrder: NotPassOrder;
}
export interface CreatePlanRecordMutationArgs {
  CreatePlanRecord: CreatePlanRecord;
}
export interface UpdateSubscriptionMutationArgs {
  UpdateSubscription: UpdateSubscription;
}
export interface BalancePayMutationArgs {
  plan_record_id: string;
}
export interface BalancePayUpdateMutationArgs {
  plan_record_id: string;
}
export interface CreateDemandMutationArgs {
  CreateDemand: CreateDemand;
}
export interface CreateDemandByAdminMutationArgs {
  owner_id: string;

  team_id: string;

  CreateDemand: CreateDemand;
}
export interface CreateDemandByUiMutationArgs {
  owner_id: string;

  team_id: string;

  CreateDemand: CreateDemand;
}
export interface ChangeDemandTimeMutationArgs {
  ChangeDemandTime: ChangeDemandTime;
}
export interface AppointDesignerMutationArgs {
  AppointDesigner: AppointDesigner;
}
export interface FinishDemandMutationArgs {
  FinishDemand: FinishDemand;
}
export interface CancelDemandMutationArgs {
  id: string;
}
export interface CancelDemandByOwnerMutationArgs {
  id: string;
}
export interface TopDemandByOwnerMutationArgs {
  id: string;
}
export interface ChangeDemandTypeMutationArgs {
  id: string;
}
export interface SortDemandsMutationArgs {
  SortDemandList: SortDemandList;
}
export interface CreateNoticeMutationArgs {
  CreateNotice: CreateNotice;
}
export interface ReadNoticeMutationArgs {
  /** id */
  id: string;
}
export interface SignWeChatMutationArgs {
  SignWeChatInput: SignWeChatInput;
}
export interface ChangeTeamEndDateMutationArgs {
  ChangeTeamEndDate: ChangeTeamEndDate;
}
export interface CreateTeamMutationArgs {
  CreateTeam: CreateTeam;
}
export interface DeleteTeamMutationArgs {
  id: string;
}
export interface AddTeamMutationArgs {
  AddTeam: AddTeam;
}
export interface UploadTeamCardUriMutationArgs {
  card_uri: string;

  id: string;
}
export interface AppointTeamDesignerMutationArgs {
  AppointTeamDesigner: AppointTeamDesigner;
}
export interface AppointTeamDesignerAdviserMutationArgs {
  AppointTeamDesignerAdviser: AppointTeamDesignerAdviser;
}
export interface AddBalanceMutationArgs {
  AddBalance: AddBalance;
}
export interface ChangeTeamMutationArgs {
  ChangeTeam: ChangeTeam;
}
export interface ChangeTeamOwnerMutationArgs {
  user_id: string;
}
export interface ChangeTeamNameMutationArgs {
  new_name: string;

  team_id: string;
}
export interface CreateProjectMutationArgs {
  CreateProject: CreateProject;
}
export interface CreateMemberMutationArgs {
  CreateMember: CreateMember;
}
export interface DeleteMemberMutationArgs {
  user_id: string;
}
export interface MoveMemberMutationArgs {
  old_team_id: string;

  to_team_id: string;

  user_id: string;
}
export interface CreateIndependentMutationArgs {
  CreateIndependent: CreateIndependent;
}
export interface CreatePackageMutationArgs {
  CreatePackage: CreatePackage;
}
export interface CreateSubscriptionMutationArgs {
  CreateSubscription: CreateSubscription;
}
export interface ChangeSubscriptionCountMutationArgs {
  ChangeSubscriptionCount: ChangeSubscriptionCount;
}
export interface CreateOrderItemMutationArgs {
  CreateOrderItem: CreateOrderItem;
}
export interface DeleteOrderItemMutationArgs {
  id: string;
}
export interface CreateAnnexMutationArgs {
  CreateAnnex: CreateAnnex;
}
export interface FinishAnnexMutationArgs {
  FinishAnnex: FinishAnnex;
}
export interface CreateAnnotationMutationArgs {
  CreateAnnotation: CreateAnnotation;
}
export interface UpdateAnnotationMutationArgs {
  UpdateAnnotation: UpdateAnnotation;
}
export interface DeleteAnnotationMutationArgs {
  /** id */
  id: string;
}
export interface CreateDemandMoreMutationArgs {
  CreateDemandMore: CreateDemandMore;
}
export interface UpdateDemandMoreMutationArgs {
  UpdateDemandMore: UpdateDemandMore;
}
export interface CreateInvoiceMutationArgs {
  CreateInvoice: CreateInvoice;
}






// ====================================================
// Components
// ====================================================

export const AnnexAnnotationsDocument = gql`
  query AnnexAnnotations($annex_id: String!) {
    annexAnnotations(annex_id: $annex_id) {
      id
      owner {
        id
        username
        headimgurl
      }
      annex_id
      text
      geometry
      data
      created_date
    }
  }
`;
export interface AnnexAnnotationsQuery {
  annexAnnotations: Query["annexAnnotations"];
}
export class AnnexAnnotationsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<AnnexAnnotationsQuery, AnnexAnnotationsQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<AnnexAnnotationsQuery, AnnexAnnotationsQueryArgs>
        query={AnnexAnnotationsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AnnexAnnotationsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<AnnexAnnotationsQuery, AnnexAnnotationsQueryArgs>
> &
  TChildProps;
export function AnnexAnnotationsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AnnexAnnotationsQuery,
        AnnexAnnotationsQueryArgs,
        AnnexAnnotationsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AnnexAnnotationsQuery,
    AnnexAnnotationsQueryArgs,
    AnnexAnnotationsProps<TChildProps>
  >(AnnexAnnotationsDocument, operationOptions);
}
export const AnnexGroupListDocument = gql`
  query AnnexGroupList($id: String!, $group_id: String) {
    annexGroupList(id: $id, group_id: $group_id) {
      date
      group_id
      items {
        id
        path
        uri
        file_old_name
      }
    }
  }
`;
export interface AnnexGroupListQuery {
  annexGroupList: Query["annexGroupList"];
}
export class AnnexGroupListComponent extends React.Component<
  Partial<ReactApollo.QueryProps<AnnexGroupListQuery, AnnexGroupListQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<AnnexGroupListQuery, AnnexGroupListQueryArgs>
        query={AnnexGroupListDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AnnexGroupListProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<AnnexGroupListQuery, AnnexGroupListQueryArgs>
> &
  TChildProps;
export function AnnexGroupListHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AnnexGroupListQuery,
        AnnexGroupListQueryArgs,
        AnnexGroupListProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AnnexGroupListQuery,
    AnnexGroupListQueryArgs,
    AnnexGroupListProps<TChildProps>
  >(AnnexGroupListDocument, operationOptions);
}
export const CreateAnnexDocument = gql`
  mutation CreateAnnex($CreateAnnex: CreateAnnex!) {
    createAnnex(CreateAnnex: $CreateAnnex) {
      id
    }
  }
`;
export interface CreateAnnexMutation {
  createAnnex: Mutation["createAnnex"];
}
export class CreateAnnexComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateAnnexMutation, CreateAnnexMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateAnnexMutation, CreateAnnexMutationArgs>
        mutation={CreateAnnexDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateAnnexProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateAnnexMutation, CreateAnnexMutationArgs>
> &
  TChildProps;
export type CreateAnnexMutationFn = ReactApollo.MutationFn<
  CreateAnnexMutation,
  CreateAnnexMutationArgs
>;
export function CreateAnnexHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateAnnexMutation,
        CreateAnnexMutationArgs,
        CreateAnnexProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateAnnexMutation,
    CreateAnnexMutationArgs,
    CreateAnnexProps<TChildProps>
  >(CreateAnnexDocument, operationOptions);
}
export const CreateAnnotationDocument = gql`
  mutation CreateAnnotation($CreateAnnotation: CreateAnnotation!) {
    createAnnotation(CreateAnnotation: $CreateAnnotation) {
      id
    }
  }
`;
export interface CreateAnnotationMutation {
  createAnnotation: Mutation["createAnnotation"];
}
export class CreateAnnotationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateAnnotationMutation,
      CreateAnnotationMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateAnnotationMutation,
        CreateAnnotationMutationArgs
      >
        mutation={CreateAnnotationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateAnnotationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateAnnotationMutation,
    CreateAnnotationMutationArgs
  >
> &
  TChildProps;
export type CreateAnnotationMutationFn = ReactApollo.MutationFn<
  CreateAnnotationMutation,
  CreateAnnotationMutationArgs
>;
export function CreateAnnotationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateAnnotationMutation,
        CreateAnnotationMutationArgs,
        CreateAnnotationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateAnnotationMutation,
    CreateAnnotationMutationArgs,
    CreateAnnotationProps<TChildProps>
  >(CreateAnnotationDocument, operationOptions);
}
export const DeleteAnnotationDocument = gql`
  mutation DeleteAnnotation($id: String!) {
    deleteAnnotation(id: $id) {
      id
    }
  }
`;
export interface DeleteAnnotationMutation {
  deleteAnnotation: Mutation["deleteAnnotation"];
}
export class DeleteAnnotationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteAnnotationMutation,
      DeleteAnnotationMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteAnnotationMutation,
        DeleteAnnotationMutationArgs
      >
        mutation={DeleteAnnotationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteAnnotationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteAnnotationMutation,
    DeleteAnnotationMutationArgs
  >
> &
  TChildProps;
export type DeleteAnnotationMutationFn = ReactApollo.MutationFn<
  DeleteAnnotationMutation,
  DeleteAnnotationMutationArgs
>;
export function DeleteAnnotationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteAnnotationMutation,
        DeleteAnnotationMutationArgs,
        DeleteAnnotationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteAnnotationMutation,
    DeleteAnnotationMutationArgs,
    DeleteAnnotationProps<TChildProps>
  >(DeleteAnnotationDocument, operationOptions);
}
export const UpdateAnnotationDocument = gql`
  mutation UpdateAnnotation($UpdateAnnotation: UpdateAnnotation!) {
    updateAnnotation(UpdateAnnotation: $UpdateAnnotation) {
      id
    }
  }
`;
export interface UpdateAnnotationMutation {
  updateAnnotation: Mutation["updateAnnotation"];
}
export class UpdateAnnotationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateAnnotationMutation,
      UpdateAnnotationMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateAnnotationMutation,
        UpdateAnnotationMutationArgs
      >
        mutation={UpdateAnnotationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateAnnotationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateAnnotationMutation,
    UpdateAnnotationMutationArgs
  >
> &
  TChildProps;
export type UpdateAnnotationMutationFn = ReactApollo.MutationFn<
  UpdateAnnotationMutation,
  UpdateAnnotationMutationArgs
>;
export function UpdateAnnotationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateAnnotationMutation,
        UpdateAnnotationMutationArgs,
        UpdateAnnotationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateAnnotationMutation,
    UpdateAnnotationMutationArgs,
    UpdateAnnotationProps<TChildProps>
  >(UpdateAnnotationDocument, operationOptions);
}
export const BalancePayDocument = gql`
  mutation BalancePay($plan_record_id: String!) {
    balancePay(plan_record_id: $plan_record_id) {
      id
    }
  }
`;
export interface BalancePayMutation {
  balancePay: Mutation["balancePay"];
}
export class BalancePayComponent extends React.Component<
  Partial<ReactApollo.MutationProps<BalancePayMutation, BalancePayMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<BalancePayMutation, BalancePayMutationArgs>
        mutation={BalancePayDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BalancePayProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<BalancePayMutation, BalancePayMutationArgs>
> &
  TChildProps;
export type BalancePayMutationFn = ReactApollo.MutationFn<
  BalancePayMutation,
  BalancePayMutationArgs
>;
export function BalancePayHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BalancePayMutation,
        BalancePayMutationArgs,
        BalancePayProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BalancePayMutation,
    BalancePayMutationArgs,
    BalancePayProps<TChildProps>
  >(BalancePayDocument, operationOptions);
}
export const BalancePayUpdateDocument = gql`
  mutation BalancePayUpdate($plan_record_id: String!) {
    balancePayUpdate(plan_record_id: $plan_record_id) {
      id
    }
  }
`;
export interface BalancePayUpdateMutation {
  balancePayUpdate: Mutation["balancePayUpdate"];
}
export class BalancePayUpdateComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      BalancePayUpdateMutation,
      BalancePayUpdateMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        BalancePayUpdateMutation,
        BalancePayUpdateMutationArgs
      >
        mutation={BalancePayUpdateDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BalancePayUpdateProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    BalancePayUpdateMutation,
    BalancePayUpdateMutationArgs
  >
> &
  TChildProps;
export type BalancePayUpdateMutationFn = ReactApollo.MutationFn<
  BalancePayUpdateMutation,
  BalancePayUpdateMutationArgs
>;
export function BalancePayUpdateHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BalancePayUpdateMutation,
        BalancePayUpdateMutationArgs,
        BalancePayUpdateProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BalancePayUpdateMutation,
    BalancePayUpdateMutationArgs,
    BalancePayUpdateProps<TChildProps>
  >(BalancePayUpdateDocument, operationOptions);
}
export const CreateIndependentDocument = gql`
  mutation CreateIndependent($CreateIndependent: CreateIndependent!) {
    createIndependent(CreateIndependent: $CreateIndependent) {
      id
    }
  }
`;
export interface CreateIndependentMutation {
  createIndependent: Mutation["createIndependent"];
}
export class CreateIndependentComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateIndependentMutation,
      CreateIndependentMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateIndependentMutation,
        CreateIndependentMutationArgs
      >
        mutation={CreateIndependentDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateIndependentProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateIndependentMutation,
    CreateIndependentMutationArgs
  >
> &
  TChildProps;
export type CreateIndependentMutationFn = ReactApollo.MutationFn<
  CreateIndependentMutation,
  CreateIndependentMutationArgs
>;
export function CreateIndependentHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateIndependentMutation,
        CreateIndependentMutationArgs,
        CreateIndependentProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateIndependentMutation,
    CreateIndependentMutationArgs,
    CreateIndependentProps<TChildProps>
  >(CreateIndependentDocument, operationOptions);
}
export const CancelDemandByOwnerDocument = gql`
  mutation CancelDemandByOwner($id: String!) {
    cancelDemandByOwner(id: $id) {
      id
      status
    }
  }
`;
export interface CancelDemandByOwnerMutation {
  cancelDemandByOwner: Mutation["cancelDemandByOwner"];
}
export class CancelDemandByOwnerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CancelDemandByOwnerMutation,
      CancelDemandByOwnerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CancelDemandByOwnerMutation,
        CancelDemandByOwnerMutationArgs
      >
        mutation={CancelDemandByOwnerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CancelDemandByOwnerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CancelDemandByOwnerMutation,
    CancelDemandByOwnerMutationArgs
  >
> &
  TChildProps;
export type CancelDemandByOwnerMutationFn = ReactApollo.MutationFn<
  CancelDemandByOwnerMutation,
  CancelDemandByOwnerMutationArgs
>;
export function CancelDemandByOwnerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CancelDemandByOwnerMutation,
        CancelDemandByOwnerMutationArgs,
        CancelDemandByOwnerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CancelDemandByOwnerMutation,
    CancelDemandByOwnerMutationArgs,
    CancelDemandByOwnerProps<TChildProps>
  >(CancelDemandByOwnerDocument, operationOptions);
}
export const ChangeDemandTypeDocument = gql`
  mutation ChangeDemandType($id: String!) {
    changeDemandType(id: $id) {
      id
      status
      type
      order {
        id
      }
    }
  }
`;
export interface ChangeDemandTypeMutation {
  changeDemandType: Mutation["changeDemandType"];
}
export class ChangeDemandTypeComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeDemandTypeMutation,
      ChangeDemandTypeMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeDemandTypeMutation,
        ChangeDemandTypeMutationArgs
      >
        mutation={ChangeDemandTypeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeDemandTypeProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ChangeDemandTypeMutation,
    ChangeDemandTypeMutationArgs
  >
> &
  TChildProps;
export type ChangeDemandTypeMutationFn = ReactApollo.MutationFn<
  ChangeDemandTypeMutation,
  ChangeDemandTypeMutationArgs
>;
export function ChangeDemandTypeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeDemandTypeMutation,
        ChangeDemandTypeMutationArgs,
        ChangeDemandTypeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeDemandTypeMutation,
    ChangeDemandTypeMutationArgs,
    ChangeDemandTypeProps<TChildProps>
  >(ChangeDemandTypeDocument, operationOptions);
}
export const CreateDemandDocument = gql`
  mutation CreateDemand($CreateDemand: CreateDemand!) {
    createDemand(CreateDemand: $CreateDemand) {
      id
      finish_date
    }
  }
`;
export interface CreateDemandMutation {
  createDemand: Mutation["createDemand"];
}
export class CreateDemandComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateDemandMutation, CreateDemandMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateDemandMutation, CreateDemandMutationArgs>
        mutation={CreateDemandDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateDemandProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateDemandMutation, CreateDemandMutationArgs>
> &
  TChildProps;
export type CreateDemandMutationFn = ReactApollo.MutationFn<
  CreateDemandMutation,
  CreateDemandMutationArgs
>;
export function CreateDemandHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateDemandMutation,
        CreateDemandMutationArgs,
        CreateDemandProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateDemandMutation,
    CreateDemandMutationArgs,
    CreateDemandProps<TChildProps>
  >(CreateDemandDocument, operationOptions);
}
export const FinishDemandDocument = gql`
  mutation FinishDemand($FinishDemand: FinishDemand!) {
    finishDemand(FinishDemand: $FinishDemand) {
      id
      finish_date
    }
  }
`;
export interface FinishDemandMutation {
  finishDemand: Mutation["finishDemand"];
}
export class FinishDemandComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<FinishDemandMutation, FinishDemandMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<FinishDemandMutation, FinishDemandMutationArgs>
        mutation={FinishDemandDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type FinishDemandProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<FinishDemandMutation, FinishDemandMutationArgs>
> &
  TChildProps;
export type FinishDemandMutationFn = ReactApollo.MutationFn<
  FinishDemandMutation,
  FinishDemandMutationArgs
>;
export function FinishDemandHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FinishDemandMutation,
        FinishDemandMutationArgs,
        FinishDemandProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FinishDemandMutation,
    FinishDemandMutationArgs,
    FinishDemandProps<TChildProps>
  >(FinishDemandDocument, operationOptions);
}
export const GetDemandByIdDocument = gql`
  query GetDemandById($id: String!) {
    getDemandById(id: $id) {
      id
      name
      status
      description
      ui_description
      color_description
      size_description
      remark
      finish_date
      created_date
      start_date
      updated_date
      finish_remark
      original_file_format
      file_format
      project_name
      logo_type
      logo_name
      client
      client_desc
      ui_description_remark
      is_new
      is_new_ui
      designer_is_new
      description_files {
        name
        uri
      }
      ui_description_files {
        name
        uri
      }
      size_description_files {
        name
        uri
      }
      project {
        id
        title
        uri
        type
        content
        description
        cost_day
      }
      owner {
        id
        username
        headimgurl
      }
      more {
        id
        context
        uris
        oss_uris {
          uri
          oss_uri
        }
      }
    }
  }
`;
export interface GetDemandByIdQuery {
  getDemandById: Query["getDemandById"];
}
export class GetDemandByIdComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetDemandByIdQuery, GetDemandByIdQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetDemandByIdQuery, GetDemandByIdQueryArgs>
        query={GetDemandByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDemandByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetDemandByIdQuery, GetDemandByIdQueryArgs>
> &
  TChildProps;
export function GetDemandByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDemandByIdQuery,
        GetDemandByIdQueryArgs,
        GetDemandByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDemandByIdQuery,
    GetDemandByIdQueryArgs,
    GetDemandByIdProps<TChildProps>
  >(GetDemandByIdDocument, operationOptions);
}
export const OwnerDemandsDocument = gql`
  query OwnerDemands(
    $take: Int
    $skip: Int
    $team_id: String
    $status: String
    $name: [String!]
    $owner_username: [String!]
    $company_name: [String!]
    $type: [String!]
    $designer_status: [String!]
  ) {
    ownerDemands(
      take: $take
      team_id: $team_id
      status: $status
      skip: $skip
      name: $name
      owner_username: $owner_username
      company_name: $company_name
      type: $type
      designer_status: $designer_status
    ) {
      count
      list {
        id
        is_new
        is_new_ui
        designer_is_new
        name
        status
        finish_date
        created_date
        position
        type
        owner {
          id
          username
          mobile
          headimgurl
        }
        project {
          id
          title
        }
        order {
          id
        }
        cover
        cover_uri
      }
    }
  }
`;
export interface OwnerDemandsQuery {
  ownerDemands: Query["ownerDemands"];
}
export class OwnerDemandsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<OwnerDemandsQuery, OwnerDemandsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<OwnerDemandsQuery, OwnerDemandsQueryArgs>
        query={OwnerDemandsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OwnerDemandsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<OwnerDemandsQuery, OwnerDemandsQueryArgs>
> &
  TChildProps;
export function OwnerDemandsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OwnerDemandsQuery,
        OwnerDemandsQueryArgs,
        OwnerDemandsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OwnerDemandsQuery,
    OwnerDemandsQueryArgs,
    OwnerDemandsProps<TChildProps>
  >(OwnerDemandsDocument, operationOptions);
}
export const SortDemandsDocument = gql`
  mutation SortDemands($SortDemandList: SortDemandList!) {
    sortDemands(SortDemandList: $SortDemandList) {
      success
    }
  }
`;
export interface SortDemandsMutation {
  sortDemands: Mutation["sortDemands"];
}
export class SortDemandsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<SortDemandsMutation, SortDemandsMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<SortDemandsMutation, SortDemandsMutationArgs>
        mutation={SortDemandsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SortDemandsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<SortDemandsMutation, SortDemandsMutationArgs>
> &
  TChildProps;
export type SortDemandsMutationFn = ReactApollo.MutationFn<
  SortDemandsMutation,
  SortDemandsMutationArgs
>;
export function SortDemandsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SortDemandsMutation,
        SortDemandsMutationArgs,
        SortDemandsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SortDemandsMutation,
    SortDemandsMutationArgs,
    SortDemandsProps<TChildProps>
  >(SortDemandsDocument, operationOptions);
}
export const TopDemandByOwnerDocument = gql`
  mutation TopDemandByOwner($id: String!) {
    topDemandByOwner(id: $id) {
      id
      status
    }
  }
`;
export interface TopDemandByOwnerMutation {
  topDemandByOwner: Mutation["topDemandByOwner"];
}
export class TopDemandByOwnerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      TopDemandByOwnerMutation,
      TopDemandByOwnerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        TopDemandByOwnerMutation,
        TopDemandByOwnerMutationArgs
      >
        mutation={TopDemandByOwnerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type TopDemandByOwnerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    TopDemandByOwnerMutation,
    TopDemandByOwnerMutationArgs
  >
> &
  TChildProps;
export type TopDemandByOwnerMutationFn = ReactApollo.MutationFn<
  TopDemandByOwnerMutation,
  TopDemandByOwnerMutationArgs
>;
export function TopDemandByOwnerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        TopDemandByOwnerMutation,
        TopDemandByOwnerMutationArgs,
        TopDemandByOwnerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TopDemandByOwnerMutation,
    TopDemandByOwnerMutationArgs,
    TopDemandByOwnerProps<TChildProps>
  >(TopDemandByOwnerDocument, operationOptions);
}
export const ViewerDemandsDocument = gql`
  query ViewerDemands {
    viewer {
      id
      demands {
        id
        is_new
        is_new_ui
        designer_is_new
        name
        status
        finish_date
        created_date
        type
        owner {
          id
          username
          mobile
          headimgurl
        }
        project {
          id
          title
        }
        order {
          id
        }
        cover
        cover_uri
      }
    }
  }
`;
export interface ViewerDemandsQuery {
  viewer: Query["viewer"];
}
export class ViewerDemandsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerDemandsQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerDemandsQuery, any>
        query={ViewerDemandsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerDemandsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerDemandsQuery, any>
> &
  TChildProps;
export function ViewerDemandsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerDemandsQuery,
        any,
        ViewerDemandsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerDemandsQuery,
    any,
    ViewerDemandsProps<TChildProps>
  >(ViewerDemandsDocument, operationOptions);
}
export const CreateInvoiceDocument = gql`
  mutation CreateInvoice($CreateInvoice: CreateInvoice!) {
    CreateInvoice(CreateInvoice: $CreateInvoice) {
      id
    }
  }
`;
export interface CreateInvoiceMutation {
  CreateInvoice: Mutation["CreateInvoice"];
}
export class CreateInvoiceComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateInvoiceMutation, CreateInvoiceMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateInvoiceMutation, CreateInvoiceMutationArgs>
        mutation={CreateInvoiceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateInvoiceProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateInvoiceMutation, CreateInvoiceMutationArgs>
> &
  TChildProps;
export type CreateInvoiceMutationFn = ReactApollo.MutationFn<
  CreateInvoiceMutation,
  CreateInvoiceMutationArgs
>;
export function CreateInvoiceHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateInvoiceMutation,
        CreateInvoiceMutationArgs,
        CreateInvoiceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateInvoiceMutation,
    CreateInvoiceMutationArgs,
    CreateInvoiceProps<TChildProps>
  >(CreateInvoiceDocument, operationOptions);
}
export const CreateProductOrderDocument = gql`
  mutation CreateProductOrder($CreateProductOrder: CreateProductOrder!) {
    createProductOrder(CreateProductOrder: $CreateProductOrder) {
      id
      type
      detail
      status
      price
      old_price
      out_trade_no
    }
  }
`;
export interface CreateProductOrderMutation {
  createProductOrder: Mutation["createProductOrder"];
}
export class CreateProductOrderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateProductOrderMutation,
      CreateProductOrderMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateProductOrderMutation,
        CreateProductOrderMutationArgs
      >
        mutation={CreateProductOrderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateProductOrderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateProductOrderMutation,
    CreateProductOrderMutationArgs
  >
> &
  TChildProps;
export type CreateProductOrderMutationFn = ReactApollo.MutationFn<
  CreateProductOrderMutation,
  CreateProductOrderMutationArgs
>;
export function CreateProductOrderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateProductOrderMutation,
        CreateProductOrderMutationArgs,
        CreateProductOrderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateProductOrderMutation,
    CreateProductOrderMutationArgs,
    CreateProductOrderProps<TChildProps>
  >(CreateProductOrderDocument, operationOptions);
}
export const CreateSubscriptionOrderDocument = gql`
  mutation CreateSubscriptionOrder(
    $CreateSubscriptionOrder: CreateSubscriptionOrder!
  ) {
    createSubscriptionOrder(CreateSubscriptionOrder: $CreateSubscriptionOrder) {
      id
    }
  }
`;
export interface CreateSubscriptionOrderMutation {
  createSubscriptionOrder: Mutation["createSubscriptionOrder"];
}
export class CreateSubscriptionOrderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateSubscriptionOrderMutation,
      CreateSubscriptionOrderMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateSubscriptionOrderMutation,
        CreateSubscriptionOrderMutationArgs
      >
        mutation={CreateSubscriptionOrderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateSubscriptionOrderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateSubscriptionOrderMutation,
    CreateSubscriptionOrderMutationArgs
  >
> &
  TChildProps;
export type CreateSubscriptionOrderMutationFn = ReactApollo.MutationFn<
  CreateSubscriptionOrderMutation,
  CreateSubscriptionOrderMutationArgs
>;
export function CreateSubscriptionOrderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateSubscriptionOrderMutation,
        CreateSubscriptionOrderMutationArgs,
        CreateSubscriptionOrderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateSubscriptionOrderMutation,
    CreateSubscriptionOrderMutationArgs,
    CreateSubscriptionOrderProps<TChildProps>
  >(CreateSubscriptionOrderDocument, operationOptions);
}
export const GetOrderByIdDocument = gql`
  query GetOrderById($id: String!) {
    getOrderById(id: $id) {
      id
      type
      detail
      status
      price
      old_price
      out_trade_no
      items {
        id
        type
        detail
        price
        order_type
        out_trade_no
      }
    }
  }
`;
export interface GetOrderByIdQuery {
  getOrderById: Query["getOrderById"];
}
export class GetOrderByIdComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetOrderByIdQuery, GetOrderByIdQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetOrderByIdQuery, GetOrderByIdQueryArgs>
        query={GetOrderByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetOrderByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetOrderByIdQuery, GetOrderByIdQueryArgs>
> &
  TChildProps;
export function GetOrderByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetOrderByIdQuery,
        GetOrderByIdQueryArgs,
        GetOrderByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetOrderByIdQuery,
    GetOrderByIdQueryArgs,
    GetOrderByIdProps<TChildProps>
  >(GetOrderByIdDocument, operationOptions);
}
export const SignProductOrderDocument = gql`
  mutation SignProductOrder($SignProductOrder: SignProductOrder!) {
    signProductOrder(SignProductOrder: $SignProductOrder) {
      id
      uri
    }
  }
`;
export interface SignProductOrderMutation {
  signProductOrder: Mutation["signProductOrder"];
}
export class SignProductOrderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      SignProductOrderMutation,
      SignProductOrderMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        SignProductOrderMutation,
        SignProductOrderMutationArgs
      >
        mutation={SignProductOrderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SignProductOrderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    SignProductOrderMutation,
    SignProductOrderMutationArgs
  >
> &
  TChildProps;
export type SignProductOrderMutationFn = ReactApollo.MutationFn<
  SignProductOrderMutation,
  SignProductOrderMutationArgs
>;
export function SignProductOrderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SignProductOrderMutation,
        SignProductOrderMutationArgs,
        SignProductOrderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SignProductOrderMutation,
    SignProductOrderMutationArgs,
    SignProductOrderProps<TChildProps>
  >(SignProductOrderDocument, operationOptions);
}
export const SignWeChatDocument = gql`
  mutation SignWeChat($SignWeChatInput: SignWeChatInput!) {
    signWeChat(SignWeChatInput: $SignWeChatInput) {
      debug
      appId
      timestamp
      nonceStr
      signature
      jsApiList
    }
  }
`;
export interface SignWeChatMutation {
  signWeChat: Mutation["signWeChat"];
}
export class SignWeChatComponent extends React.Component<
  Partial<ReactApollo.MutationProps<SignWeChatMutation, SignWeChatMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<SignWeChatMutation, SignWeChatMutationArgs>
        mutation={SignWeChatDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SignWeChatProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<SignWeChatMutation, SignWeChatMutationArgs>
> &
  TChildProps;
export type SignWeChatMutationFn = ReactApollo.MutationFn<
  SignWeChatMutation,
  SignWeChatMutationArgs
>;
export function SignWeChatHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SignWeChatMutation,
        SignWeChatMutationArgs,
        SignWeChatProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SignWeChatMutation,
    SignWeChatMutationArgs,
    SignWeChatProps<TChildProps>
  >(SignWeChatDocument, operationOptions);
}
export const UpdateSubscriptionOrderDocument = gql`
  mutation UpdateSubscriptionOrder(
    $UpdateSubscriptionByOrder: UpdateSubscriptionByOrder!
  ) {
    updateSubscriptionOrder(
      UpdateSubscriptionByOrder: $UpdateSubscriptionByOrder
    ) {
      id
    }
  }
`;
export interface UpdateSubscriptionOrderMutation {
  updateSubscriptionOrder: Mutation["updateSubscriptionOrder"];
}
export class UpdateSubscriptionOrderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateSubscriptionOrderMutation,
      UpdateSubscriptionOrderMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateSubscriptionOrderMutation,
        UpdateSubscriptionOrderMutationArgs
      >
        mutation={UpdateSubscriptionOrderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateSubscriptionOrderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateSubscriptionOrderMutation,
    UpdateSubscriptionOrderMutationArgs
  >
> &
  TChildProps;
export type UpdateSubscriptionOrderMutationFn = ReactApollo.MutationFn<
  UpdateSubscriptionOrderMutation,
  UpdateSubscriptionOrderMutationArgs
>;
export function UpdateSubscriptionOrderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateSubscriptionOrderMutation,
        UpdateSubscriptionOrderMutationArgs,
        UpdateSubscriptionOrderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateSubscriptionOrderMutation,
    UpdateSubscriptionOrderMutationArgs,
    UpdateSubscriptionOrderProps<TChildProps>
  >(UpdateSubscriptionOrderDocument, operationOptions);
}
export const UploadPayImageDocument = gql`
  mutation UploadPayImage($UploadOrderImage: UploadOrderImage!) {
    uploadPayImage(UploadOrderImage: $UploadOrderImage) {
      id
      pay_uri
    }
  }
`;
export interface UploadPayImageMutation {
  uploadPayImage: Mutation["uploadPayImage"];
}
export class UploadPayImageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UploadPayImageMutation,
      UploadPayImageMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UploadPayImageMutation, UploadPayImageMutationArgs>
        mutation={UploadPayImageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UploadPayImageProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UploadPayImageMutation, UploadPayImageMutationArgs>
> &
  TChildProps;
export type UploadPayImageMutationFn = ReactApollo.MutationFn<
  UploadPayImageMutation,
  UploadPayImageMutationArgs
>;
export function UploadPayImageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UploadPayImageMutation,
        UploadPayImageMutationArgs,
        UploadPayImageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UploadPayImageMutation,
    UploadPayImageMutationArgs,
    UploadPayImageProps<TChildProps>
  >(UploadPayImageDocument, operationOptions);
}
export const ViewerOrdersDocument = gql`
  query ViewerOrders($take: Int, $skip: Int) {
    viewer {
      orders(take: $take, skip: $skip) {
        count
        list {
          id
          status
        }
      }
    }
  }
`;
export interface ViewerOrdersQuery {
  viewer: Query["viewer"];
}
export class ViewerOrdersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerOrdersQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerOrdersQuery, any>
        query={ViewerOrdersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerOrdersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerOrdersQuery, any>
> &
  TChildProps;
export function ViewerOrdersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerOrdersQuery,
        any,
        ViewerOrdersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerOrdersQuery,
    any,
    ViewerOrdersProps<TChildProps>
  >(ViewerOrdersDocument, operationOptions);
}
export const ViewerTeamOrdersDocument = gql`
  query ViewerTeamOrders($skip: Int = 0, $take: Int = 25) {
    viewer {
      id
      team {
        id
        order_count
        owner {
          id
        }
        orders(skip: $skip, take: $take) {
          id
          type
          detail
          status
          price
          old_price
          out_trade_no
          pay_type
          remark
          reason
          uri
          pay_uri
          cycle
          cycle_name
          created_date
          open_invoice
          owner {
            id
            username
            headimgurl
          }
          items {
            id
            type
            detail
            price
            order_type
            out_trade_no
          }
        }
      }
    }
  }
`;
export interface ViewerTeamOrdersQuery {
  viewer: Query["viewer"];
}
export class ViewerTeamOrdersComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<ViewerTeamOrdersQuery, any>
  >
> {
  render() {
    return (
      <ReactApollo.Query<ViewerTeamOrdersQuery, any>
        query={ViewerTeamOrdersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerTeamOrdersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerTeamOrdersQuery, any>
> &
  TChildProps;
export function ViewerTeamOrdersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerTeamOrdersQuery,
        any,
        ViewerTeamOrdersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerTeamOrdersQuery,
    any,
    ViewerTeamOrdersProps<TChildProps>
  >(ViewerTeamOrdersDocument, operationOptions);
}
export const PackagesDocument = gql`
  query Packages($take: Int) {
    packages(take: $take, order: { created_date: "ASC" }) {
      count
      list {
        id
        name
        price
        balance
        status
        introduction
      }
    }
  }
`;
export interface PackagesQuery {
  packages: Query["packages"];
}
export class PackagesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<PackagesQuery, PackagesQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<PackagesQuery, PackagesQueryArgs>
        query={PackagesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PackagesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<PackagesQuery, PackagesQueryArgs>
> &
  TChildProps;
export function PackagesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PackagesQuery,
        PackagesQueryArgs,
        PackagesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PackagesQuery,
    PackagesQueryArgs,
    PackagesProps<TChildProps>
  >(PackagesDocument, operationOptions);
}
export const CreatePlanRecordDocument = gql`
  mutation CreatePlanRecord($CreatePlanRecord: CreatePlanRecord!) {
    createPlanRecord(CreatePlanRecord: $CreatePlanRecord) {
      id
      number
      cycle
      price
      out_trade_no
      pay_type
      uri
    }
  }
`;
export interface CreatePlanRecordMutation {
  createPlanRecord: Mutation["createPlanRecord"];
}
export class CreatePlanRecordComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreatePlanRecordMutation,
      CreatePlanRecordMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreatePlanRecordMutation,
        CreatePlanRecordMutationArgs
      >
        mutation={CreatePlanRecordDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreatePlanRecordProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreatePlanRecordMutation,
    CreatePlanRecordMutationArgs
  >
> &
  TChildProps;
export type CreatePlanRecordMutationFn = ReactApollo.MutationFn<
  CreatePlanRecordMutation,
  CreatePlanRecordMutationArgs
>;
export function CreatePlanRecordHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreatePlanRecordMutation,
        CreatePlanRecordMutationArgs,
        CreatePlanRecordProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreatePlanRecordMutation,
    CreatePlanRecordMutationArgs,
    CreatePlanRecordProps<TChildProps>
  >(CreatePlanRecordDocument, operationOptions);
}
export const GetPlanRecordByIdDocument = gql`
  query GetPlanRecordById($id: String!) {
    getPlanRecordById(id: $id) {
      id
      status
    }
  }
`;
export interface GetPlanRecordByIdQuery {
  getPlanRecordById: Query["getPlanRecordById"];
}
export class GetPlanRecordByIdComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetPlanRecordByIdQuery, GetPlanRecordByIdQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetPlanRecordByIdQuery, GetPlanRecordByIdQueryArgs>
        query={GetPlanRecordByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetPlanRecordByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetPlanRecordByIdQuery, GetPlanRecordByIdQueryArgs>
> &
  TChildProps;
export function GetPlanRecordByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetPlanRecordByIdQuery,
        GetPlanRecordByIdQueryArgs,
        GetPlanRecordByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetPlanRecordByIdQuery,
    GetPlanRecordByIdQueryArgs,
    GetPlanRecordByIdProps<TChildProps>
  >(GetPlanRecordByIdDocument, operationOptions);
}
export const ProjectsDocument = gql`
  query Projects($take: Int, $type: String) {
    projects(take: $take, type: $type) {
      count
      list {
        id
        title
        uri
        type
        content
        description
        cost_day
        item_type
      }
    }
  }
`;
export interface ProjectsQuery {
  projects: Query["projects"];
}
export class ProjectsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ProjectsQuery, ProjectsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<ProjectsQuery, ProjectsQueryArgs>
        query={ProjectsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ProjectsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ProjectsQuery, ProjectsQueryArgs>
> &
  TChildProps;
export function ProjectsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ProjectsQuery,
        ProjectsQueryArgs,
        ProjectsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ProjectsQuery,
    ProjectsQueryArgs,
    ProjectsProps<TChildProps>
  >(ProjectsDocument, operationOptions);
}
export const RequestUploadOssFileDocument = gql`
  query RequestUploadOSSFile($file_name: String!) {
    requestUploadOSSFile(file_name: $file_name) {
      access_key_id
      policy
      signature
      key
      host
      expiration
      dir
      callback
    }
  }
`;
export interface RequestUploadOssFileQuery {
  requestUploadOSSFile: Query["requestUploadOSSFile"];
}
export class RequestUploadOssFileComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      RequestUploadOssFileQuery,
      RequestUploadOssFileQueryArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        RequestUploadOssFileQuery,
        RequestUploadOssFileQueryArgs
      >
        query={RequestUploadOssFileDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RequestUploadOssFileProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    RequestUploadOssFileQuery,
    RequestUploadOssFileQueryArgs
  >
> &
  TChildProps;
export function RequestUploadOssFileHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RequestUploadOssFileQuery,
        RequestUploadOssFileQueryArgs,
        RequestUploadOssFileProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RequestUploadOssFileQuery,
    RequestUploadOssFileQueryArgs,
    RequestUploadOssFileProps<TChildProps>
  >(RequestUploadOssFileDocument, operationOptions);
}
export const UpdateSubscriptionDocument = gql`
  mutation UpdateSubscription($UpdateSubscription: UpdateSubscription!) {
    updateSubscription(UpdateSubscription: $UpdateSubscription) {
      id
      number
      cycle
      price
      out_trade_no
      pay_type
      uri
    }
  }
`;
export interface UpdateSubscriptionMutation {
  updateSubscription: Mutation["updateSubscription"];
}
export class UpdateSubscriptionComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateSubscriptionMutation,
      UpdateSubscriptionMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateSubscriptionMutation,
        UpdateSubscriptionMutationArgs
      >
        mutation={UpdateSubscriptionDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateSubscriptionProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationArgs
  >
> &
  TChildProps;
export type UpdateSubscriptionMutationFn = ReactApollo.MutationFn<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationArgs
>;
export function UpdateSubscriptionHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateSubscriptionMutation,
        UpdateSubscriptionMutationArgs,
        UpdateSubscriptionProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationArgs,
    UpdateSubscriptionProps<TChildProps>
  >(UpdateSubscriptionDocument, operationOptions);
}
export const ViewerSubscriptionsDocument = gql`
  query ViewerSubscriptions {
    viewer {
      id
      team {
        id
        subscriptions {
          id
          name
          count
          end_date
          cycle_name
          start_date
        }
      }
    }
  }
`;
export interface ViewerSubscriptionsQuery {
  viewer: Query["viewer"];
}
export class ViewerSubscriptionsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      ViewerSubscriptionsQuery,
      any
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<ViewerSubscriptionsQuery, any>
        query={ViewerSubscriptionsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerSubscriptionsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerSubscriptionsQuery, any>
> &
  TChildProps;
export function ViewerSubscriptionsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerSubscriptionsQuery,
        any,
        ViewerSubscriptionsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerSubscriptionsQuery,
    any,
    ViewerSubscriptionsProps<TChildProps>
  >(ViewerSubscriptionsDocument, operationOptions);
}
export const AddTeamDocument = gql`
  mutation AddTeam($AddTeam: AddTeam!) {
    addTeam(AddTeam: $AddTeam) {
      id
    }
  }
`;
export interface AddTeamMutation {
  addTeam: Mutation["addTeam"];
}
export class AddTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<AddTeamMutation, AddTeamMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<AddTeamMutation, AddTeamMutationArgs>
        mutation={AddTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AddTeamMutation, AddTeamMutationArgs>
> &
  TChildProps;
export type AddTeamMutationFn = ReactApollo.MutationFn<
  AddTeamMutation,
  AddTeamMutationArgs
>;
export function AddTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddTeamMutation,
        AddTeamMutationArgs,
        AddTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddTeamMutation,
    AddTeamMutationArgs,
    AddTeamProps<TChildProps>
  >(AddTeamDocument, operationOptions);
}
export const ChangeTeamDocument = gql`
  mutation ChangeTeam($ChangeTeam: ChangeTeam!) {
    changeTeam(ChangeTeam: $ChangeTeam) {
      id
    }
  }
`;
export interface ChangeTeamMutation {
  changeTeam: Mutation["changeTeam"];
}
export class ChangeTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<ChangeTeamMutation, ChangeTeamMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<ChangeTeamMutation, ChangeTeamMutationArgs>
        mutation={ChangeTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<ChangeTeamMutation, ChangeTeamMutationArgs>
> &
  TChildProps;
export type ChangeTeamMutationFn = ReactApollo.MutationFn<
  ChangeTeamMutation,
  ChangeTeamMutationArgs
>;
export function ChangeTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeTeamMutation,
        ChangeTeamMutationArgs,
        ChangeTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeTeamMutation,
    ChangeTeamMutationArgs,
    ChangeTeamProps<TChildProps>
  >(ChangeTeamDocument, operationOptions);
}
export const ChangeTeamOwnerDocument = gql`
  mutation ChangeTeamOwner($user_id: String!) {
    changeTeamOwner(user_id: $user_id) {
      id
      owner {
        id
        username
        headimgurl
      }
    }
  }
`;
export interface ChangeTeamOwnerMutation {
  changeTeamOwner: Mutation["changeTeamOwner"];
}
export class ChangeTeamOwnerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeTeamOwnerMutation,
      ChangeTeamOwnerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeTeamOwnerMutation,
        ChangeTeamOwnerMutationArgs
      >
        mutation={ChangeTeamOwnerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeTeamOwnerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<ChangeTeamOwnerMutation, ChangeTeamOwnerMutationArgs>
> &
  TChildProps;
export type ChangeTeamOwnerMutationFn = ReactApollo.MutationFn<
  ChangeTeamOwnerMutation,
  ChangeTeamOwnerMutationArgs
>;
export function ChangeTeamOwnerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeTeamOwnerMutation,
        ChangeTeamOwnerMutationArgs,
        ChangeTeamOwnerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeTeamOwnerMutation,
    ChangeTeamOwnerMutationArgs,
    ChangeTeamOwnerProps<TChildProps>
  >(ChangeTeamOwnerDocument, operationOptions);
}
export const CreateTeamDocument = gql`
  mutation CreateTeam($CreateTeam: CreateTeam!) {
    createTeam(CreateTeam: $CreateTeam) {
      id
    }
  }
`;
export interface CreateTeamMutation {
  createTeam: Mutation["createTeam"];
}
export class CreateTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateTeamMutation, CreateTeamMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateTeamMutation, CreateTeamMutationArgs>
        mutation={CreateTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateTeamMutation, CreateTeamMutationArgs>
> &
  TChildProps;
export type CreateTeamMutationFn = ReactApollo.MutationFn<
  CreateTeamMutation,
  CreateTeamMutationArgs
>;
export function CreateTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamMutation,
        CreateTeamMutationArgs,
        CreateTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamMutation,
    CreateTeamMutationArgs,
    CreateTeamProps<TChildProps>
  >(CreateTeamDocument, operationOptions);
}
export const CreateTeamUriDocument = gql`
  query CreateTeamUri {
    createTeamUri {
      uri
    }
  }
`;
export interface CreateTeamUriQuery {
  createTeamUri: Query["createTeamUri"];
}
export class CreateTeamUriComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CreateTeamUriQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<CreateTeamUriQuery, any>
        query={CreateTeamUriDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamUriProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CreateTeamUriQuery, any>
> &
  TChildProps;
export function CreateTeamUriHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamUriQuery,
        any,
        CreateTeamUriProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamUriQuery,
    any,
    CreateTeamUriProps<TChildProps>
  >(CreateTeamUriDocument, operationOptions);
}
export const DeleteMemberDocument = gql`
  mutation DeleteMember($user_id: String!) {
    deleteMember(user_id: $user_id) {
      id
    }
  }
`;
export interface DeleteMemberMutation {
  deleteMember: Mutation["deleteMember"];
}
export class DeleteMemberComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteMemberMutation, DeleteMemberMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteMemberMutation, DeleteMemberMutationArgs>
        mutation={DeleteMemberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteMemberProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteMemberMutation, DeleteMemberMutationArgs>
> &
  TChildProps;
export type DeleteMemberMutationFn = ReactApollo.MutationFn<
  DeleteMemberMutation,
  DeleteMemberMutationArgs
>;
export function DeleteMemberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteMemberMutation,
        DeleteMemberMutationArgs,
        DeleteMemberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteMemberMutation,
    DeleteMemberMutationArgs,
    DeleteMemberProps<TChildProps>
  >(DeleteMemberDocument, operationOptions);
}
export const GetTeamByCodeDocument = gql`
  query GetTeamByCode($code: String!) {
    getTeamByCode(code: $code) {
      id
      name
    }
  }
`;
export interface GetTeamByCodeQuery {
  getTeamByCode: Query["getTeamByCode"];
}
export class GetTeamByCodeComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetTeamByCodeQuery, GetTeamByCodeQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetTeamByCodeQuery, GetTeamByCodeQueryArgs>
        query={GetTeamByCodeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetTeamByCodeProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetTeamByCodeQuery, GetTeamByCodeQueryArgs>
> &
  TChildProps;
export function GetTeamByCodeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetTeamByCodeQuery,
        GetTeamByCodeQueryArgs,
        GetTeamByCodeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetTeamByCodeQuery,
    GetTeamByCodeQueryArgs,
    GetTeamByCodeProps<TChildProps>
  >(GetTeamByCodeDocument, operationOptions);
}
export const OutTeamDocument = gql`
  mutation OutTeam {
    outTeam {
      id
    }
  }
`;
export interface OutTeamMutation {
  outTeam: Mutation["outTeam"];
}
export class OutTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<OutTeamMutation, any>>
> {
  render() {
    return (
      <ReactApollo.Mutation<OutTeamMutation, any>
        mutation={OutTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OutTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<OutTeamMutation, any>
> &
  TChildProps;
export type OutTeamMutationFn = ReactApollo.MutationFn<
  OutTeamMutation,
  any
>;
export function OutTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OutTeamMutation,
        any,
        OutTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OutTeamMutation,
    any,
    OutTeamProps<TChildProps>
  >(OutTeamDocument, operationOptions);
}
export const ViewerTeamDocument = gql`
  query ViewerTeam {
    viewer {
      id
      team {
        id
        name
        balance
        plan
        plan_count
        plan_end_date
        now_plan_end_date
        owner {
          id
        }
        cycle_name
        cycle
        members {
          id
          created_date
          owner {
            id
            username
            mobile
            headimgurl
          }
        }
        subscriptions {
          id
          name
          count
          end_date
          cycle_name
          cycle
          start_date
        }
        now_subscription {
          id
          name
          count
          end_date
          cycle_name
          cycle
          start_date
          price
        }
      }
    }
  }
`;
export interface ViewerTeamQuery {
  viewer: Query["viewer"];
}
export class ViewerTeamComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerTeamQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerTeamQuery, any>
        query={ViewerTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerTeamProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerTeamQuery, any>
> &
  TChildProps;
export function ViewerTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerTeamQuery,
        any,
        ViewerTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerTeamQuery,
    any,
    ViewerTeamProps<TChildProps>
  >(ViewerTeamDocument, operationOptions);
}
export const CreateMobileCodeDocument = gql`
  mutation CreateMobileCode($mobile: String!, $country_number: String!) {
    createMobileCode(mobile: $mobile, country_number: $country_number) {
      success
    }
  }
`;
export interface CreateMobileCodeMutation {
  createMobileCode: Mutation["createMobileCode"];
}
export class CreateMobileCodeComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateMobileCodeMutation,
      CreateMobileCodeMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateMobileCodeMutation,
        CreateMobileCodeMutationArgs
      >
        mutation={CreateMobileCodeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateMobileCodeProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateMobileCodeMutation,
    CreateMobileCodeMutationArgs
  >
> &
  TChildProps;
export type CreateMobileCodeMutationFn = ReactApollo.MutationFn<
  CreateMobileCodeMutation,
  CreateMobileCodeMutationArgs
>;
export function CreateMobileCodeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateMobileCodeMutation,
        CreateMobileCodeMutationArgs,
        CreateMobileCodeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateMobileCodeMutation,
    CreateMobileCodeMutationArgs,
    CreateMobileCodeProps<TChildProps>
  >(CreateMobileCodeDocument, operationOptions);
}
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      id
      username
      headimgurl
      is_mobile
      team {
        oss_card_uri
        id
        name
        balance
        plan
        designer {
          id
          username
          headimgurl
        }
        designerAdviser {
          id
          username
          headimgurl
        }
        owner {
          id
        }
        plan_end_date
      }
      teams {
        id
        name
        plan
        plan_end_date
        owner {
          id
          username
          headimgurl
        }
      }
    }
  }
`;
export interface ViewerQuery {
  viewer: Query["viewer"];
}
export class ViewerComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerQuery, any>
        query={ViewerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerQuery, any>
> &
  TChildProps;
export function ViewerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerQuery,
        any,
        ViewerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerQuery,
    any,
    ViewerProps<TChildProps>
  >(ViewerDocument, operationOptions);
}

import React from 'react';
import { Modal, Upload, Icon, Button } from 'antd';

import styles from './index.module.css';
// import { BalancePayComponent } from '../../apollo';
import { message } from '../message';
import { withRouter } from 'react-router';
import bank from './bank@2x.png';
import { up_load_life_photo_options } from '../../lib/upload';
import { UploadPayImageComponent } from '../../apollo';

const { Dragger } = Upload;

class BankPayModal extends React.Component<any> {
  handleSubmit = async (send: any) => {
    if (!this.state.uri) {
      return message.error(new Error('请上传文件后提交'));
    }
    try {
      const { id } = this.props;
      await send({
        variables: {
          UploadOrderImage: { pay_uri: this.state.uri, order_id: id },
        },
      });
      message.success('提交成功');
      this.props.history.push(this.props.router || '/service');
      // this.props.history.push('/account');
    } catch (e) {
      message.error(e);
    }
  };

  state = {
    uri: '',
  };

  render() {
    const { price } = this.props;
    return (
      <>
        <Modal
          visible={this.props.visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          onCancel={this.props.onCancel}
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.title}>
              <img src={bank} alt="bank" className={styles.bank_image} />
              对公账户打款
            </div>
            <div>
              <div style={{ marginTop: '30px' }} className={styles.big_title}>
                第一步
              </div>
              <div style={{ marginTop: '8px' }} className={styles.big_title}>
                将<span style={{ color: '#7CB2B8' }}> {price} 元 </span>
                汇款至下述账户
              </div>
              <div className={styles.bank_info}>
                账户名：天津奈夫科技合伙企业（有限合伙） <br />
                开户行：招商银行股份有限公司北京中关村支行 <br />
                账号：110935220110101
              </div>
              <div style={{ marginTop: '30px' }} className={styles.big_title}>
                第二步
              </div>
              <div style={{ marginTop: '8px' }} className={styles.big_title}>
                并将付款成功的页面截图进行上传作为凭证
              </div>
              <div className={styles.desc}>
                截图中能体现付款金额、时间、付款方账户名称等信息即可
              </div>
              <Dragger
                disabled={this.state.uri.length > 0}
                {...up_load_life_photo_options((uri, file) =>
                  this.setState({
                    uri: uri,
                  }),
                )}
                accept=".png,.jpg"
              >
                <p className="ant-upload-drag-icon">
                  <Icon style={{ color: '#7CB2B8' }} type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">支持扩展名：.jpg .png</p>
              </Dragger>
              <UploadPayImageComponent>
                {send => {
                  return (
                    <Button
                      className={`linear-gradient-gray ${styles.next}`}
                      onClick={() => this.handleSubmit(send)}
                      type="primary"
                    >
                      完成
                    </Button>
                  );
                }}
              </UploadPayImageComponent>
              <div className={styles.bottom_desc}>
                完成后，敬请联系您的设计顾问 <br />
                我们将于1个工作日内查核对汇款信息并更新订单状态
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(BankPayModal);

import React from 'react';
import {
  Button,
  Form,
  Input,
  Radio,
  Upload,
  Icon,
  Checkbox,
} from 'antd';
import queryString from 'query-string';
import SuccessModal from '../../components/success-modal';
import SureDemandModal from '../../components/sure-demand-modal';

import { CreateDemandComponent } from '../../apollo';
import { message } from '../../components/message';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import tip from './tip@2x.png';
import moment from 'moment';
import { up_load_life_photo_options } from '../../lib/upload';
import { getFilesName } from '../../lib';
// import warn from '../../image/warn@2x.png';
import { findProjectItem } from '../../db/product.db';
// import { QrCode } from '../choose-type-new/qr_code';
import { oss_uri } from '../../config';
import { QrCodeText } from '../choose-type-new/qr_code_text';

const { TextArea } = Input;

export class LittleCard extends React.Component<any> {
  render() {
    const { uri, title, content, description } = this.props;
    return (
      <div className={styles.card_container}>
        {this.props.children}
        <img className={styles.card_img} alt="" src={oss_uri + uri} />
        <div>
          <div className={styles.card_title}>{title}</div>
          <div className={styles.card_content}>{content}</div>
          <div className={styles.card_description}>{description}</div>
        </div>
      </div>
    );
  }
}

class Tip extends React.Component<any> {
  render() {
    const { style } = this.props;
    return (
      <div style={style} className={styles.tip_container}>
        <img className={styles.tip_img} alt="" src={tip} />
        {this.props.children}
      </div>
    );
  }
}

class CreateDemand extends React.Component<any> {
  state = {
    visible: false,
    sure_visible: false,
    values: {},
    uris: [],
    ui_uris: [],
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        this.setState({
          sure_visible: true,
          values,
        });
      }
    });
  };

  sure = async (send: any) => {
    this.setState({
      sure_visible: false,
    });
    try {
      // color_description: color_preference!
      const project_name = queryString.parse(window.location.search).type;
      const { values, uris, ui_uris }: any = this.state;
      const { data } = await send({
        variables: {
          CreateDemand: {
            ...values,
            project_name,
            size_description:
              values.choose_size === '其他尺寸规格'
                ? values.size_description
                : values.choose_size,
            description_files: getFilesName(uris, values.description_files),
            ui_description_files: getFilesName(
              ui_uris,
              values.ui_description_files,
            ),
            file_format: values.file_format.join(','),
            choose_size: undefined,
          },
        },
      });
      // message.success('创建成功');
      const { form } = this.props;
      const { resetFields } = form;
      resetFields();
      const date = moment(data && data.createDemand.finish_date).format(
        'YYYY-MM-DD HH:mm',
      );
      // const date = moment(data && data.createDemand.finish_date).format(
      //   'YYYY-MM-DD HH:mm:ss',
      // );
      const id = data && data.createDemand.id;
      const search = queryString.parse(window.location.search);
      // this.setState({ visible: true });
      // this.props.history.push('/service');
      this.props.history.push(
        `/choose-plan?date=${date}&id=${id}&type=${search.type}`,
      );
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const search = queryString.parse(window.location.search);
    const item = findProjectItem(String(search.type));
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { visible, uris, ui_uris, sure_visible } = this.state;
    const uploadButton = (
      <div>
        <Button className="btn_upload">
          <Icon type="plus" />
          {/* <div className="ant-upload-text">上传</div> */}
          上传文件
        </Button>
        <span className="file_desc">
          支持pdf及word，jpg格式，.zip 和 .rar格式，.ppt 和 .pptx
        </span>
      </div>
    );
    return (
      <CreateDemandComponent>
        {(send, { loading, data }) => {
          return (
            <div>
              <HeaderLayout>
                <div style={{ position: 'relative' }}>
                  <div className={styles.title}>创建新的设计需求</div>
                  <div className={styles.desc}>
                    详细、规范的描述您的设计需求，能够大幅提高设计方案的精准度
                  </div>
                  {/* <QrCode /> */}
                  <QrCodeText />
                </div>
                <SuccessModal
                  visible={visible}
                  desc={
                    <>
                      `您可以在需求队列里查看需求进程。`
                      <br /> `现在系统预计
                      {moment(data && data.createDemand.finish_date).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                      完成，如有变动，会由设计顾问与您联系。`
                    </>
                  }
                />
                <SureDemandModal
                  onCancel={() => this.setState({ sure_visible: false })}
                  onSure={this.sure.bind(this, send)}
                  visible={sure_visible}
                />
                {/* <Modal
                  visible={this.state.sure_visible}
                  className={`modal-cover modal-cover-body`}
                  width={600}
                  onCancel={() => this.setState({ sure_visible: false })}
                  footer={null}
                  title={null}
                >
                  <div className={styles.modal_container}>
                    <div className={styles.modal_title}>
                      <img className={styles.warn_img} src={warn} alt="" />
                      请确认提交该需求吗？
                    </div>
                    <div className={styles.modal_desc}>
                      请确保您需要出现在交付物上的所有文案内容都已填写完成；
                      <br />
                      如提交需求后修改文案内容，风格偏好等，交付时间可能会顺延
                    </div>
                    <Button
                      className={`linear-gradient-gray ${styles.sure2} normal_button`}
                      loading={loading}
                      onClick={this.sure.bind(this, send)}
                      type="primary"
                    >
                      确认提交
                    </Button>
                    <br />
                    <br />
                    <Button
                      className={`${styles.aggress_sure} normal_button`}
                      onClick={() => this.setState({ sure_visible: false })}
                    >
                      再完善一下
                    </Button>
                  </div>
                </Modal> */}
                <div className={styles.container}>
                  {/* <div className={styles.title}>新增需求</div> */}
                  <div
                    style={{
                      display: 'flex',
                      padding: '30px',
                      marginTop: '30px',
                    }}
                  >
                    <div className={styles.left}>
                      <Form className="login-form">
                        <Form.Item label="需求名称">
                          {getFieldDecorator('name', {
                            rules: [
                              { required: true, message: '请输入需求名称!' },
                            ],
                          })(
                            <Input
                              maxLength={20}
                              className="king_normal_input"
                              placeholder="例：双11大促食品类Banner设计"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('name') &&
                              getFieldValue('name').length) ||
                              0}
                            /20
                          </span>
                        </Form.Item>
                        <Form.Item label="文案内容、图片素材">
                          {getFieldDecorator('description', {
                            rules: [
                              { required: true, message: '请输入需求描述!' },
                            ],
                          })(
                            <TextArea
                              rows={4}
                              maxLength={5000}
                              className="king_normal_text_area"
                              placeholder="输入在该物料中需要出现的所有文案内容"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('description') &&
                              getFieldValue('description').length) ||
                              0}
                            /5000
                          </span>
                        </Form.Item>
                        <div>
                          或上传已有的需求文档，以及设计稿中需出现的文案、素材图片等，最多可以上传5个文件
                        </div>
                        <br />
                        <Form.Item>
                          {getFieldDecorator('description_files')(
                            <Upload
                              {...up_load_life_photo_options((uri, file) =>
                                this.setState({
                                  uris: [...uris, { [uri]: file.name }],
                                }),
                              )}
                              accept=".pdf,.jpg,.docx,.doc,.rar,.zip,.ppt,.pptx"
                              defaultFileList={
                                getFieldValue('description_files') &&
                                getFieldValue('description_files').fileList
                              }
                            >
                              {((getFieldValue('description_files') &&
                                getFieldValue('description_files').fileList
                                  .length < 5) ||
                                !getFieldValue('description_files')) &&
                                uploadButton}
                            </Upload>,
                          )}
                        </Form.Item>
                        <Form.Item label="视觉风格描述">
                          {getFieldDecorator('ui_description', {
                            rules: [
                              {
                                required: true,
                                message: '请输入视觉风格描述!',
                              },
                            ],
                          })(
                            <Input
                              maxLength={20}
                              className="king_normal_input"
                              placeholder="可列举一些关键词，比如情绪、风格、针对的人群等"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('ui_description') &&
                              getFieldValue('ui_description').length) ||
                              0}
                            /20
                          </span>
                        </Form.Item>
                        <div>
                          如果有明确的参考案例，会加快Nef.Design的设计速度，最多可上传5个文件
                        </div>
                        <br />
                        <Form.Item>
                          {getFieldDecorator('ui_description_files')(
                            <Upload
                              {...up_load_life_photo_options((uri, file) =>
                                this.setState({
                                  ui_uris: [...ui_uris, { [uri]: file.name }],
                                }),
                              )}
                              accept=".pdf,.jpg,.docx,.doc,.rar,.zip"
                              defaultFileList={
                                getFieldValue('ui_description_files') &&
                                getFieldValue('ui_description_files').fileList
                              }
                            >
                              {((getFieldValue('ui_description_files') &&
                                getFieldValue('ui_description_files').fileList
                                  .length < 5) ||
                                !getFieldValue('ui_description_files')) &&
                                uploadButton}
                            </Upload>,
                          )}
                        </Form.Item>
                        <Form.Item label="色彩偏好">
                          {getFieldDecorator('color_description')(
                            <Input
                              maxLength={20}
                              className="king_normal_input"
                              placeholder="例如：品牌色，红色系，黑金风格等；如没有，可以不填"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('color_description') &&
                              getFieldValue('color_description').length) ||
                              0}
                            /20
                          </span>
                        </Form.Item>
                        {item.sizes ? (
                          <Form.Item label="尺寸规格">
                            <>
                              <span style={{ color: '#AEB1B5' }}>
                                如果有明确的参考案例，我们能为您交付更精准的设计方案，最多可上传5个文件
                              </span>
                              {getFieldDecorator('choose_size', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择尺寸规格!',
                                  },
                                ],
                              })(
                                <Radio.Group>
                                  {item.sizes.map(({ value, desc }: any) => (
                                    <>
                                      <Radio value={value}>
                                        {value}{' '}
                                        <span
                                          style={{
                                            color: '#AEB1B5',
                                            paddingLeft: '20px',
                                          }}
                                        >
                                          {desc}
                                        </span>
                                      </Radio>
                                      <br />
                                    </>
                                  ))}
                                </Radio.Group>,
                              )}
                            </>
                          </Form.Item>
                        ) : (
                          <Form.Item label="尺寸规格">
                            <>
                              <span style={{ color: '#AEB1B5' }}>
                                如果有明确的参考案例，我们能为您交付更精准的设计方案，最多可上传5个文件
                              </span>
                              {getFieldDecorator('choose_size', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择尺寸规格!',
                                  },
                                ],
                              })(
                                <Radio.Group>
                                  <Radio value="宽1200px, 高900px">
                                    宽1200px, 高900px
                                  </Radio>
                                  <span style={{ color: '#AEB1B5' }}>
                                    常见的 4：3 规格
                                  </span>
                                  <br />
                                  <Radio value="宽1920px, 高1080px">
                                    宽1920px, 高1080px
                                  </Radio>
                                  <span style={{ color: '#AEB1B5' }}>
                                    常见的 16：9 规格
                                  </span>
                                  <br />
                                  <Radio value="宽900px, 高383px">
                                    宽383px, 高900px
                                  </Radio>
                                  <span style={{ color: '#AEB1B5' }}>
                                    公众号封面首图
                                  </span>
                                  <br />
                                  <Radio value="宽1024px, 高768px">
                                    宽1024px, 高768px
                                  </Radio>
                                  <span style={{ color: '#AEB1B5' }}>
                                    横版推文配图
                                  </span>
                                  <br />
                                  <Radio value="宽600px, 高200px">
                                    宽600px, 高200px
                                  </Radio>
                                  <span style={{ color: '#AEB1B5' }}>
                                    超链接配图尺寸
                                  </span>
                                  <br />
                                  <Radio value="其他尺寸规格">
                                    其他尺寸规格
                                  </Radio>
                                  <br />
                                </Radio.Group>,
                              )}
                            </>
                          </Form.Item>
                        )}
                        {getFieldValue('choose_size') === '其他尺寸规格' && (
                          <Form.Item>
                            {getFieldDecorator('size_description', {
                              rules: [
                                { required: true, message: '请输入特殊要求!' },
                              ],
                            })(
                              <Input
                                maxLength={20}
                                className="king_normal_input"
                                placeholder="请输入要求"
                              />,
                            )}
                            <span className="form_right">
                              {(getFieldValue('size_description') &&
                                getFieldValue('size_description').length) ||
                                0}
                              /20
                            </span>
                          </Form.Item>
                        )}
                        <Form.Item label="源文件格式要求">
                          <div>
                            <span style={{ color: '#AEB1B5' }}>
                              图片格式（可多选）
                            </span>
                            <br />
                            <Form.Item>
                              {getFieldDecorator('file_format', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选图片格式要求!',
                                  },
                                ],
                              })(
                                <Checkbox.Group>
                                  <Checkbox value=".jpg/jpeg">
                                    .jpg/jpeg
                                  </Checkbox>
                                  <br />
                                  <br />
                                  <Checkbox value=".png">.png</Checkbox>
                                  <br />
                                  <br />
                                  <Checkbox value=".pdf">.pdf</Checkbox>
                                </Checkbox.Group>,
                              )}
                            </Form.Item>
                          </div>
                          <div>
                            <span style={{ color: '#AEB1B5' }}>源文件格式</span>
                            <br />
                            {getFieldDecorator('original_file_format', {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择源文件格式要求!',
                                },
                              ],
                            })(
                              <Radio.Group>
                                <Radio value="不做特定要求">不做特定要求</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将根据您的需求自行选择适合的设计工具进行设计
                                </span>
                                <br />
                                <Radio value=".psd">.psd</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将使用 Adobe Photoshop进行设计
                                </span>
                                <br />
                                <Radio value=".ai">.ai</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将使用 Adobe Illustrator进行设计
                                </span>
                                <br />
                                <Radio value=".sketch">.sketch</Radio>
                                <span style={{ color: '#AEB1B5' }}>
                                  设计师将使用 Sketch 进行设计
                                </span>
                                <br />
                              </Radio.Group>,
                            )}{' '}
                          </div>
                        </Form.Item>
                        <Form.Item label="其他要求说明与备注">
                          {getFieldDecorator('remark')(
                            <TextArea
                              rows={4}
                              maxLength={100}
                              className="king_normal_text_area"
                              placeholder="请填写其他特殊要求"
                            />,
                          )}
                          <span className="form_right">
                            {(getFieldValue('remark') &&
                              getFieldValue('remark').length) ||
                              0}
                            /100
                          </span>
                        </Form.Item>
                      </Form>
                    </div>
                    <div>
                      <Tip style={{ marginTop: '46px' }}>
                        · 为您的需求命名，帮助您在需求队列中分辨出不同的需求。
                        <br />· 建议的格式为：内容+物料类型，如“双十一促销海报”
                      </Tip>
                      <Tip style={{ marginTop: '70px' }}>
                        · 您物料中需要出现的所有文案都需要交接给我们 <br />
                        ·
                        建议您向我们指明信息层级，比如标题、副标题、正文内容等；
                        <br />· 如有需要用到的图片、源文件等素材也可以一并上传。
                      </Tip>
                      <Tip style={{ marginTop: '150px' }}>
                        <span>
                          您可以参考我们的
                          <span style={{ color: '#DEBB6E' }}>视觉风格图谱</span>
                          ，以确定您偏好的视觉风格。
                        </span>
                      </Tip>
                      <Tip style={{ marginTop: '360px' }}>
                        <span>
                          · 设计物料的尺寸非常重要 <br />
                          · 设计物料尺寸规格的变更需要花费较多的工作量 <br />·
                          可参考云设计部提供的
                          <span style={{ color: '#DEBB6E' }}>
                            常规设计物料尺寸规格表
                          </span>
                        </span>
                      </Tip>
                      <Tip style={{ marginTop: '350px' }}>
                        ·
                        源文件的格式要求非常重要，如设计完成后变更源文件格式要求，可能会需要重新设计
                        <br />· 如您对源文件格式的要求不明确，可咨询设计顾问
                      </Tip>
                    </div>
                  </div>
                </div>
              </HeaderLayout>
              <div className={styles.bottom}>
                <div className={styles.bottom_container}>
                  <div className={styles.bottom_title}>
                    {item && <LittleCard {...item} />}
                  </div>
                  <div className={styles.bottom_right}>
                    <Button
                      onClick={() => this.props.history.goBack()}
                      className={`${styles.bottom_button} normal_button`}
                    >
                      上一步
                    </Button>
                    <Button
                      className={`${styles.bottom_button} linear-gradient-gray normal_button`}
                      style={{ color: '#FFFFFF' }}
                      onClick={this.handleSubmit}
                      type="primary"
                    >
                      下一步
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </CreateDemandComponent>
    );
  }
}

export const CreateDemandForm: any = Form.create({ name: 'create_demand_new' })(
  CreateDemand,
);

import React from 'react';
import { Modal, Button, Checkbox } from 'antd';

import styles from './index.module.css';
import { withRouter } from 'react-router';
import tip from './tip@2x.png';

class SureDemandModal extends React.Component<any> {
  state = {
    A: false,
    B: false,
    C: false,
  }
  render() {
    const { visible } = this.props;
    const disabled = !(this.state.A && this.state.B && this.state.C)
    return (
      <Modal
        visible={visible}
        className={`modal-cover modal-cover-body`}
        width={680}
        footer={null}
        title={null}
        onCancel={this.props.onCancel}
      >
        <div className={styles.modal_container}>
          <img alt="" className={styles.modal_title_img} src={tip} />
          <div className={styles.modal_desc}>敬请您阅读并确认下述三个选项</div>
          <div className={styles.modal_content}>
            <div style={{ marginTop: '20px' }}>
              <Checkbox
                value="A"
                onChange={e => this.setState({ A: e.target.checked })}
              >
                我确认我提交的
                <span style={{ color: '#7CB2B8' }}>
                  文案、图片、源文件、字体
                </span>
                等素材
                <span style={{ color: '#7CB2B8' }}>未侵犯任何第三方权益</span>
              </Checkbox>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Checkbox value="B" onChange={e => this.setState({ B: e.target.checked })}>
                我了解如果重新变更
                <span style={{ color: '#7CB2B8' }}>文案内容、源文件格式</span>及
                <span style={{ color: '#7CB2B8' }}>尺寸规格要求</span>
                会产生额外的工作量
              </Checkbox>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Checkbox value="C" onChange={e => this.setState({ C: e.target.checked })}>
                我了解设计是一个
                <span style={{ color: '#7CB2B8' }}>共同合作</span>
                的过程，如果我拿到的设计方案我不是百分百满意，我可以给出
                <span style={{ color: '#7CB2B8' }}>反馈意见</span>，云设计部会
                <span>修改到我满意为止</span>
              </Checkbox>
            </div>
          </div>
          <Button
            className={`${styles.aggress_sure} normal_button`}
            onClick={this.props.onCancel}
          >
            再完善一下
          </Button>
          <Button
            className={`linear-gradient-gray ${disabled ? styles.next_disable : styles.sure} normal_button`}
            onClick={this.props.onSure}
            type="primary"
            disabled={disabled}
          >
            确定创建
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withRouter(SureDemandModal);

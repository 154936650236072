import React from 'react';
import { Button, Form,  Row, Col, Card } from 'antd';
import BodyWithHeader from '../../components/body-with-header';
import tip from './tip.jpg';




class JoinTip extends React.Component<any> {

  render() {
    return (
      <BodyWithHeader
        title="加入已有团队"
        desc='如您的公司已订阅服务，可以加入到您公司的团队中，进行协作
        共同使用云设计部服务'
      >
        <Card style={{width:'1160px',margin:'51px auto',boxShadow:'0px 0px 20px #E3E3E3',borderRadius:'12px'}}>
        <Row style={{ "display": "flex", "justifyContent": "center",  }} gutter={16}>
          <Col>
            <img src={tip} alt='' style={{ "width": '1120px', "height": "336px" }}></img>
          </Col>
        </Row>
        <Row style={{ "display": "flex", "justifyContent": "center", "marginTop": '50px' }}>
          <Col>
           <Button style={{"width":"220px",height:"50px",backgroundColor:"#69707A",color:"#FFFFFF"}} onClick={()=>{this.props.history.push('/choose-or-create-team')}}>好的，了解</Button>
          </Col>
        </Row>
        </Card>
      </BodyWithHeader>
    );
  }
}

export const JoinTipPage: any = Form.create({ name: 'join_tip' })(
  JoinTip,
);

import React from 'react';
import { Button, Modal } from 'antd';

import styles from './index.module.css';
interface ParamsProps {
  visible: boolean
  onUserAgreementCancel: () => void
  onUserAgreementOk: () => void
}
export class UserAgreemnet extends React.Component<ParamsProps> {

  render() {
    const { visible, onUserAgreementCancel, onUserAgreementOk } = this.props;
    return (
      <Modal
        className={`modal-cover modal-cover-body`}
        visible={visible}
        footer={null}
        closable={false}
        centered
        width={800}
        confirmLoading={false}
        onCancel={onUserAgreementCancel}
      >
        <div className={styles.modal_title}>《Nef.Design用户协议》</div>
        <div className={styles.modal_body}>
          <span
            style={{ color: '#69707A', fontSize: '20px', fontWeight: 900 }}
          >
            特别提示:
          </span>
          <br />
          <br />
          请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入云设计部。当您注册成功或完成团队创建或提交设计需求后，无论是进入云设计部系统，还是在云设计部系统上提交设计需求、接收设计方案，均意味着您（即「用户」）完全接受本协议项下的全部条款。
          <br />
          <br />
          《云设计部平台使用协议》
          <br />
          <br />
          更新日期:2022年【1】月【20】日
          <br />
          <br />
          生效日期:2022年【1】月【20】日
          <br />
          <br />
          云设计部是由天津奈夫科技合伙企业（有限合伙）及/或其关联方(以下简称“奈夫科技”或“我们”)提供的互联网软件及设计服务(以下简称“云设计部”)。本服务协议(以下简称“本协议”)是您(以下简称“您”或“用户”)与奈夫科技之间关于您访问和使用云设计部以及奈夫科技提供的其他服务(以下简称“云设计部服务”或“本服务”)的协议。
          <br />
          <br />
          在本服务创建账户并使用之前，您必须首先同意本协议。在接受本协议之前，请您仔细阅读全部内容，如您无法准确理解或不同意本协议的任一内容，请不要访问、使用云设计部或奈夫科技提供的其他任何服务。您通过网络⻚面点击确认、实际购买或使用本服务等操作均表示您已阅读并充分理解本协议之内容，具有达成本协议所需的民事权利能力和民事行为能力，与奈夫科技就使用或订购相应产品和/或服务已达成合意，并同意接受本协议的全部约定内容，受其约束。
          <br />
          <br />

          您同意奈夫科技有权不时地对本协议进行修订。修订后的协议将在网⻚上公布的方式向您告知，并代替原来的服务协议。您继续访问和使用云设计部即视为您接受修订后的服务协议。如您不同意修订后的全部或者部分服务协议内容，您有权在当前服务期结束后，停止续订云设计部服务。新修订的协议不对您当前的服务期生效，仅对您新续订的服务期生效。
          <br />
          <br />
          如果您代表某个机构而非您个人注册、登录和使用云设计部，则您将被认为获得该机构的充分授权，有权代表该机构同意本服务协议以及服务协议不时的修改和补充。
          <br />
          <br />
          如您已与奈夫科技另行签订协议，则已另行签订的协议为准。
          <br />
          <br />
          1.服务内容
          <br />
          <br />
          1.1本协议中的“云设计部服务”指:奈夫科技向您提供platform.nef.design网站(即云设计部官网)上所展示的产品和服务，包括但不限于通过网⻚、移动端APP等方式提供的全部服务，具体服务内容以您所使用或订购的内容为准。
          <br />
          <br />
          1.2若您未从奈夫科技官方途径或经奈夫科技授权的第三方获取云设计部服务的，我们无法保证该服务能够正常使用，我们对此种获取方式和使用行为造成的损失不承担任何责任。
          <br />
          <br />
          1.3根据实际需要和奈夫科技不时提供的其他服务内容，奈夫科技可能与您另行签订其他协议(“其他协议”)。同时，即使未另行签订其他协议，您使用云设计部的具体服务也将被视为您同意奈夫科技关于该等具体服务的任何要求。如果其他协议和本协议之间存在冲突，应以其他协议为准，但以该冲突和与该协议特定事宜相关为限。
          <br />
          <br />
          1.4奈夫科技保留随时变更、中止或终止部分免费服务的权利，并保留根据实际情况随时调整云设计部提供的免费服务种类、形式的权利。奈夫科技不承担因云设计部提供的任何免费服务的调整给您造成的损失。奈夫科技有权在未来恰当的时机对任何免费服务内容收取相应的服务费用。奈夫科技做出上述调整前会以站内通知、邮件或官网公告等方式通知您，并给予您合理期限以完成数据的下载和备份。云设计部免费版服务可能在人数、项目数量或功能上有所限制，您可以通过开通付费版解除该等限制，具体信息以云设计部官网描述为准
          <br />
          <br />
          1.5您在使用云设计部服务前，应充分阅读并理解云设计部官网对相应服务的描述，尤其是对免费服务、各级收费服务在功能、服务标准上的限制。对由于您未能充分理解上述描述和限制而造成的任何损失，奈夫科技不承担任何法律责任。
          <br />
          <br />
          2.账户
          <br />
          <br />
          2.1为访问与使用云设计部，您必须注册一个云设计部账户。注册账户时，您需要提供真实、准确的信息。如果您的信息发生变更，请及时在您的账户中对信息进行更新。您对(a)您的账户的保密性，以及用于启用您访问服务的任何密码、身份验证密钥或安全凭据的安全性负责，和(b)所有在您账户下的活动，不论是您还是您的最终用户的活动负责。如果您的账户下有任何未经授权或滥用行为或者任何与服务相关的安全事件，请您立即通知我们。您认可并同意我们不会对您账户中任何未经授权或滥用行为负责，除非这是由我们违反本协议所造成的。
          <br />
          <br />
          2.2奈夫科技和您应遵守相关法律法规进行实名认证。就云设计部服务的使用，云设计部可能要求您提供进一步的身份资料、经营资质和信息，以完成身份认证或资格验证。仅在完成相应身份认证及资格验证后，您才可以订购及使用相关服务。您理解并同意奈夫科技有权自行或委托第三方，审查您在实名认证时提供的信息是否真实、准确及有效，对于违反本协议约定的账户信息，奈夫科技有权采取不予通过、屏蔽或删除等处理。您知晓并同意，如您未依照相关法律法规及奈夫科技的要求完成实名认证，将影响您访问或使用本服务。
          <br />
          <br />
          2.3您声明并保证，您向奈夫科技提供的信息均为真实、准确、有效、最新的，否则您应承担由此产生的一切法律责任。尽管有前述规定，奈夫科技无义务对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、准确性、完整性、适用性及/或是否为最新资料的责任。
          <br />
          <br />
          2.4奈夫科技建议您妥善保管您的注册邮箱、手机号、密码和名称，准确输入该等信息将作为您访问登录云设计部并享有服务的关键环节。
          <br />
          <br />
          2.5 如您是企业/组织用户，您登录本软件后可以创建您的企业或组织。您可 以选择通过手机号码、邮箱、邀请口令、邀请链接、邀请二维码等方式 (具体方式以云设计部实际提供的方式为准)邀请企业/组织成员加入本软件成为您的最终用户。
          <br />
          <br />
          2.6 如果您接受邀请成为企业/组织用户的最终用户，您可以享受我们为该企 业/组织用户提供的服务内容，服务内容以我们实际提供的功能及企业/组 织用户的服务开通情况为准。您所在企业/组织及其管理员基于企业/组织 管理需求，对您的企业/组织成员身份及基于该身份的相关权益进行管理， 包括但不限于加入、删除以及调整权限和限制服务内容等。您保证在遵守 本服务规范的同时，按照企业/组织的授权范围和规范使用本服务。
          <br />
          <br />
          2.7 您作为企业/组织用户在上传和管理最终用户的姓名、手机号码等个人信 息时，请确保您已经事先获得最终用户的明确同意，仅收集为运营及功能 实现目的而必需的用户信息，且已充分告知最终用户相关数据收集的目的、 范围及使用方式等事项，并提示最终用户可通过阅读产品隐私政策进一步 了解产品个人信息收集处理规则及其所享有的关于个人信息的权利。
          您声明并保证您上传或储存在云设计部中的内容不违反本协议并且符合法律法 规。您应对您上传或储存的内容的合法性、准确性、完整性和可靠性负责。 您应负责获得并持续保有任何与您的内容相关的通知、同意或授权。除非 法律另有明确规定，否则奈夫科技不承担任何与您上传或储存的内容有关 的义务和责任。若您在云设计部上传、储存侵犯他人知识产权或其他违法、违 规的内容，奈夫科技有权利予以删除并保留移交司法机关处理的权利。
          <br />
          <br />
          3.2 您不得使用云设计部上传、储存、共享、发送或传播任何敏感信息和违反国家 法律法规的信息，包括但不限于下列信息:
          <br />
          <br />
          - 反对宪法所确定的基本原则的;
          <br />
          <br />
          - 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的; - 损害国家荣誉和利益的;
          <br />
          <br />
          - 煽动⺠族仇恨、⺠族歧视，破坏⺠族团结的;
          <br />
          <br />
          - 破坏国家宗教政策，宣扬邪教和封建迷信的;
          <br />
          <br />
          - 散布谣言，扰乱社会秩序，破坏社会稳定的;
          <br />
          <br />
          - 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;
          <br />
          <br />
          - 侮辱或者诽谤他人，侵害他人合法权益的;
          <br />
          <br />
          - 含有法律、行政法规禁止的其他内容的。
          <br />
          <br />
          您承诺发表言论要:爱国、守法、自律、真实、文明。不传播任何非法的、
          <br />
          <br />
          <br />
          <br />

          骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽 的、危害国家安全的、泄露国家机密的、破坏国家宗教政策和⺠族团结的 以及其它违反法律法规及政策的内容。若您的行为不符合以上提到的服务 协议云设计部将作出独立判断立即暂停或终止您的服务账号。您需自行对其全 部行为承担法律责任，云设计部不承担任何法律责任。
          <br />
          <br />
          3.3 您不得发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商 业秘密等合法权利的内容。
          <br />
          <br />
          3.4 您不得制作、复制、发布、传播涉及他人隐私、个人信息或资料的信息。
          <br />
          <br />
          3.5 您不得发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的内容。
          <br />
          <br />
          3.6 您应当对您使用云设计部的一切行为及所有在您账户下的活动(不论是您还是 您授权他人的活动)负责。您不得利用云设计部从事违反法律法规、违反本用 户协议约定、危害网络安全、危害计算机系统安全、破坏云设计部产品完整性 或其他侵害云设计部及任何第三人利益的活动。若您从事了上述行为，尽微致 广有权暂停或终止您的账户，并拒绝您于现在和未来使用云设计部之全部或部 分功能，由此对奈夫科技或第三人造成的损失或由此产生的一切法律责任 均由您全部承担。
          <br />
          <br />
          4. 内容和知识产权
          <br />
          <br />
          4.1 您在云设计部提交的内容之所有权归您所有。您明白奈夫科技对您提供的任何 内容不在任何方面负责，奈夫科技没有义务预先审查此类内容。但是，蓝 湖有权随时决定您提供的任何内容是否符合相关法律规定或本协议的相关 约定，并可随时预先审查、转移、拒绝、修改和/或删除您上传的内容，而 不需事先通知您。若您使用本服务的行为存在或涉嫌任何违反国家法律法 规或侵犯任何第三方合法权益的情形，奈夫科技有权直接删除该等违反规 定的信息，并可以暂停或终止向您服务。
          <br />
          <br />
          5 免责声明 您知悉并同意，下列情况下，奈夫科技无需向您承担任何责任:
          <br />
          <br />
          5.1 由于您维护不当或保密不当，导致数据、资料、口令、密码等丢失或泄 漏，或其他非因奈夫科技原因导致的个人信息的泄漏，奈夫科技不承担任 何法律责任;
          <br />
          <br />
          5.2 任何由于不可抗力、黑客攻击、电脑病毒侵入、网络运营商原因或政府管 制等奈夫科技无法合理控制的原因而造成云设计部使用中止、中断、延误、使 用限制、使用异常、使用不能、暂时性网站关闭等，奈夫科技不承担任何 法律责任;
          <br />
          <br />
          5.3 您应对通过云设计部上传或储存文件、输入数据的合法性以及获得文件、数 据、内容的方式的合法性负责，因上述文件、数据、内容引发的一切纠 纷，由您承担全部法律责任，奈夫科技不承担任何法律责任;
          <br />
          <br />
          5.4 互联网是一个开放平台，如果您将图片、文字等内容上传并传播，有可能 会被其他组织或个人复制、转载、擅改或做其它非法用途，您应充分意识 到此类⻛险的存在。您同意使用奈夫科技服务所存在的此类⻛险及后果将 完全由您自己承担，奈夫科技不承担任何责任。
          <br />
          <br />
          5.5 您理解并确认，受技术所限，奈夫科技无法保证其所提供的服务毫无瑕 疵，但我们承诺将不断提升服务质量及服务水平，为您提供更加优质的服 务。据此，您同意:如果奈夫科技所提供的服务虽然存在瑕疵，但该等瑕 疵是当时行业技术水平所无法避免的，其将不被视为违约，奈夫科技无须 因此向您承担任何责任。
          <br />
          <br />
          5.6 我们通过中华人民共和国境内的设施提供服务，我们无法确保您的使用行 为、发布的信息及内容在其他国家或地区是适当的、可行的，当您在其他 司法辖区使用本软件及相关服务时，您应自行确保其遵守当地的法律法规，
          <br />
          <br />
          <br />
          我们对此不承担任何责任。
          <br />
          5.7 如我们发现、或通过第三方举报或投诉获知，您发布的内容存在或涉嫌违
          <br />
          <br />
          反国家法律法规或本软件规则的，我们有权依据合理判断不经通知立即采 取一切必要措施(包括但不限于立刻删除您所发布的相关内容、冻结账户 封号、向公安机关报案等)或配合国家网信部门、公检法等国家机关的执 法要求对帐户做相应处理以减轻或消除您行为所造成的影响。
          <br />
          <br />
          <br />
          <br />
          <span>《云设计部设计服务协议》</span>
          <br />
          <br />
          <br />

          鉴于:
          <br />
          <br />
          1.您正处于业务发展的快速上升期，有大量的视觉设计需求;
          <br />
          <br />
          2.云设计部为互联网产品与设计科技公司，具有产品与视觉设计、信息技术服务等方面的优势;
          <br />
          <br />
          3.本着合作共赢的良好愿景，在平等、自愿、双方协商的基础上，甲乙双方就合作事宜达成如下设计与技术服务协议:
          <br />
          <br />
          <br />
          云设计部服务范围及标准
          <br />
          <br />
          云设计部提供的设计服务范围和交付时间以 https://yun.nef.design/delivertime 公式内容为准
          <br />
          <br />
          云设计部暂不能提供下述范围的服务：
          <br />
          <br />
          营销文案撰写、文案校对等；
          <br />
          <br />
          摄影后期，包括：人像修图、照片修图调色等；
          <br />
          <br />
          商标注册、商标图形近似率查询、著作权保护申请等；
          <br />
          <br />
          其他非平面类的设计，包括：室内设计、工业设计、视频剪辑等。
          <br />
          <br />
          <br />
          订阅服务计价及交付时间
          <br />
          <br />
          订阅类型：
          <br />
          <br />
          您可以选择订阅【基础型设计部】、【业务型设计部】两种订阅类型，不同订阅类型的服务范围及服务交付时间不同，见1.1。
          <br />
          <br />
          云设计部有权在每一个新订阅期开始前，变更订阅服务协议条款，包括交付时效、服务范围等
          <br />
          <br />
          未在1.1中列明的设计类型，以云设计部为您反馈的交付时间为准。
          <br />
          <br />
          订阅期：
          <br />
          <br />
          您付款之日起计算订阅期，您可选择按照【月度】、【季度】、【年度】等不同周期订阅云设计部服务。订阅价格见 https://yun.nef.design/ 云设计部官网所示，或已设计顾问与您单独签订的协议为准。
          订阅期内，您可以无限量使用云设计部提供的服务。
          <br />
          <br />
          云设计部仅在工作日提供服务，云设计部保证每个月度订阅周期有效服务时间不低于22个工作日，如因国家法定节假日或其他不可抗力导致有效服务时间不足22工作日，云设计部将订阅期顺延，以保障有效服务时间足额。
          <br />
          <br />
          <br />

          试用期与退款：
          <br />
          <br />
          您首次订阅云设计部服务，订阅期首5工作日为试用期。（如为季度订阅、年度订阅，则初次订阅首14日为试用期。）双方也可另行约定试用期的时长与起止时间。
          试用期内，您可要求退订服务、解除协议，云设计部在5工作日内全款退还。退订后，您不得使用云设计部试用期内交付的任何设计文件、设计方案。
          试用期结束后，订阅费用不予任何理由退还。
          <br />
          <br />
          需求提交
          <br />
          <br />
          云设计部为您提供标准化需求收集表单及需求管理系统，您需按云设计部要求规范提供设计服务所必须的需求信息，如文案内容、风格偏好、尺寸规格要求等。
          <br />
          <br />
          交付时间
          <br />
          <br />
          云设计部在每个法定工作日的9：30-18：30交付设计方案、与您沟通设计需求、修改意见。周六日、法定节假日不提供服务。
          <br />
          <br />
          您在工作日的工作时间提交需求后，开始计算交付时间；如您在非工作日或非工作时间提交需求，则从最近的一个工作时间开始计算交付时间。
          <br />
          <br />
          例如，您在某周一上午10：00提交了一个【横幅广告】设计需求，则云设计部将在当周周二10：00前交付设计完整方案。
          <br />
          <br />
          例如，您在某周日晚19:00提交了一个【横幅广告】设计需求，则交付时间从周一9:30
          <br />
          <br />
          所有设计需求均安1.1所示标准交付时间表交付，不支持任何加急要求。
          <br />
          <br />
          您完成订阅付款当日订阅期内，您可以提交多个设计需求，无数量上限。云设计部按您提交需求的顺序或您指定的顺序逐个进行设计并交付。如您订阅多个设计师席位，则可多个设计需求同时并发进行。
          <br />
          <br />
          <br />

          您的权利和义务
          <br />
          <br />
          您按照规范的格式向云设计部提出设计需求。设计需求应当明确、合法。对于不同的服务项，云设计部会在每个设计流程完成后，交付相应交付物由您进行确认和反馈。因您确认和反馈产生的时间延后，云设计部不承担延误责任。
          <br />
          <br />
          您有权对云设计部的交付物提出修改意见，云设计部须根据您的修改意见作出调整，并在新的标准交付时间内交付修改后的方案，无修改轮次限制。
          <br />
          <br />
          您可以对云设计部提供的设计方案提出任何修改意见，如对设计师服务不满意，可进行投诉，云设计部可为更换服务设计师。但您对接人不能在合作过程中 言语攻击、辱骂、压制设计师。
          <br />
          <br />
          您有责任对设计方案作出独立的、综合的判断、决策。由您交付至云设计部，作为设计素材、设计文案的所有文案内容，云设计部不负责对其进行任何校对工作，不对其内容的准确性、合规性、保密要求负责。由云设计部交付至您的设计稿中包含的所有文案、设计样式、软件字段，须由您自行进行校对和审阅。您使用云设计部提供的设计方案进行印制、广告投放、售卖等行为所带来一切收益和损失，均由您承担。
          <br />
          <br />
          您应保证向云设计部交付的文案、图片、字体等各类素材文件，未侵犯任何第三方的合法权益。如因您交付的文案、图片、字体等素材侵犯第三方权益而造成您、云设计部损失的，由您承担相应责任。
          <br />
          <br />
          您应当按时、足额向云设计部支付服务费用。
          <br />
          云设计部的权利和义务
          <br />
          云设计部可以向您索要素材、文案、业务目标等合理的需求信息，您应当主动配合。如因需求信息不全导致云设计部无法开始进行设计，云设计部不承担任何事实上和法律上的责任。
          <br />
          <br />
          云设计部应指派至少一名设计顾问/设计师与您对接服务的相关事宜，服务时间为工作日 9：30~18：30，实际服务时间可根据客户要求整体延后至10：00-19：00。
          <br />
          <br />
          云设计部保证在标准交付时间，交付相应交付物。但云设计部不能对任何设计需求保证、承诺定稿时间，即云设计部只能保证按1.1所示标准速度交付设计方案，不能保证设计方案达到您满意；云设计部保证订阅期内，可以按您要求无限次修改，达到您满意为止。修改所需时间以1.1条款为准，未在条款中定义的情形，则以云设计部反馈的交付时间为准。
          <br />
          <br />
          因您自身需求变动、内容变动、设计需求变动、交付时间变动，需要对设计方案进行修改时，云设计部按照您要求进行修改，以新的设计需求进行设计排期，按标准交付时间交付。
          <br />
          <br />
          如您在合作过程中，有对云设计部设计师、设计顾问及其他服务人员进行 辱骂/言语攻击 等言行，云设计部有权利单方面终止服务，解除本合同。
          <br />
          <br />
          <br />
          知识产权
          <br />
          由云设计部为您设计的所有设计方案的使用权均永久归属于您，但设计过程中使用的由第三方提供的字体、图片、矢量图片等除外。
          <br />
          <br />
          您要求云设计部在设计中使用的各类设计素材，如字体、摄影图片、logo商标、矢量素材、文案等，应由您自行确保该素材未侵犯第三方权益，云设计部不负责对您指定素材的知识产权合规性进行判断。
          <br />
          <br />
          云设计部应保证为您提供的设计服务、所使用第三方素材等未侵犯任何第三方的合法权益。
          <br />
          设计素材使用：
          <br />
          云设计部为实现设计需求，可能在部分设计中使用由第三方提供的图片、字体等设计素材，云设计部自行保证所使用设计素材未侵犯任何第三方权益。如因云设计部自行使用的设计素材侵权，由云设计部承担相应责任。
          <br />
          <br />
          云设计部在设计定稿交付后，为您获取该设计素材的使用授权，并书面告知您该素材的使用范围，如因您超出范围使用造成侵权，则由您自行承担相应责任。
          <br />
          <br />
          云设计部仅为已定稿的设计素材获取使用授权，您不得使用设计过程中，被您否决、或仅作为参考示例等并未定稿交付的设计方案，因使用未定稿方案造成侵权，则由您自行承担相应责任。
          <br />
          <br />
          云设计部仅能为您或另行书面约定的1家企业主体获取素材使用授权。如因您将设计方案交由未授权的主体使用造成侵权，则由您自行承担相应责任。
          <br />
          <br />
          本协议默认，云设计部使用需获取使用授权的设计素材时，授权主体为您。如您需为其他主体获取授权，则以双方另行约定为准。
          <br />
          <br />
          本协议生效后，甲、乙双方可以在相关宣传资料中合法并合理的使用对方的名称、商标、标识、照片等相关企业形象。云设计部可在相关宣传资料中使用云设计部为您设计的所有设计方案。
          <br />
          <br />
          <br />
          付款方式
          <br />
          付款方式
          <br />
          <br />
          云设计部提供业务平台platform.nef.design（后简称Nef.Design业务平台）作为您账户管理及付款平台，您可在设计顾问的引导下，在Nef.Design云设计部业务平台进行账户注册及付款。
          您也可以采用银行汇款的形式付款，需在服务开始前将款项汇至下述地址：
          <br />
          <br />
          【账户名】天津奈夫科技合伙企业（有限合伙）
          <br />
          <br />
          【开户行】招商银行股份有限公司北京中关村支行
          <br />
          <br />
          【账号】110935220110101
          <br />
          <br />
          发票开具
          <br />
          <br />
          试用期结束后，云设计部可为您开具电子版增值税普通发票。
          <br />
          <br />
          您须先向云设计部提交发票信息，云设计部在收到发票信息3工作日内开具电子版增值税普通发票。
          <br />
          <br />
          如对发票类型、规格、交付方式有其他要求，则按双方另行约定的标准执行。
          <br />
          <br />
          <br />

          协议解除及违约责任
          <br />
          <br />
          云设计部因交付延误而导致您蒙受损失的，云设计部应对您遭受的直接或间接损失承担赔偿责任，赔偿金额以当前服务期订阅金额为上限。
          <br />
          <br />
          您有责任对产品与设计方案作出独立的、综合的判断、决策，您根据设计师提供的设计意见、建议、方案所作出的决定而导致的损失，由您自行承担。
          <br />
          <br />
          因您修改、需求变更导致的交付时间延后，造成您损失的，云设计部不承担责任。
          <br />
          <br />
          云设计部不承担文案、设计稿校对责任。由您交付至云设计部，作为设计素材、设计文案的内容、材料，云设计部不负责对其进行任何校对工作，不对其内容的准确性、合规性、保密责任负责。由云设计部交付至您的设计稿中包含的所有文案、设计样式、软件字段，须由您自行进行校对和审阅。
          <br />
          <br />
          您无正当理由不支付服务费用，或者无正当理由终止本协议（不可抗力、双方协商同意等情况除外），云设计部有权要求您支付未付的服务费、其他相关费用以及延期支付的利息，收取的费用不予退还。
          <br />
          <br />
          未经双方事先书面同意，任意一方都不能让与或转让此协议的全部或其中的任何权利、利益和义务。
          <br />
          <br />
          如一方违反本协议的有关规定，另一方有权书面通知违约方在规定的时间内纠正违约行为，并对违约方所造成的损失索赔。如违约方未能在规定的时间内纠正违约行为，则守约方有权终止本协议。
          <br />
          <br />
          <br />
          法律适用及争议解决
          <br />
          <br />
          本协议受中华人民共和国法律管辖。
          <br />
          <br />
          在执行本协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方可直接向云设计部所在地人民法院提起诉讼。
          <br />
          <br />
          <br />
          期限及终止
          <br />
          <br />
          本协议一式两份，双方各执一份，具有同等法律效力。
          <br />
          <br />
          本协议自双方加盖公章之日起生效，有效期至订阅周期最后一日。您订阅到期后，本协议自动履行完成。
          <br />
          <br />
          协议有效期为一年。协议到期且您没有续费意向，则默认协议自动履行完成。您每进行一次续费付款，则协议有效期自充值或汇款之日起，自动顺延，协议有效。
          <br />
          <br />
          <br />
          最近修订于2022年【1】月【20】日。奈夫科技保留一切权利
          <br />
          <br />
          天津奈夫科技合伙企业
          <br />
        </div>
        <div className={styles.button_group}>
          <Button
            className={`${styles.aggress_sure}`}
            onClick={onUserAgreementCancel}
            // onClick={() => this.setState({ visible: false })}
            style={{ marginRight: '30px', width: '110px' }}
          >
            取消
          </Button>
          <Button
            className={`linear-gradient-gray ${styles.aggress_sure}`}
            // onClick={() => this.setState({ choose: true, visible: false })}
            onClick={onUserAgreementOk}
            type="primary"
          >
            已阅读同意
          </Button>
        </div>
      </Modal>
    );
  }
}



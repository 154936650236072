import React, { useEffect, useState } from 'react';
import Chat, { Bubble, useMessages, Card, CardMedia, CardActions, Button, FileCard } from './chatui';
import "./chatui/styles/index.less"
// import '@chatui/core/es/styles/index.less';
import { joinRoom, sendMessage, initMessage, loadMoreMessage } from '../../lib/socket';
import { Link } from 'react-router-dom';
import './chat.css'
// import icon from './icon.png'
import link from './link.png'
import { UploadFileModalForm } from './upload-file';
import { file_types, getFileType } from '../../lib';
import { Tag } from 'antd';
import moment from 'moment';

// const initialMessages: any[] = [
//   // {
//   //   type: 'text',
//   //   content: { text: '您好，我是您的私人设计师' },
//   //   user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
//   // },
//   // {
//   //   type: 'image',
//   //   content: {
//   //     picUrl: '//img.alicdn.com/tfs/TB1p_nirYr1gK0jSZR0XXbP8XXa-300-300.png',
//   //   },
//   // },
// ];
const types: any = {
  0: "text",
  1: "file",
  2: "design"
}

function getData(message: any, session: any) {
  return {
    type: types[message.type] || 'text',
    position: message.owner_id === session.id ? 'right' : 'left',
    content: { text: message.message, cover: message.cover, uri: message.uri },
    user: { avatar: message.user.headimgurl, username: message.user.username || message.user.nickname, role: message.user.role },
    created_date: moment(message.created_date).utcOffset(8).format("MM-DD HH:mm:ss"),
    file_old_name: message.file_old_name || "xxx.file"
  }
}

export function DemandChat({ room_id, history }: { room_id: any, history: any }) {
  // 消息列表
  const { messages, appendMsg, prependMsgs } = useMessages();
  const [last_cursor, setCursor] = useState({ afterCursor: "", beforeCursor: "" } as any)
  const session = JSON.parse(localStorage.getItem("session") as string)
  async function join() {
    const { is_login, socket } = await joinRoom(room_id);
    if (!is_login) {
      history.push('/login');
      return <div>请登录</div>
    }
    socket.on("message", (data: any) => {
      appendMsg(getData(data, session))
    })
    const { data, cursor } = await initMessage(room_id)
    setCursor(cursor)
    prependMsgs(data.reverse().map((message: any) => getData(message, session)))
  }

  async function onRefresh() {
    const { data, cursor } = await loadMoreMessage(room_id, last_cursor.afterCursor)
    setCursor(cursor)
    prependMsgs(data.reverse().map((message: any) => getData(message, session)))
  }

  // componentDidMount
  useEffect(() => {
    // 需要在 componentDidMount 执行的内容
    join()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // 发送回调
  async function handleSend(type: any, val: any, file_old_name?: string) {
    const message = val.trim()
    if (type === 'text' && message) {
      // TODO: 发送请求
      const result = await sendMessage(room_id, message, 0)
      appendMsg(getData(result, session))
      // setTyping(true);
    } else if (type === 'file') {
      const result = await sendMessage(room_id, message, 1, file_old_name)
      appendMsg(getData(result, session))
    }
  }

  // 快捷短语回调，可根据 item 数据做出不同的操作，这里以发送文本消息为例
  function handleQuickReplyClick(item: any) {
    handleSend('text', item.name);
  }

  function renderMessageContent(msg: any) {
    const { type, content, position, user, created_date, file_old_name } = msg;
    // 根据消息类型来渲染
    switch (type) {
      case 'text': {
        if (position === "right") {
          return <div>
            <div style={{ marginBottom: "6px", marginLeft: "6px", textAlign: position || 'left' }}>
              <span style={{ color: "#69707A" }}>{created_date}</span>&nbsp;
              <Tag color="blue">{user.role || "客户"}</Tag>
              {user.username}&nbsp;
            </div>
            <Bubble content={content.text} />
          </div>;
        }
        return <div>
          <div style={{ marginBottom: "6px", marginLeft: "6px", textAlign: position || 'left' }}>
            {user.username}&nbsp;
            <Tag color="blue">{user.role || "客户"}</Tag><span style={{ color: "#69707A" }}>{created_date}</span></div>
          <Bubble content={content.text} />
        </div>;
      }
      case 'file': {
        const type = getFileType(content.text)
        if (file_types.includes(type)) {
          if (position === "right") {
            return <div>
              <div style={{ marginBottom: "6px", marginLeft: "6px", textAlign: position || 'left' }}>
                <span style={{ color: "#69707A" }}>{created_date}</span>&nbsp;
                <Tag color="blue">{user.role || "客户"}</Tag>
                {user.username}&nbsp;
              </div>
              <Bubble type="image">
                <img src={content.uri} alt="" />
              </Bubble>
            </div>;
          }
          return <div>
            <div style={{ marginBottom: "6px", marginLeft: "6px", textAlign: position || 'left' }}>
              {user.username}&nbsp;
              <Tag color="blue">{user.role || "客户"}</Tag><span style={{ color: "#69707A" }}>{created_date}</span></div>
            <Bubble type="image">
              <img src={content.uri} alt="" />
            </Bubble>
          </div>;
        }
        const file = {
          name: file_old_name,
          // size: 12345,
        };
        if (position === "right") {
          return <div>
            <div style={{ marginBottom: "6px", marginLeft: "6px", textAlign: position || 'left' }}>
              <span style={{ color: "#69707A" }}>{created_date}</span>&nbsp;
              <Tag color="blue">{user.role || "客户"}</Tag>
              {user.username}&nbsp;
            </div>
            <FileCard file={file}>
              <a href={content.uri}>下载</a>
            </FileCard>
          </div>;
        }
        return <div>
          <div style={{ marginBottom: "6px", marginLeft: "6px", textAlign: position || 'left' }}>
            {user.username}&nbsp;
            <Tag color="blue">{user.role || "客户"}</Tag><span style={{ color: "#69707A" }}>{created_date}</span></div>
          <FileCard file={file}>
            <a href={content.uri}>下载</a>
          </FileCard>
        </div>;
      }
      case 'design': {
        return (
          <Card size="xl">
            <CardMedia image={content.cover} />
            <CardActions>
              <Link to={`/photo?id=${room_id}&group_id=${content.text}`}><Button color="primary">查看设计</Button></Link>
            </CardActions>
          </Card>
        );
      }
      default:
        return null;
    }
  }

  const [visible, setVisible] = useState(false)

  return (
    <>
      <UploadFileModalForm demand_id={room_id} visible={visible} callback={(uri: string, file_old_name: string) => {
        handleSend("file", uri, file_old_name)
        setVisible(false)
      }} onCancel={() => setVisible(false)} />
      <Chat
        messages={messages}
        renderMessageContent={renderMessageContent}
        onQuickReplyClick={handleQuickReplyClick}
        onSend={handleSend}
        loadMoreText={last_cursor.afterCursor !== null ? "加载更多" : ""}
        onRefresh={onRefresh}
        placeholder={"请输入..."}
        wideBreakpoint={"30"}
        // renderQuickReplies={renderQuickReplies}
        // onScroll={() => alert("onScroll")}
        toolbar={[
          // {
          //   type: "button",
          //   title: "表情",
          //   onClick: () => {
          //     console.log(1)
          //   },
          //   img: icon,
          // },
          {
            type: "button",
            title: "上传文件",
            onClick: () => {
              setVisible(true)
            },
            img: link,
          }
        ]}
      />
    </>
  );
}
import React from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Radio,
} from 'antd';
import queryString from 'query-string';
import SuccessModal from '../../components/success-modal';

import { CreateInvoiceComponent, GetOrderByIdComponent } from '../../apollo';
import { message } from '../../components/message';
import styles from './index.module.css';
import tip from './tip@2x.png';


class Tip extends React.Component<any> {
  render() {
    const { style } = this.props;
    return (
      <div style={style} className={styles.tip_container}>
        <img className={styles.tip_img} alt="" src={tip} />
        {this.props.children}
      </div>
    );
  }
}

class CreateDemand extends React.Component<any> {
  state = {
    visible: false,
  };
  handleSubmit = (send: any) => {
    // e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        this.setState({
          sure_visible: true,
        });
        this.sure(send, values)
      }
    });
  };

  sure = async (send: any, values: any) => {
    try {
      // color_description: color_preference!
      const search = queryString.parse(window.location.search);
      const { data } = await send({
        variables: {
          CreateInvoice: {
            ...values,
            amount: JSON.stringify(values.amount),
            order_id: search.order_id
          },
        },
      });
      if (data.CreateInvoice.id) {
        this.setState({
          visible: true
        })
      } else {
        message.error(data);
      }
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const search = queryString.parse(window.location.search);
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { visible } = this.state;
    return (
      <GetOrderByIdComponent variables={{ id: String(search.order_id) }}>
        {({ data }) => {
          if (!data || !data.getOrderById) {
            return '';
          }
          const order = data.getOrderById;
          return <CreateInvoiceComponent>
            {(send) => {
              return (
                <div className={styles.container} >
                  <div className={styles.change_team_container} >
                    <div className="go_back" onClick={() => this.props.history.goBack()}>
                      <Icon type="left-circle" />&nbsp;返回上层
                    </div>
                    <div className={styles.change_team_title} style={{ color: "#69707A" }}>
                      增值税普票信息收集表单
                    </div>
                    <br />
                    <div className={styles.change_team_desc} style={{ color: "#69707A" }}>
                      烦请您留下开据发票所需的信息，我们尽快为您办理
                    </div>
                    <div className={styles.change_team_desc} style={{ color: "#AEB1B5", fontSize: '14px' }}>
                      (敬请您确认发票信息填写无误，发票仅能开具一次，请确认后再填写）
                    </div>
                    <SuccessModal
                      visible={visible}
                      desc={
                        <>
                          发票信息已提交，预计3工作日内发送至您填写的邮箱
                        </>
                      }
                      okText="好的"
                      onOkClick={() => this.props.history.push('/account')}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                        }}
                        className={styles.change_team_item_container}
                      >
                        <div className={styles.left}>
                          <Form className="login-form">
                            <Form.Item label="企业主体名称">
                              {getFieldDecorator('company_name', {
                                rules: [
                                  { required: true, message: '企业主体名称!' },
                                ],
                              })(
                                <Input
                                  autoComplete="off"
                                  className="king_normal_input"
                                  placeholder="例：xxxx有限公司"
                                />,
                              )}
                            </Form.Item>
                            <Form.Item label="纳税人识别号">
                              {getFieldDecorator('identification_number', {
                                rules: [
                                  { required: true, message: '请输入纳税人识别号!' },
                                ],
                              })(
                                <Input
                                  autoComplete="off"
                                  maxLength={5000}
                                  className="king_normal_input"
                                  placeholder="填写 统一社会信用代码、纳税人识别号均可"
                                />,
                              )}
                            </Form.Item>
                            <Form.Item className='price_input' label="发票金额">
                              {getFieldDecorator('amount', {
                                rules: [
                                  {
                                    required: true,
                                    message: '发票金额!',
                                  },

                                ],
                                initialValue: order.price
                              })(
                                <Input
                                  autoComplete="off"
                                  maxLength={20}
                                  disabled
                                  className="king_normal_input"
                                />,
                              )}
                            </Form.Item>
                            <Form.Item label="发票类型">
                              {getFieldDecorator('invoice_type', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择发票类型!',
                                  },
                                ],
                              })(
                                <Radio.Group>
                                  <Radio value="技术服务">技术服务</Radio>
                                  <br />
                                  <Radio value="图文设计服务">图文设计服务</Radio>
                                  <br />
                                  <Radio value="软件开发">软件开发</Radio>
                                </Radio.Group>,
                              )}{' '}
                            </Form.Item>
                            <Form.Item label="电子邮箱">
                              {getFieldDecorator('email', {
                                rules: [
                                  {
                                    type: 'email',
                                    required: true,
                                    message: '情输入正确的Email!',
                                  },
                                  {
                                    required: true,
                                    message: '情输入电子邮箱!',
                                  },

                                ],
                              })(
                                <Input
                                  maxLength={20}
                                  autoComplete="off"
                                  className="king_normal_input"
                                  placeholder="电子邮箱"
                                />,
                              )}
                            </Form.Item>
                            <Form.Item label="收票人联系电话">
                              {getFieldDecorator('phone', {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入收票人联系电话!',
                                  },
                                ],
                              })(
                                <Input
                                  autoComplete="off"
                                  maxLength={20}
                                  type="number"
                                  className="king_normal_input"
                                  placeholder="收票人联系电话"
                                />,
                              )}
                            </Form.Item>
                          </Form>
                          <div className={styles.bottom_text}>敬请您确保所填写信息准确无误，票据仅能开具一次，无法重新开具</div>
                          <Button
                            className={`${styles.bottom_button}`}
                            style={{ marginLeft: '54%', marginTop: '63px' }}
                            onClick={this.handleSubmit.bind(this, send)}
                            type="primary"
                          >
                            提交信息
                          </Button>
                        </div>
                        <div>
                          <Tip style={{ marginTop: '46px' }}>
                            请填写企业的详细名称，须与工商注册名称完全一致
                          </Tip>
                          <Tip style={{ marginTop: '70px' }}>
                            请填写企业税号或纳税人识别号或统一社会信用代码
                          </Tip>
                          {/* <Tip style={{ marginTop: '150px' }}>
                      <span>
                        您可以参考我们的
                        <span style={{ color: '#DEBB6E' }}>视觉风格图谱</span>
                        ，以确定您偏好的视觉风格。
                      </span>
                    </Tip>
                    <Tip style={{ marginTop: '360px' }}>
                      <span>
                        · 设计物料的尺寸非常重要 <br />
                        · 设计物料尺寸规格的变更需要花费较多的工作量 <br />·
                        可参考云设计部提供的
                        <span style={{ color: '#DEBB6E' }}>
                          常规设计物料尺寸规格表
                        </span>
                      </span>
                    </Tip> */}
                          <Tip style={{ marginTop: '350px' }}>
                            发票将以邮件的形式发送至您的邮箱，希望您认真核对
                          </Tip>
                          <Tip style={{ marginTop: '70px' }}>
                            必要时，我们将与收票联系人取得联系，确认发票信息即收票情况
                          </Tip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </CreateInvoiceComponent>
        }}
      </GetOrderByIdComponent>
    );
  }
}


export const InvoicingComponetNewForm: any = Form.create({ name: 'create_invoice' })(
  CreateDemand,
);
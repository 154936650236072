import React from 'react';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import { Button, Radio, Checkbox } from 'antd';
import { ViewerTeamComponent, UpdateSubscriptionOrderComponent } from '../../apollo';
import { message } from '../../components/message';
import top from './top@2x.png';
import { plan } from './subscription-plan.db';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { UserAgreemnet } from '../../components/user-agreement';

const one_day = 1000 * 60 * 60 * 24;

function getPrice(plan_end_date: any, cycle_name: any) {
  const next_plan = plan['业务型设计部'].packages.find(
    ({ name }: any) => cycle_name === name,
  );
  const between_time =
    (new Date(plan_end_date).getTime() - Date.now()) / one_day;
  const peer_day_price = next_plan.price / (next_plan.count * 30);
  // 改为当前时间 结束时间 每天的价格（每种订阅计划不同  同时订阅数不同） 向上取1
  return Math.ceil(between_time * peer_day_price);
}

class UpdateSubscriptionPlan extends React.Component<any> {
  state = {
    value: '支付宝',
    cycle_name: '支付宝',
    // warn 将来如果是多个需要注意
    number: 1,
    cycle: 4499,
    // cycle: 3999,
    aggress: false,
    result_modal: false,
    we_chat_modal: false,
    balance_modal: false,
    uri: '',
    order_id: '',
    price: 4499,
    // price: 3999,
    title: '',
    visible: false
  };

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    let title = String(params.plan || '基础型设计部');
    if (!plan[title]) {
      title = '基础型设计部';
    }
    const item = plan[title].packages[0];
    this.setState({
      cycle: item.count * item.price,
      cycle_name: item.name,
      title,
    });
  }

  handleSubmit = async (
    send: any,
    balance: number,
    plan_end_date: any,
    cycle_name: string,
  ) => {
    const { value, aggress, number } = this.state;
    const price = getPrice(plan_end_date, cycle_name);
    if (!aggress) {
      return message.error(new Error('请同意协议后充值'));
    }
    if (value === '账户余额' && balance < price) {
      return message.error(new Error('余额不足请充值'));
    }
    try {
      const params = queryString.parse(window.location.search);
      let plan_type = String(params.plan || '基础型设计部');
      if (!plan[plan_type]) {
        plan_type = '基础型设计部';
      }
      const { data } = await send({
        variables: {
          UpdateSubscriptionByOrder: {
            number,
            // number: number - plan_count,
            // number: number - 1,
            // cycle,
            pay_type: value,
            name: plan_type,
            // name: '基础型设计部',
            // cycle_name,
            // plan_type,
          },
        },
      });
      this.props.history.push(
        `/pay-order?order_id=${data.updateSubscriptionOrder.id}`,
      );
      // const uri = data.updateSubscription.uri;
      // if (value === '支付宝') {
      //   this.setState({ result_modal: true, uri });
      //   window.open(uri);
      // } else if (value === '微信') {
      //   this.setState({
      //     we_chat_modal: true,
      //     uri,
      //     price,
      //     order_id: data.updateSubscription.id,
      //   });
      // } else {
      //   this.setState({
      //     balance_modal: true,
      //     price,
      //     order_id: data.updateSubscription.id,
      //   });
      // }
    } catch (e) {
      message.error(e);
    }
  };

  render() {
    const { aggress, number } = this.state;
    let { title } = this.state;
    // const params = queryString.parse(window.location.search);
    // let title = String(params.plan || '基础型设计部');
    if (!plan[title]) {
      title = '基础型设计部';
    }
    const desc = plan[title].desc;
    return (
      <ViewerTeamComponent fetchPolicy="no-cache">
        {({ data, error, loading, refetch }) => {
          if (error || loading || !data) {
            return '';
          }
          const team = data.viewer.team;
          if (!team) {
            return '还未加入团队';
          }
          const balance = (data.viewer.team && data.viewer.team.balance) || 0;
          const now_subscription =
            data.viewer.team && data.viewer.team.now_subscription;
          const plan_count =
            (data.viewer.team && data.viewer.team.plan_count) || 0;
          const plan =
            (data.viewer.team && data.viewer.team.plan) || '基础型设计部';
          console.log(
            'now_subscription, number, plan_count, title',
            now_subscription,
            number,
            plan_count,
            title,
          );
          const price = getPrice(team.plan_end_date, team.cycle_name);
          if (plan === '业务型设计部') {
            return (
              <HeaderLayout>
                <div style={{ textAlign: 'center', marginTop: '60px' }}>
                  当前订阅已经不能再升级
                  <br />
                  <Link to="/account">返回用户中心</Link>
                </div>
              </HeaderLayout>
            );
          }
          return (
            <HeaderLayout>
              <div className={styles.container}>
                <img className={styles.top_image} src={top} alt="" />
                <div className={styles.message}>
                  已选择 <span style={{ color: '#7CB2B8' }}> {title}</span>
                </div>
                {desc()}
                <div className={styles.label}>选择可以同时进行的需求数目：</div>
                <Radio.Group
                  onChange={e =>
                    this.setState({
                      number: e.target.value,
                    })
                  }
                  value={this.state.number}
                  style={{ display: 'flex' }}
                >
                  {plan === '基础型设计部' &&
                    plan_count === 1 &&
                    title === '业务型设计部' && (
                      <div className={styles.pay_container}>
                        <Radio value={1}>1个</Radio>
                      </div>
                    )}
                </Radio.Group>
                <div className={styles.bottom}>
                  <div className={styles.bottom_container}>
                    <div className={styles.price_text}>
                      总计：
                      <span style={{ color: '#7CB2B8' }}>{price}元</span>
                    </div>
                    <div className={styles.bottom_right}>
                      <div style={{ textAlign: 'center' }}>
                        <Checkbox
                          onChange={() => this.setState({ aggress: !aggress })}
                          checked={aggress}
                          className={styles.aggress}
                        >
                          <span className="read_me">我已阅读并同意云设计部</span>
                        </Checkbox>
                        <span
                          onClick={() => this.setState({ visible: true })}
                          style={{
                            color: 'rgba(222, 186, 108, 1)',
                            marginLeft: '-20px',
                          }}
                          className="hand"
                        >
                          《平台使用协议》《设计服务协议》
                        </span>
                        <UserAgreemnet visible={this.state.visible}
                          onUserAgreementCancel={() => { this.setState({ visible: false, aggress: false }) }}
                          onUserAgreementOk={() => { this.setState({ visible: false, aggress: true }) }}
                        />
                      </div>
                      <UpdateSubscriptionOrderComponent>
                        {send => {
                          return (
                            <>
                              {!aggress ? (
                                <Button
                                  className={`${styles.next_disable}`}
                                  disabled={!aggress}
                                  type="primary"
                                >
                                  确认订阅计划
                                </Button>
                              ) : (
                                <Button
                                  className={`linear-gradient-gray ${styles.next}`}
                                  onClick={this.handleSubmit.bind(
                                    this,
                                    send,
                                    balance,
                                    team.plan_end_date,
                                    team.cycle_name,
                                  )}
                                  type="primary"
                                >
                                  确认订阅计划
                                </Button>
                              )}
                            </>
                          );
                        }}
                      </UpdateSubscriptionOrderComponent>
                    </div>
                  </div>
                </div>
              </div>
            </HeaderLayout>
          );
        }}
      </ViewerTeamComponent>
    );
  }
}

export default UpdateSubscriptionPlan;

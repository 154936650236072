import React, { useState } from 'react';
import { Modal, Button, Avatar } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styles from './index.module.css';
import { OwnerDemandsComponent, SortDemandsComponent } from '../../../apollo';
import { message } from '../../../components/message';
import swap from './swap.png';


interface sortTableProps {
  hide: () => void
  callback: () => void
}
interface TableListProps {
  list: any
  hide: any
  send: any
}
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
function TableList(props: TableListProps) {
  const [list, setList] = useState(props.list)
  const sortDemands = async (send: any) => {
    const demandsList = list.map((item: any, index: number) => {
      let temp: any = {}
      temp.position = index + 1
      temp.id = item.id
      return temp
    })
    try {
      const result = await send({
        variables: {
          SortDemandList: { demandList: demandsList },
        },
      });
      if (result.data.sortDemands.success) {
        message.success('排序成功');
        props.hide()
      } else {
        message.success('排序失败，请稍后重试！！');
      }
      // setVisible(false)
      // props.callback()
    } catch (e) {
      message.error(e);
    }
  }
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    console.log(items)
    setList(items)
  };
  const grid = 8;
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "grey" : "#EDEFF1",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    // width: 250
  });
  return (
    <div>
      <div className={styles.modal_container_swap}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {list.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', paddingLeft: '48px', paddingRight: '48px' }}>
                          <div style={{ width: '200px' }}>排序{index + 1}</div>
                          <div style={{ width: '400px' }}>需求名称：{item.name}</div>
                          <div style={{ width: '200px', display: 'flex', alignItems: 'center' }}>
                            <Avatar src={item.owner && item.owner.headimgurl}></Avatar><span style={{ paddingLeft: '5px' }}>{item.owner && item.owner.username}</span>
                          </div>
                        </div>
                      </div>

                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={styles.button_group}>
        <Button
          className={styles.cancel}
          onClick={() => {
            props.hide()
          }}
        >
          取消
        </Button>
        <Button
          className={styles.aggress_sure}
          onClick={() => sortDemands(props.send)}
        >
          确定
        </Button>
      </div>
    </div>
  )
}

export function SortTable(props: sortTableProps) {
  const [visilble, setVisible] = useState(false)
  const hide = () => {
    props.hide()
    props.callback()
    setVisible(false)
  }
  return (
    <>
      <div
        className={`hand`}
        onClick={() => {
          setVisible(true)
          props.hide()
        }}
      >
        需求排序
      </div>
      <Modal
        visible={visilble}
        // className={`modal-cover modal-cover-body`}
        onCancel={() => {
          setVisible(false)
          props.hide()
        }}
        footer={null}
        title={null}
        width={861}
        centered={true}
        bodyStyle={{ height: '650px', overflowY: 'auto' }}
      >
        <div style={{ paddingLeft: '110px', paddingRight: '110px' }}>
          <div style={{ marginLeft: '8px', marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={swap} alt='' style={{ width: '40px', height: '40px' }}></img>
              <span className={styles.modal_title}>调整需求顺序</span>
            </div>
            <div className={styles.modal_title_desc}>上下拖动需求卡片，即可调整需求顺序</div>
          </div>
          <OwnerDemandsComponent variables={{
            take: 100,
            status: '排序',
            skip: 0
          }}>
            {({ data, loading, error, refetch }) => {
              if (loading || error || !data) {
                return '';
              }
              const list = data.ownerDemands.list;
              return (<SortDemandsComponent>
                {send => {
                  return (
                    <TableList list={list} hide={hide} send={send} />
                  );
                }}
              </SortDemandsComponent>)
            }}
          </OwnerDemandsComponent>
        </div>
      </Modal>
    </>
  );
}


import React from 'react';
import styles from './index.module.css';
import { Button, Modal } from 'antd';
import { CreateTeamUriComponent } from '../../../apollo';
import { message } from '../../../components/message';
import copy from 'copy-to-clipboard';
import { team_uri } from '../../../config';
import queryString from 'query-string';
// import QrCode from 'qrcode.react';
import team_meeting_online from './team_meeting_online@2x.png';

export class CreateTeamUriModal extends React.Component<any> {
  state = {
    visible: false,
    warn_visible: false,
  };

  onCopy = (text: string) => {
    if (copy(text)) {
      message.success('复制成功');
    } else {
      message.error(new Error('复制成功'));
    }
  };

  add = (count: number) => {
    if (count >= 10) {
      return this.setState({
        warn_visible: true,
      });
    }
    this.setState({
      visible: true,
    });
  };

  render() {
    const { visible } = this.state;
    const { team } = this.props;
    return (
      <>
        <Button
          className={`linear-gradient-gray ${styles.next}`}
          onClick={this.add.bind(this, (team && team.members.length) || 0)}
          type="primary"
        >
          <span style={{ fontSize: '20px' }}> + &nbsp;</span> 邀请成员
        </Button>
        <Modal
          visible={visible}
          className={`modal-cover modal-cover-body`}
          width={848}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={null}
          title={null}
        >
          <div className={styles.modal_container}>
            <div className={styles.modal_title}>邀请成员</div>
            <div className={styles.modal_desc}>
              <div>
                <img className={styles.team_meeting_online} src={team_meeting_online} alt="占位图" />
              </div>
              <div className={styles.team_desc}>
                您可以通过以下方式，像受邀人发送邀请。受邀人成为成员后，将有以下权限：
                {/* 您可以通过以下两种方式，像受邀人发送邀请。受邀人成为成员后，将有以下权限： */}
                <br />
                1、一个团队的成员将共享团队的订阅计划 <br />
                2、成员能够查看到团队所有的需求和进度
              </div>
            </div>
            {visible && (
              <CreateTeamUriComponent>
                {({ data, error, loading }) => {
                  if (error || loading || !data) {
                    return '';
                  }
                  const uri =
                    team_uri +
                    '?' +
                    queryString.stringify({ code: data.createTeamUri.uri });
                  return (
                    <div>
                      <div className={styles.title}>发送链接邀请</div>
                      {/* <div className={styles.title}>方法一 发送链接邀请</div> */}
                      <div className={styles.desc}>
                        为保障项目安全，邀请链接将在30分钟后失效
                      </div>
                      <input
                        className={styles.detail_uri}
                        type="text"
                        name="uri"
                        value={uri}
                        readOnly
                      />
                      <Button
                        className={`linear-gradient-gray`}
                        type="primary"
                        onClick={() => this.onCopy(uri)}
                      >
                        复制
                      </Button>
                      <div style={{ marginTop: '12px' }}>
                        请将复制以上链接，将其发送给受邀人
                      </div>
                      {/* <br />
                      <div className={styles.title}>方法二 通过微信邀请</div>
                      <div className={styles.desc}>
                        为保障项目安全，邀请链接将在30分钟后失效
                      </div>
                      <QrCode value={uri + '&share=true'} size={120} />
                      <div style={{ marginTop: '12px' }}>
                        请使用微信扫描以上二维码，按照网页指引操作
                      </div> */}
                    </div>
                  );
                }}
              </CreateTeamUriComponent>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { PrivateRoute } from './private-route';
// import { LocationListener } from './LocationListener';
import Login from '../pages/login';
import Plan from '../pages/plan';
// import Order from '../pages/order';
import UpdatePlan from '../pages/update-plan-new';
import Service from '../pages/service';
import Account from '../pages/account';
import Team from '../pages/team';
import Recharge from '../pages/recharge';
import Package from '../pages/package';
import ChatDetail from '../pages/demand-chat';
import { CreateDemandForm } from '../pages/create-demand-new';
import { CreateDemandSpecialForm } from '../pages/create-demand-special';
import { CreateTeamModalForm } from '../pages/create-team';
import { CreateOrChooseTeamModal } from '../pages/choose-or-create-team';
import { JoinTipPage } from '../pages/join-team-tip';
import { AddTeamForm } from '../pages/add-team-form';
import ChooseType from '../pages/choose-type-new';
import ChooseDemandType from '../pages/choose-demand-type';
import SubscriptionSuccess from '../pages/subscription-success';
import UpdateSubscriptionSuccess from '../pages/update-subscription-success';
import SubscriptionPlan from '../pages/subscription-plan';
import UpdateSubscriptionPlan from '../pages/update-subscription-plan-new';
import AddTeam from '../pages/add-team';
import ChoosePlan from '../pages/choose-plan';
import PayOrder from '../pages/pay-order';
import ChooseSubscriptionPlanPay from '../pages/choose-subscription-plan-pay';
import { CreateIndependentDemandForm } from '../pages/create-independent-demand';
import Photo from '../pages/photo';
import { CreateDemandUIForm } from '../pages/create-demand-ui';
import { CreateDemandPackageForm } from '../pages/create-demand-package';
import { ChooseTeam } from "../pages/choose-team";
import { InvoicingComponetNewForm } from '../pages/invoicing-new';

class Routers extends Component {
  render() {
    return (
      <Router>
        {/* <LocationListener> */}
        {/* <Route exact path="/" component={Index} /> */}
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/add-team" component={AddTeam} />
        {/* <Route exact path="/add-team" component={AddTeam} /> */}
        <PrivateRoute exact path="/add-team-form" component={AddTeamForm} />
        <PrivateRoute
          exact
          path="/create-team"
          component={CreateTeamModalForm}
        />
        <Route
          exact
          path="/choose-or-create-team"
          component={CreateOrChooseTeamModal}
        />
        <Route
          exact
          path="/team-tip"
          component={JoinTipPage}
        />
        <Route
          exact
          path="/invoicing"
          component={InvoicingComponetNewForm}
        />
        <PrivateRoute exact path="/plan" component={Plan} />
        <PrivateRoute exact path="/service" component={Service} />
        <PrivateRoute exact path="/" component={Service} />
        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/team" component={Team} />
        <PrivateRoute exact path="/recharge" component={Recharge} />
        <PrivateRoute exact path="/package" component={Package} />
        <PrivateRoute exact path="/detail" component={ChatDetail} />
        {/* <PrivateRoute exact path="/detail" component={Detail} /> */}
        <PrivateRoute exact path="/chat-detail" component={ChatDetail} />
        {/* <PrivateRoute exact path="/order" component={Order} /> */}
        <PrivateRoute
          exact
          path="/choose-demand-type"
          component={ChooseDemandType}
        />
        <PrivateRoute
          exact
          path="/create-demand"
          component={CreateDemandForm}
        />
        <PrivateRoute
          exact
          path="/create-demand-package"
          component={CreateDemandPackageForm}
        />
        <PrivateRoute
          exact
          path="/create-demand-special"
          component={CreateDemandSpecialForm}
        />
        {/* <PrivateRoute exact path="/choose-type" component={ChooseType} /> */}
        <PrivateRoute exact path="/choose-type" component={ChooseType} />
        <PrivateRoute exact path="/update-plan" component={UpdatePlan} />
        <PrivateRoute
          exact
          path="/subscription-success"
          component={SubscriptionSuccess}
        />
        <PrivateRoute
          exact
          path="/update-subscription-success"
          component={UpdateSubscriptionSuccess}
        />
        <PrivateRoute
          exact
          path="/create-independent-demand"
          component={CreateIndependentDemandForm}
        />
        <PrivateRoute
          exact
          path="/create-demand-ui"
          component={CreateDemandUIForm}
        />
        <PrivateRoute
          exact
          path="/subscription-plan"
          component={SubscriptionPlan}
        />
        <PrivateRoute
          exact
          path="/update-subscription-plan"
          component={UpdateSubscriptionPlan}
        />
        <PrivateRoute
          exact
          path="/choose-plan"
          component={ChoosePlan}
        />
        <PrivateRoute
          exact
          path="/choose-subscript-plan-pay"
          component={ChooseSubscriptionPlanPay}
        />
        <PrivateRoute
          exact
          path="/pay-order"
          component={PayOrder}
        />
        <PrivateRoute
          exact
          path="/photo"
          component={Photo}
        />
        <PrivateRoute
          exact
          path="/choose-team"
          component={ChooseTeam}
        />
        {/* </LocationListener> */}
      </Router>
    );
  }
}

export default Routers;

import React from 'react';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
// import right from '../plan/right.png';
import { Button } from 'antd';
import { plan } from '../subscription-plan/subscription-plan.db';
import queryString from 'query-string';
import { withRouter } from 'react-router';

// class PlanList extends React.Component {
//   render() {
//     return (
//       <div className={styles.plan_list_container}>
//         <img className={styles.plan_list_img} alt="" src={right} />
//         {this.props.children}
//       </div>
//     );
//   }
// }

class SubscriptionSuccess extends React.Component<any> {
  render() {
    const params = queryString.parse(window.location.search);
    let title = String(params.plan || '基础型设计部');
    if (!plan[title]) {
      title = '基础型设计部';
    }
    const desc = plan[title].desc;
    const img = plan[title].plan_img;
    return (
      <HeaderLayout>
        <div className={styles.container}>
          <div className={styles.message}>
            您已经成功订阅<span style={{ color: '#7CB2B8' }}>{title}</span>
          </div>
          <img className={styles.img} src={img} alt="" />
          <div className={styles.list}>{desc(params.count)}</div>

          {/* <div className={styles.list}>
            <PlanList>
              最少可同时进行 <span style={{ color: '#7CB2B8' }}>1</span> 个需求
            </PlanList>
            <PlanList>订阅期内无限量提交需求及修改</PlanList>
            <PlanList>首次订阅七天无理由退款</PlanList>
            <PlanList>
              配备 <span style={{ color: '#7CB2B8' }}>1</span> 个资深设计师 +{' '}
              <span style={{ color: '#7CB2B8' }}>1</span> 新锐设计师
            </PlanList>
          </div> */}
          <Button
            className={`linear-gradient-gray ${styles.next}`}
            onClick={() => this.props.history.push('/service')}
            type="primary"
          >
            进入设计部
          </Button>
        </div>
      </HeaderLayout>
    );
  }
}

export default withRouter(SubscriptionSuccess);
